import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import styled, { keyframes } from 'styled-components';
import './UserChat2.css'; // Seu CSS global, cuidado para não sobrescrever posições
import ChatSystemNotifications from './ChatSystemNotifications/ChatSystemNotifications';
import Chatbot from '../../../../components/ChatBot/Chatbot';
import { getUserIdFromFirebaseId } from '../../../Login/userService';
import { auth } from '../../../../firebase';
import liliImage from '../../../../imagens/MascoteLiliVidaPetTech.png';

const UserChat = ({ initialUserId }) => {
  const [userId, setUserId] = useState(initialUserId);
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [chatHistory, setChatHistory] = useState([]);
  const [message, setMessage] = useState('');
  const [chatMinimized, setChatMinimized] = useState(true);
  const [showTooltip, setShowTooltip] = useState(true); // <-- exibe/oculta o balão
  const messagesEndRef = useRef(null);
  const [checkAttempts, setCheckAttempts] = useState(0);

  // 1. Checa login
  useEffect(() => {
    const checkLoginStatus = async () => {
      try {
        const user = auth.currentUser;
        if (user) {
          const uidFromApi = await getUserIdFromFirebaseId(user.uid);
          if (uidFromApi) {
            setUserId(uidFromApi);
            localStorage.setItem('userId', uidFromApi);
          }
        } else {
          setUserId('99999');
          localStorage.setItem('userId', '99999');
        }
      } catch (error) {
        console.error('Erro ao verificar login:', error);
      }
    };

    checkLoginStatus();
    const interval = setInterval(() => {
      if (checkAttempts < 12 && userId === null) {
        checkLoginStatus();
        setCheckAttempts(prev => prev + 1);
      } else {
        clearInterval(interval);
      }
    }, 3000);
    return () => clearInterval(interval);
  }, [checkAttempts, userId]);

  // 2. Seleciona parceiro (Lili por padrão)
  const selectPartner = async (partner) => {
    setSelectedPartner(partner);
    if (partner.partnerId === '9999') {
      const initialMessages = [
        {
          senderId: '9999',
          text: 'Bem-vindo(a), sou Lili, sua IA da VidaPet.Tech! Estou aqui para tirar suas dúvidas.'
        },
        {
          senderId: '9999',
          text: 'Já conhece a VidaPet.Tech?'
        }
      ];
      setChatHistory(initialMessages);
    } else {
      try {
        const resp = await axios.get(
          `https://vidapet.tech/api/DB/vetclinic_db/api/system/get_chat_history.php?senderId=${userId}&recipientId=${partner.partnerId}`
        );
        const chatData = resp.data || [];
        const validChatHistory = chatData
          .filter(msg => msg && typeof msg.message === 'string' && msg.message.trim() !== '')
          .map(msg => ({
            senderId: msg.senderId,
            text: msg.message,
            timestamp: msg.timestamp
          }));
        setChatHistory(validChatHistory);
      } catch (error) {
        console.error('Erro ao buscar histórico:', error);
        setChatHistory([]);
      }
    }
  };

  // Se userId não estiver definido, seleciona Lili
  useEffect(() => {
    if (!userId) {
      const defaultLili = {
        partnerId: '9999',
        displayName: 'Lili',
        photoURL: liliImage,
        email: 'lili@vidapet.tech'
      };
      selectPartner(defaultLili);
    }
  }, [userId]);

  // 3. Fecha chat ao clicar fora
  useEffect(() => {
    const handleClickOutside = (event) => {
      const chatContainer = document.querySelector('.uc2-chat-wrapper');
      if (chatContainer && !chatContainer.contains(event.target)) {
        setChatMinimized(true);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // 4. Scroll ao final quando chatHistory mudar
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [chatHistory]);

  // 5. Lógica chatbot Lili
  const handleChatbotMessages = async (userMessage) => {
    const updatedMessages = [...chatHistory, userMessage];
    setChatHistory(updatedMessages);

    const botResponse = await Chatbot({
      userMessage: userMessage.text,
      chatHistory: updatedMessages
    });
    setChatHistory(prev => [...prev, botResponse]);

    if (userId) {
      await axios.post('https://vidapet.tech/api/DB/vetclinic_db/api/system/send_message.php', {
        chatId: '9999',
        senderId: userId,
        recipientId: '9999',
        message: userMessage.text
      });
      await axios.post('https://vidapet.tech/api/DB/vetclinic_db/api/system/send_message.php', {
        chatId: '9999',
        senderId: '9999',
        recipientId: userId,
        message: botResponse.text
      });
    }
  };

  // 6. Envio de mensagem
  const sendMessage = () => {
    if (!selectedPartner || !message.trim()) return;
    const newMsg = { senderId: userId || '9999', text: message.trim() };

    if (selectedPartner.partnerId === '9999') {
      handleChatbotMessages(newMsg);
    } else {
      const updated = [...chatHistory, newMsg];
      setChatHistory(updated);
      axios
        .post('https://vidapet.tech/api/DB/vetclinic_db/api/system/send_message.php', {
          chatId: selectedPartner.partnerId,
          senderId: userId,
          recipientId: selectedPartner.partnerId,
          message: newMsg.text
        })
        .catch(error => console.error('Erro ao enviar mensagem:', error));
    }
    setMessage('');
  };

  // 7. Notificações
  const handleNotificationSelect = (notification) => {
    const lili = { partnerId: '9999', displayName: 'Lili', photoURL: liliImage, email: 'lili@vidapet.tech' };
    selectPartner(lili);
    setChatHistory(prev => [...prev, { senderId: '9999', text: notification.message }]);
  };

  // 8. Minimiza / Maximiza
  const toggleChatMinimized = (e) => {
    e.stopPropagation();
    setChatMinimized(prev => !prev);
  };

  // Nova função: handleIconClick => esconde tooltip e abre o chat
  const handleIconClick = (e) => {
    e.stopPropagation();
    setShowTooltip(false); // <--- desabilita balão
    setChatMinimized(false);
  };

  // 9. Enter para enviar
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      sendMessage();
    }
  };

  return (
    <ChatContainer>
      <ChatWrapper
        className={`uc2-chat-wrapper ${chatMinimized ? 'uc2-minimized' : ''}`}
        /* Removemos onClick={chatMinimized ? toggleChatMinimized : undefined} 
           para não abrir o chat automaticamente ao clicar em qualquer lugar,
           agora o clique está no handleIconClick do MinimizedIconWrapper
        */
      >
        {chatMinimized && (
          <MinimizedIconWrapper onClick={handleIconClick}>
            {showTooltip && (
              <TooltipBalloon>
                Clique na Lili e obtenha dicas da nossa Pet IA
              </TooltipBalloon>
            )}
            <MinimizedIcon src={liliImage} alt="Lili Mascote" />
          </MinimizedIconWrapper>
        )}
        {!chatMinimized && (
          <>
            <MinimizeBtn onClick={toggleChatMinimized}>&times;</MinimizeBtn>
            <ChatInner>
              <ChatHeader>
                <PartnerImg src={selectedPartner?.photoURL} alt={selectedPartner?.displayName} />
                <PartnerName>{selectedPartner?.displayName}</PartnerName>
              </ChatHeader>
              <ChatMessages>
                {chatHistory.map((msg, index) => (
                  <MessageBubble key={index} className={msg.senderId === userId ? 'uc2-sent' : 'uc2-received'}>
                    <p>{msg.text}</p>
                  </MessageBubble>
                ))}
                <div ref={messagesEndRef} />
              </ChatMessages>
              <ChatInput>
                <InputField
                  type="text"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  onKeyDown={handleKeyPress}
                  placeholder="Digite aqui..."
                />
                <SendButton onClick={sendMessage}>Enviar</SendButton>
              </ChatInput>
            </ChatInner>
          </>
        )}
      </ChatWrapper>

      {/* Notificações (ocultas via CSS) */}
      <NotificationsSection className="notifications-section">
        <ChatSystemNotifications onNotificationSelect={handleNotificationSelect} />
      </NotificationsSection>
    </ChatContainer>
  );
};

export default UserChat;

/* ============================= STYLED COMPONENTS ============================= */
const ChatContainer = styled.div`
  position: relative;
  min-height: 100vh;
`;

const ChatWrapper = styled.div`
  position: fixed;
  bottom: 60px;
  right: 20px;
  width: 300px;
  height: 420px;
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
  overflow: hidden;
  z-index: 9999;
  transition: width 0.3s, height 0.3s;

  &.uc2-minimized {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background-color: transparent;
    cursor: pointer;
    box-shadow: none;
  }
`;

/** Animação do anel pulsante **/
const ringPulse = keyframes`
  0% {
    transform: scale(1);
    opacity: 0.7;
  }
  70% {
    transform: scale(2.2);
    opacity: 0;
  }
  100% {
    transform: scale(2.2);
    opacity: 0;
  }
`;

const MinimizedIconWrapper = styled.div`
  position: relative;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    content: "";
    position: absolute;
    width: 64px;
    height: 64px;
    border: 3px solid #00A4E3;
    border-radius: 50%;
    animation: ${ringPulse} 1.5s infinite ease-out;
  }
`;

const MinimizedIcon = styled.img`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  z-index: 2;
  transition: transform 0.2s ease;
  &:hover {
    transform: scale(1.05);
  }
`;

const TooltipBalloon = styled.div`
  position: absolute;
  bottom: 75px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 6px 10px;
  border-radius: 6px;
  font-size: 0.85rem;
  white-space: nowrap;
  z-index: 3;

  &:after {
    content: "";
    position: absolute;
    bottom: -6px;
    left: 50%;
    transform: translateX(-50%);
    border-width: 6px;
    border-style: solid;
    border-color: #333 transparent transparent transparent;
  }
`;

const MinimizeBtn = styled.button`
  position: absolute;
  top: 4px;
  right: 6px;
  background: transparent;
  color: #fff;
  border: none;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  z-index: 100;
  transition: background-color 0.2s ease;
  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
`;

const ChatInner = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ChatHeader = styled.div`
  background-color: #00A4E3;
  color: #fff;
  padding: 10px;
  display: flex;
  align-items: center;
`;

const PartnerImg = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 8px;
`;

const PartnerName = styled.h4`
  font-size: 1rem;
  font-weight: bold;
  margin: 0;
`;

const ChatMessages = styled.div`
  flex: 1;
  padding: 10px;
  overflow-y: auto;
  background-color: #f5f5f5;
`;

const MessageBubble = styled.div`
  max-width: 80%;
  margin-bottom: 6px;
  padding: 8px 12px;
  border-radius: 16px;
  font-size: 0.9rem;
  line-height: 1.3;
  color: #333;
  word-wrap: break-word;

  &.uc2-sent {
    background-color: #dcf8c6;
    margin-left: auto;
    border-top-right-radius: 0;
  }
  &.uc2-received {
    background-color: #ffffff;
    margin-right: auto;
    border-top-left-radius: 0;
  }
`;

const ChatInput = styled.div`
  display: flex;
  border-top: 1px solid #ddd;
  padding: 8px;
  background-color: #fafafa;
`;

const InputField = styled.input`
  flex: 1;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 6px 10px;
  font-size: 0.9rem;
  outline: none;
  margin-right: 6px;
`;

const SendButton = styled.button`
  background-color: #00A4E3;
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: 6px 2px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.2s ease;
  &:hover {
    background-color: #007fad;
  }
`;

const NotificationsSection = styled.div`
  display: none !important;
`;
