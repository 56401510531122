import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DoacaoPixQRCode from './DoacaoPixQRCode';
import ProgramaDoacaoFundoResgate from './ProgramaDoacaoFundoResgate';
import './ProgramaDoacao.css';

const ProgramaDeDoacao = () => {
  const navigate = useNavigate();
  const [showDonation, setShowDonation] = useState(false);
  const [showFundoResgate, setShowFundoResgate] = useState(false);

  return (
    <div className="doacao-page">
      <h1 className="doacao-title">Programa de Doação VidaPet.Tech</h1>
      <p className="doacao-description">
        Escolha como deseja contribuir para salvar e cuidar de pets!
      </p>

      {/* Opção para Pessoa Física */}
      <div className="doacao-card">
        <h2>Doe como Pessoa Física</h2>
        <p>
          Doe a partir de <strong>R$ 20,00 por ano</strong> ganhe nossa 
          <strong>Tag QR-Code para seu Pet</strong>, além do Plano Confort e seus benefícios por 1 ano.
        </p>
        <button className="doacao-button" onClick={() => setShowDonation(true)}>
          Quero Doar
        </button>
      </div>

      {/* Opção para Empresas */}
      <div className="doacao-card">
        <h2>Para Empresas - Plano Corporate</h2>
        <p>
          Sua empresa <strong>não tem custo</strong> e colabora para que todos os funcionários tenham o
          <strong> Plano Confort</strong> que dá direito a Um teleconsulta Veterinaria Orientativa, além tambem da <strong>Tag ID VidaPet.Tech</strong>.
        </p>
        <button className="doacao-button" onClick={() => navigate('/corporate')}>
          Saiba Mais
        </button>
      </div>

      {/* Opção para ONGs */}
      <div className="doacao-card">
        <h2>Para ONGs</h2>
        <p>
          Utilize o sistema VidaPet.Tech <strong>sem custo</strong> e aumente suas chances de receber doações.
          Nossa plataforma oferece total transparência na utilização dos recursos doados por usuários e empresas.
        </p>
        <button
          className="doacao-button"
          onClick={() => alert('Mais informações para ONGs em breve!')}
        >
          Quero Saber Mais
        </button>
      </div>

      {/* Chamada para conhecer o Fundo Dinâmico de Resgate */}
      <div className="doacao-fundo-resgate">
        <h2>Fundo Dinâmico de Resgate</h2>
        <p>
          Boa parte da sua doação vai para o Fundo Dinâmico de Resgate, que financia todo o ciclo de resgate,
          cuidados e adoção dos pets. Clique abaixo para conhecer como funciona!
        </p>
        <button
          className="doacao-button"
          onClick={() => setShowFundoResgate(true)}
        >
          Conheça o Fundo
        </button>
      </div>

      {/* Modal de Doação */}
      {showDonation && (
        <div className="modal-overlay">
          <div className="modal-content">
            <DoacaoPixQRCode onClose={() => setShowDonation(false)} />
            {/* Botão de Voltar */}
            <button className="fdr-back-btn" onClick={() => setShowDonation(false)}>
              Voltar
            </button>
          </div>
        </div>
      )}

      {/* Modal do Fundo Dinâmico de Resgate */}
      {showFundoResgate && (
        <div className="modal-overlay">
          <div className="modal-content">
            <ProgramaDoacaoFundoResgate onClose={() => setShowFundoResgate(false)} />
            {/* Botão de Voltar */}
            <button className="fdr-back-btn" onClick={() => setShowFundoResgate(false)}>
              Voltar
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProgramaDeDoacao;
