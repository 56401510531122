// src/components/ConvenioPayments.jsx

import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import './ConvenioPayments.css';
import PetSelector from './PetSelector'; // Importa o componente PetSelector
import { getUserIdFromFirebaseId } from '../../../Login/userService'; // Ajuste o caminho conforme necessário

const ConvenioPayments = ({
  convenioId,
  modalidadeId,
  valor,
  onClose
}) => {
  // Campos básicos do cliente
  const [clientName, setClientName] = useState('');
  const [clientCpf, setClientCpf] = useState('');
  const [clientEmail, setClientEmail] = useState('');
  const [coupon, setCoupon] = useState('');

  // Estados de controle
  const [isProcessing, setIsProcessing] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');
  const [error, setError] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(null);

  // Dados do QR Code
  const [oBigFator, setOBigFator] = useState(null);     // Base64-encoded QR image
  const [oBigFatorQR, setOBigFatorQR] = useState(null); // Pix copy/paste payload

  // Timer de 10 minutos
  const [timeLeft, setTimeLeft] = useState(600); // 10 min (600s)

  // Tentativas de checagem de pagamento
  const [paymentCheckAttempts, setPaymentCheckAttempts] = useState(0);

  // Estado para mostrar o PetSelector
  const [showPetSelector, setShowPetSelector] = useState(true); // Abrir automaticamente
  const [selectedPetId, setSelectedPetId] = useState(null); // Inicialmente sem seleção

  // Estado para armazenar o userId
  const [userId, setUserId] = useState(null);

  // ========= Helpers de validação =========
  const isValidCpf = (cpf) => /^\d{11}$/.test(cpf); // Regex para exatamente 11 dígitos
  const isValidName = (name) => name.trim().length > 0;
  const isValidEmail = (email) => /\S+@\S+\.\S+/.test(email);

  // ===================================================================
  //     NOVO: Função para registrar o pagamento em serv_pagamentos_convenios
  // ===================================================================
  const registerConvenioPayment = useCallback(async (
    convenioContratoId,
    valorPago,
    metodoPagamento,
    statusPagamento,
    codigoTransacao
  ) => {
    try {
      // Define data de pagamento = hoje
      const dataPagamento = new Date().toISOString().split('T')[0]; // 'YYYY-MM-DD'

      const payload = {
        convenio_contrato_id: convenioContratoId,
        data_pagamento: dataPagamento,
        valor: valorPago,
        status_pagamento: statusPagamento, // ex: 'pago' ou 'confirmed'
        metodo_pagamento: metodoPagamento,
        codigo_transacao: codigoTransacao || null,
      };

      console.log('>>> registerConvenioPayment payload:', payload);

      const response = await axios.post(
        'https://vidapet.tech/api/DB/vidapettech/serv_Apis/Convenios/create_convenio_payment.php',
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.data.status === 'success') {
        console.log('Pagamento registrado na tabela serv_pagamentos_convenios.');
      } else {
        console.error('Falha ao registrar pagamento:', response.data.message);
      }
    } catch (err) {
      console.error('Erro ao registrar pagamento:', err);
    }
  }, []);

  // ===================================================================
  //     Ajuste: Agora criamos (ou adaptamos) esta função para já enviar
  //     as datas data_fim, data_ultimo_pagamento e data_proximo_vencimento.
  // ===================================================================
  const registerUserPetConvenio = useCallback(
    async (userId, petId, convenioId, modalidadeId, metodoPagamento = 'PIX') => {
      setStatusMessage('Registrando convênio para o usuário/pet...');
      try {
        // Calcula datas conforme sua regra
        // -> data_inicio = hoje
        const now = new Date();
        const dataInicio = now.toISOString().split('T')[0]; // 'YYYY-MM-DD'

        // -> data_fim = hoje + 1 ano
        const dataFimDate = new Date(now);
        dataFimDate.setFullYear(dataFimDate.getFullYear() + 1);
        const dataFim = dataFimDate.toISOString().split('T')[0];

        // -> data_ultimo_pagamento = hoje
        const dataUltimoPagamento = dataInicio;

        // -> data_proximo_vencimento = hoje + 1 mês
        const dataProxDate = new Date(now);
        dataProxDate.setMonth(dataProxDate.getMonth() + 1);
        const dataProximoVencimento = dataProxDate.toISOString().split('T')[0];

        // Monta payload
        const payload = {
          user_id: userId,
          convenio_id: convenioId,
          modalidade_id: modalidadeId,
          metodo_pagamento: metodoPagamento,
          status: 'ativo',
          data_inicio: dataInicio,
          data_fim: dataFim,
          data_ultimo_pagamento: dataUltimoPagamento,
          data_proximo_vencimento: dataProximoVencimento,
        };

        // Inclui pet_id se for maior que 0
        if (petId && petId > 0) {
          payload.pet_id = petId;
        } else {
          payload.pet_id = 0; // ou null
        }

        console.log('>>> registerUserPetConvenio payload:', payload);

        const response = await axios.post(
          'https://vidapet.tech/api/DB/vidapettech/serv_Apis/Convenios/create_user_pet_convenio.php',
          payload,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        if (response.data.status === 'success') {
          console.log('Convênio registrado com sucesso:', response.data);
          setStatusMessage('Convênio registrado para o usuário/pet com sucesso!');
          // Retorna o ID do registro (convenio_contrato_id)
          return response.data.record_id;
        } else {
          throw new Error(response.data.message || 'Erro ao registrar convênio.');
        }
      } catch (err) {
        console.error('Erro ao criar user_pet_convenio:', err);
        setError('Não foi possível registrar o convênio na tabela user_pet_convenios.');
        return null;
      }
    },
    []
  );

  // ===================================================================
  //                      FUNÇÕES DE AUTENTICAÇÃO E API
  // ===================================================================
  // Obtém userId
  const obtainUserId = useCallback(async () => {
    try {
      const firebaseId = localStorage.getItem('firebaseId');
      if (firebaseId) {
        const fetchedUserId = await getUserIdFromFirebaseId(firebaseId);
        if (fetchedUserId) {
          setUserId(fetchedUserId);
          console.log('ConvenioPayments - userId:', fetchedUserId);
        } else {
          console.error('ConvenioPayments - Falha ao obter userId do firebaseId');
          setError('Falha ao obter ID do usuário.');
        }
      } else {
        console.error('ConvenioPayments - firebaseId não encontrado no localStorage');
        setError('Usuário não está autenticado.');
      }
    } catch (error) {
      console.error('ConvenioPayments - Erro ao verificar a autenticação:', error);
      setError('Erro ao verificar a autenticação.');
    }
  }, []);

  useEffect(() => {
    obtainUserId();
  }, [obtainUserId]);

  // Verifica se já existe cliente no DB
  const checkClientInDatabase = useCallback(async (cpf) => {
    setStatusMessage('Verificando cliente no banco de dados...');
    try {
      const response = await axios.get(
        `https://vidapet.tech/api/pgs/asaas/clients_added_db.php?cpf=${cpf}`
      );
      return response.data; // { status: 'success', client_id: '...' }
    } catch (error) {
      console.error('Erro ao verificar cliente no banco de dados:', error);
      throw new Error('Erro ao verificar cliente no banco de dados.');
    }
  }, []);

  // Cria cliente no Asaas
  const createClientInAsaas = useCallback(async (name, cpf, email) => {
    setStatusMessage('Criando cliente no Asaas...');
    try {
      const response = await axios.post(
        'https://vidapet.tech/api/pgs/asaas/create_client.php',
        { nome_completo: name, cpf, email }
      );
      return response.data; // { status: 'success', id: 'cus_xxx', ... }
    } catch (error) {
      console.error('Erro ao criar cliente no Asaas:', error);
      throw new Error('Erro ao criar cliente no Asaas.');
    }
  }, []);

  // Tenta várias vezes obter o client_id do DB
  const getClientIdAfterDelay = useCallback(async (cpf) => {
    setStatusMessage('Aguardando confirmação do cliente...');
    let retries = 10;
    while (retries > 0) {
      await new Promise((resolve) => setTimeout(resolve, 2000));
      try {
        const client = await checkClientInDatabase(cpf);
        if (client && client.client_id) {
          return client;
        }
      } catch (error) {
        // ignora erros temporários
      }
      retries--;
    }
    throw new Error('ID do cliente não disponível após várias tentativas.');
  }, [checkClientInDatabase]);

  // Cria cobrança no Asaas
  const createBillingInAsaas = useCallback(async (customerId) => {
    setStatusMessage('Criando cobrança no Asaas...');
    try {
      const response = await axios.post(
        'https://vidapet.tech/api/pgs/asaas/create_billing.php',
        {
          customer: customerId,
          value: valor
        }
      );
      return response.data; // { id: 'pay_xxx', value: 99.90, ... }
    } catch (error) {
      console.error('Erro ao criar cobrança no Asaas:', error);
      throw new Error('Erro ao criar cobrança no Asaas.');
    }
  }, [valor]);

  // Registra cobrança no backend
  const registerBillingInBackend = useCallback(async (billingData) => {
    setStatusMessage('Registrando cobrança no backend...');
    try {
      const response = await axios.post(
        'https://vidapet.tech/api/pgs/asaas/create_billing_db.php',
        billingData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      return response.data; // { status: 'pending' }
    } catch (error) {
      console.error('Erro ao registrar cobrança no backend:', error);
      throw new Error('Erro ao registrar cobrança no backend.');
    }
  }, []);

  // Obtém o QR Code Pix
  const getPixQrCode = useCallback(async (billingId) => {
    setStatusMessage('Obtendo QR Code Pix...');
    try {
      const response = await axios.get(
        `https://vidapet.tech/api/pgs/asaas/get_pix_qr.php?billing_id=${billingId}`
      );

      // Se a API retorna {"encodedImage":"..."}{"payload":"..."}
      const dataSplit = response.data.split('}{');
      let qrCodeData;

      if (dataSplit.length === 2) {
        // Junta as duas partes
        const [part1, part2] = dataSplit.map((item, i) =>
          i === 0 ? item + '}' : '{' + item
        );
        qrCodeData = JSON.parse(part1);
        // Se precisar do payload do segundo JSON, mesclar
      } else {
        // Caso já venha um JSON único
        qrCodeData = JSON.parse(response.data);
      }

      return qrCodeData; // { encodedImage, payload }
    } catch (error) {
      console.error('Erro ao obter QR Code Pix:', error);
      throw new Error('Erro ao obter QR Code Pix.');
    }
  }, []);

  // Verifica status do pagamento no Asaas
  const checkPaymentStatus = useCallback(async (billingId) => {
    setStatusMessage('Verificando status do pagamento...');
    try {
      const response = await axios.get(
        `https://vidapet.tech/api/pgs/asaas/check_payment_status.php?billing_id=${billingId}`
      );
      return response.data; // { paymentConfirmed: true|false }
    } catch (error) {
      console.error('Erro ao verificar status do pagamento:', error);
      throw new Error('Erro ao verificar status do pagamento.');
    }
  }, []);

  // ===================================================================
  //                         MONITOR DE PAGAMENTO
  // ===================================================================
  const monitorPayment = useCallback(
    (billingId) => {
      const interval = setInterval(async () => {
        try {
          const status = await checkPaymentStatus(billingId);
          setPaymentCheckAttempts((prev) => prev + 1);

          if (status && status.paymentConfirmed) {
            clearInterval(interval);
            setPaymentStatus('confirmed');
            setStatusMessage('Pagamento confirmado! Seu convênio será ativado.');

            // 1) Cria (ou atualiza) user_pet_convenio
            const contratoId = await registerUserPetConvenio(
              userId,
              selectedPetId,
              convenioId,
              modalidadeId,
              'PIX'
            );

            // 2) Registra na tabela de pagamentos (serv_pagamentos_convenios)
            if (contratoId) {
              // 'billingId' é o ID da transação gerado pelo Asaas => pode mandar como codigo_transacao
              await registerConvenioPayment(
                contratoId,
                valor,
                'PIX',
                'pago',      // ou 'confirmed'
                billingId
              );
            }
          }

          // Se tentar muitas vezes e ainda não confirmou, damos timeout
          if (paymentCheckAttempts >= 20 && paymentStatus !== 'confirmed') {
            clearInterval(interval);
            setPaymentStatus('timeout');
            setError('Tempo limite para confirmação de pagamento excedido.');
          }
        } catch (error) {
          clearInterval(interval);
          setError('Erro ao verificar status do pagamento.');
        }
      }, 10000); // 10 segundos
    },
    [
      checkPaymentStatus,
      paymentCheckAttempts,
      paymentStatus,
      registerUserPetConvenio,
      registerConvenioPayment,
      convenioId,
      modalidadeId,
      userId,
      selectedPetId,
      valor
    ]
  );

  // ===================================================================
  //                   CONTAGEM REGRESSIVA DE 10 MINUTOS
  // ===================================================================
  useEffect(() => {
    let timer;
    if (timeLeft > 0 && paymentStatus !== 'confirmed') {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft <= 0 && paymentStatus !== 'confirmed') {
      setPaymentStatus('timeout');
      setError('Tempo limite para confirmação de pagamento excedido.');
    }
    return () => clearInterval(timer);
  }, [timeLeft, paymentStatus]);

  // ===================================================================
  //                 FLUXO PRINCIPAL DE PAGAMENTO (PIX)
  // ===================================================================
  const handlePayment = useCallback(async () => {
    setError(null);

    // Validações
    if (!isValidCpf(clientCpf)) {
      setError('CPF inválido. Deve conter 11 dígitos (somente números).');
      return;
    }
    if (!isValidName(clientName)) {
      setError('Nome completo inválido.');
      return;
    }
    if (!isValidEmail(clientEmail)) {
      setError('Email inválido.');
      return;
    }
    if (!selectedPetId || selectedPetId <= 0) {
      setError('Selecione um pet para prosseguir com o pagamento.');
      return;
    }
    if (!userId || userId <= 0) {
      setError('ID do usuário inválido. Tente novamente.');
      return;
    }

    setIsProcessing(true);
    setStatusMessage('Iniciando processamento do pagamento...');

    try {
      // 1) Verifica se já existe client no DB
      const existingClient = await checkClientInDatabase(clientCpf);
      let clientId;

      if (existingClient.status === 'success' && existingClient.client_id) {
        clientId = existingClient.client_id;
      } else {
        // 2) Cria cliente no Asaas
        const clientResponse = await createClientInAsaas(
          clientName,
          clientCpf,
          clientEmail
        );

        if (clientResponse.status === 'success') {
          // 3) Espera o ID aparecer no DB local
          const updatedClient = await getClientIdAfterDelay(clientCpf);
          if (updatedClient.status === 'success' && updatedClient.client_id) {
            clientId = updatedClient.client_id;
          } else {
            throw new Error('ID do cliente não disponível após criação.');
          }
        } else {
          throw new Error('Falha ao criar cliente no Asaas.');
        }
      }

      // 4) Cria cobrança no Asaas
      const billingResponse = await createBillingInAsaas(clientId);
      if (!billingResponse || !billingResponse.id) {
        throw new Error('Resposta inesperada na criação da cobrança.');
      }

      // 5) Registra cobrança no backend (DB local)
      const billingData = {
        client_id: clientId,
        amount: billingResponse.value,
        asaas_billing_id: billingResponse.id,
        convenio_id: convenioId,
        modalidade_id: modalidadeId
      };
      const dbResponse = await registerBillingInBackend(billingData);

      if (dbResponse.status === 'pending') {
        // 6) Obtém QR code Pix
        const qrCodeResponse = await getPixQrCode(billingResponse.id);
        if (qrCodeResponse.encodedImage && qrCodeResponse.payload) {
          setOBigFator(qrCodeResponse.encodedImage);
          setOBigFatorQR(qrCodeResponse.payload);
          setStatusMessage(
            `Pague o convênio (R$ ${billingResponse.value.toFixed(2)}) via Pix (QR ou Copia e Cola). Assim que o pagamento for confirmado, seu convênio será ativado.`
          );

          // 7) Inicia monitoramento do pagamento
          monitorPayment(billingResponse.id);
        } else {
          throw new Error('Dados do QR Code Pix ausentes ou inválidos.');
        }
      } else {
        throw new Error('Resposta inesperada do backend ao criar cobrança.');
      }
    } catch (err) {
      console.error(err);
      setError(err.message || 'Erro ao processar pagamento.');
    } finally {
      setIsProcessing(false);
    }
  }, [
    clientCpf,
    clientEmail,
    clientName,
    checkClientInDatabase,
    createBillingInAsaas,
    createClientInAsaas,
    convenioId,
    getClientIdAfterDelay,
    isValidCpf,
    isValidEmail,
    isValidName,
    monitorPayment,
    modalidadeId,
    registerBillingInBackend,
    selectedPetId,
    userId
  ]);

  // ===================================================================
  //                FLUXO DE CUPOM (SEM QR CODE, DIRETO)
  // ===================================================================
  const handleCouponSubmit = useCallback(async () => {
    if (coupon.trim().toUpperCase() === 'PAGO') {
      // Marca pagamento como confirmado
      setPaymentStatus('confirmed');
      setStatusMessage('Pagamento confirmado via cupom!');

      // 1) Cria/Registra convênio
      const contratoId = await registerUserPetConvenio(
        userId,
        selectedPetId,
        convenioId,
        modalidadeId,
        'CUPOM'
      );

      // 2) Registra o pagamento em serv_pagamentos_convenios
      if (contratoId) {
        await registerConvenioPayment(
          contratoId,
          valor,
          'CUPOM',
          'pago',      // ou 'confirmed'
          'CUPOM-PAGO' // ou qualquer código que queira colocar
        );
      }
    } else {
      setError('Cupom inválido.');
    }
  }, [
    coupon,
    convenioId,
    modalidadeId,
    registerUserPetConvenio,
    registerConvenioPayment,
    selectedPetId,
    userId,
    valor
  ]);

  // ===================================================================
  //                           RENDERIZAÇÃO
  // ===================================================================
  return (
    <div className="convenio-payments-container">
      <h2>Pagamento do Convênio</h2>

      {/* Modal de Seleção de Pet */}
      {showPetSelector && (
        <PetSelector
          onSelect={(petId) => {
            setSelectedPetId(petId);
            setShowPetSelector(false);
          }}
          onClose={() => setShowPetSelector(false)}
        />
      )}

      {/* Se o pagamento ainda não foi confirmado, exibimos o formulário */}
      {paymentStatus !== 'confirmed' && (
        <>
          {/* Formulário de Dados do Cliente */}
          <input
            type="text"
            placeholder="Nome completo"
            value={clientName}
            onChange={(e) => setClientName(e.target.value)}
          />
          <input
            type="text"
            placeholder="CPF (somente números)"
            value={clientCpf}
            onChange={(e) => setClientCpf(e.target.value)}
          />
          <input
            type="email"
            placeholder="Email"
            value={clientEmail}
            onChange={(e) => setClientEmail(e.target.value)}
          />

          {/* Exibir o Pet Selecionado */}
          {selectedPetId && (
            <div className="selected-pet-info">
              <h4>Pet Selecionado:</h4>
              <p>ID: {selectedPetId}</p>
              {/* Opcional: Exibir mais informações do pet, se necessário */}
            </div>
          )}

          {/* Botão para Iniciar Pagamento */}
          <button onClick={handlePayment} disabled={isProcessing}>
            {isProcessing ? 'Processando...' : 'Gerar Pagamento'}
          </button>
        </>
      )}

      {/* Mensagens de status e erro */}
      {statusMessage && <p className="status-message">{statusMessage}</p>}
      {error && <p className="error-message">{error}</p>}

      {/* Exibe QR Code e infos enquanto pagamento não confirmado */}
      {oBigFator && oBigFatorQR && paymentStatus !== 'confirmed' && (
        <>
          <div className="qr-code-container">
            <img
              src={`data:image/png;base64,${oBigFator}`}
              alt="QR Code para pagamento"
              className="qr-code"
            />
            <p className="qr-code-text">Copia e Cola: {oBigFatorQR}</p>
            <button
              onClick={() => {
                navigator.clipboard.writeText(oBigFatorQR);
                alert('Código Pix copiado para a área de transferência!');
              }}
            >
              Copiar Código Pix
            </button>
            <p className="timer">
              Tempo restante para pagamento: {Math.floor(timeLeft / 60)}:
              {timeLeft % 60 < 10 ? `0${timeLeft % 60}` : timeLeft % 60} minutos
            </p>
          </div>

          {/* Cupom de teste */}
          <div className="coupon-container">
            <input
              type="text"
              placeholder="Cupom"
              value={coupon}
              onChange={(e) => setCoupon(e.target.value)}
            />
            <button onClick={handleCouponSubmit}>Validar Cupom</button>
          </div>
        </>
      )}

      {/* Se pagamento foi confirmado */}
      {paymentStatus === 'confirmed' && (
        <div className="payment-confirmed">
          <h3>Pagamento confirmado!</h3>
          <p>Seu convênio foi ativado com sucesso (via Pix ou Cupom).</p>
        </div>
      )}

      {/* Se tempo esgotou */}
      {paymentStatus === 'timeout' && (
        <div className="payment-timeout">
          <h3>Tempo esgotado</h3>
          <p>O tempo limite para confirmação de pagamento foi excedido.</p>
        </div>
      )}

      {/* Botão de fechar modal */}
      <button onClick={onClose}>Fechar</button>
    </div>
  );
};

export default ConvenioPayments;
