// src/pages/MarketPlace/Servicos/PainelParceiros/PainelParceiros/PlansDashboard.jsx

import React, { useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { FaDownload } from 'react-icons/fa'; // Ícone download (react-icons)

const PlansDashboard = () => {
  // ===================== ESTADOS DE LOGIN =====================
  const [loggedIn, setLoggedIn] = useState(false);
  const [partnerId, setPartnerId] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');

  // ===================== ESTADOS DE DADOS =====================
  const [admin, setAdmin] = useState(null);       
  const [contracts, setContracts] = useState([]); 
  const [convenio, setConvenio] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Controla quais contratos estão expandidos para exibir as parcelas
  const [expandedContracts, setExpandedContracts] = useState({});

  // **Novo**: Se `isListMode` for true, usamos lista; se false, tabela
  const [isListMode, setIsListMode] = useState(false);

  // ===================== LOGOUT E PRINT =====================
  const handleLogout = () => {
    setLoggedIn(false);
    setAdmin(null);
    setContracts([]);
    setConvenio(null);
    setPassword('');
    setPartnerId('');
  };

  const handlePrint = () => {
    window.print();
  };

  // ===================== LOGIN + CARGA DE DADOS =====================
  const handleLogin = async (e) => {
    e.preventDefault();
    setLoginError('');
    setError(null);

    try {
      setLoading(true);

      const response = await axios.post(
        'https://vidapet.tech/api/DB/vidapettech/serv_Apis/Convenios/get_admin_convenios_dashboard.php',
        { partnerId, password }
      );

      if (response.data.status === 'success') {
        setAdmin(response.data.admin);
        setContracts(response.data.contracts || []);
        if (response.data.convenio) {
          setConvenio(response.data.convenio);
        }
        setLoggedIn(true);
      } else {
        setLoginError(response.data.message || 'Falha no login');
      }
    } catch (err) {
      setLoginError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // ===================== TELA DE LOGIN =====================
  if (!loggedIn) {
    return (
      <LoginContainer>
        <h2>Painel de Admin - Convênios</h2>
        <Form onSubmit={handleLogin}>
          <Input
            type="text"
            placeholder="ID do Parceiro (partner_code)"
            value={partnerId}
            onChange={(e) => setPartnerId(e.target.value)}
            required
          />
          <Input
            type="password"
            placeholder="Senha"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <LoginButton type="submit" disabled={loading}>
            {loading ? 'Enviando...' : 'Entrar'}
          </LoginButton>
          {loginError && <ErrorText>{loginError}</ErrorText>}
        </Form>
      </LoginContainer>
    );
  }

  // ===================== SE LOGADO MAS OCORREU ERRO =====================
  if (error) {
    return <ErrorText>Erro: {error}</ErrorText>;
  }

  // Função para expandir/ocultar parcelas de um contrato
  const toggleExpanded = (contratoId) => {
    setExpandedContracts((prev) => ({
      ...prev,
      [contratoId]: !prev[contratoId],
    }));
  };

  // Função para alternar entre modo lista e modo tabela
  const toggleListMode = () => {
    setIsListMode(!isListMode);
  };

  // ===================== RENDER TELA PRINCIPAL =====================
  return (
    <DashboardContainer>
      {/* Header: botões de sair, imprimir e alternar modo */}
      <HeaderActions>
        <LogoutButton onClick={handleLogout}>Sair</LogoutButton>
        <PrintButton onClick={handlePrint}>Imprimir</PrintButton>
        <ListModeButton onClick={toggleListMode}>
          {isListMode ? 'Exibir em Tabela' : 'Exibir em Lista'}
        </ListModeButton>
      </HeaderActions>

      {/* Informações do Admin */}
      {admin && (
        <AdminInfo>
          <p>
            <strong>Bem-vindo:</strong> {admin.nome_completo} (<em>{admin.username}</em>)
          </p>
          <p>E-mail: {admin.email}</p>
          <p>Convênio gerenciado: {admin.convenio_id}</p>
        </AdminInfo>
      )}

      {/* Informações do Convênio (documentos, etc.) */}
      {convenio && (
        <ConvenioInfo>
          <h3>Convênio: {convenio.nome}</h3>
          {/* Se tiver documentos */}
          {convenio.documentos && convenio.documentos.length > 0 && (
            <>
              <h4>Documentos do Convênio:</h4>
              <ul>
                {convenio.documentos.map((doc) => (
                  <li key={doc.id}>
                    <a
                      href={doc.documento_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        color: '#000',
                        textDecoration: 'none',
                        fontWeight: 'bold',
                      }}
                    >
                      <FaDownload style={{ marginRight: '5px' }} />
                      {doc.documento_nome}
                    </a>
                  </li>
                ))}
              </ul>
            </>
          )}
        </ConvenioInfo>
      )}

      <h2>Dashboard de Convênios Adquiridos</h2>

      {/* Se não for lista, exibe a tabela */}
      {!isListMode && (
        <Table>
          <thead>
            <tr>
              <Th>ID</Th>
              <Th>Usuário</Th>
              <Th>Pet</Th>
              <Th>Convênio</Th>
              <Th>Modalidade</Th>
              <Th>Status</Th>
              <Th>Início</Th>
              <Th>Últ. Pagto</Th>
              <Th>Próx. Mensal.</Th>
              <Th>Ações</Th>
            </tr>
          </thead>
          <tbody>
            {contracts.map((plan) => (
              <React.Fragment key={plan.contrato_id}>
                <tr>
                  <Td>{plan.contrato_id}</Td>
                  <Td>{plan.user_name || '---'}</Td>
                  <Td>{plan.pet_nome || '---'}</Td>
                  <Td>{plan.convenio_nome}</Td>
                  <Td>{plan.modalidade_nome}</Td>
                  <Td>{plan.status}</Td>
                  <Td>
                    {plan.data_inicio
                      ? new Date(plan.data_inicio).toLocaleDateString()
                      : '---'}
                  </Td>
                  <Td>
                    {plan.data_ultimo_pagamento
                      ? new Date(plan.data_ultimo_pagamento).toLocaleDateString()
                      : '---'}
                  </Td>
                  <Td>
                    {plan.data_proximo_vencimento
                      ? new Date(plan.data_proximo_vencimento).toLocaleDateString()
                      : '---'}
                  </Td>
                  <Td>
                    <ActionButton onClick={() => toggleExpanded(plan.contrato_id)}>
                      {expandedContracts[plan.contrato_id] ? 'Ocultar' : 'Ver Parcelas'}
                    </ActionButton>
                  </Td>
                </tr>

                {/* Parcelas expandíveis */}
                {expandedContracts[plan.contrato_id] && (
                  <tr>
                    <Td colSpan={10}>
                      {plan.parcelas && plan.parcelas.length > 0 ? (
                        <ParcelasTable>
                          <thead>
                            <tr>
                              <th>Parcela</th>
                              <th>Vencimento</th>
                              <th>Status</th>
                              <th>Data Pagamento</th>
                            </tr>
                          </thead>
                          <tbody>
                            {plan.parcelas.map((parcela) => (
                              <tr key={parcela.id}>
                                <td>{parcela.numero_parcela}</td>
                                <td>
                                  {parcela.data_vencimento
                                    ? new Date(parcela.data_vencimento).toLocaleDateString()
                                    : '---'}
                                </td>
                                <td>{parcela.status_pagamento}</td>
                                <td>
                                  {parcela.data_pagamento
                                    ? new Date(parcela.data_pagamento).toLocaleDateString()
                                    : '---'}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </ParcelasTable>
                      ) : (
                        <p>Nenhuma parcela encontrada.</p>
                      )}
                    </Td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </Table>
      )}

      {/* Se for lista, exibe modo card/list */}
      {isListMode && (
        <ListContainer>
          {contracts.map((plan) => (
            <ListItem key={plan.contrato_id}>
              <h4>Contrato #{plan.contrato_id}</h4>
              <p><strong>Usuário:</strong> {plan.user_name || '---'}</p>
              <p><strong>Pet:</strong> {plan.pet_nome || '---'}</p>
              <p><strong>Convênio:</strong> {plan.convenio_nome}</p>
              <p><strong>Modalidade:</strong> {plan.modalidade_nome}</p>
              <p><strong>Status:</strong> {plan.status}</p>
              <p><strong>Data Início:</strong>{' '}
                {plan.data_inicio
                  ? new Date(plan.data_inicio).toLocaleDateString()
                  : '---'}
              </p>
              <p><strong>Últ. Pagto:</strong>{' '}
                {plan.data_ultimo_pagamento
                  ? new Date(plan.data_ultimo_pagamento).toLocaleDateString()
                  : '---'}
              </p>
              <p><strong>Próx. Mensalidade:</strong>{' '}
                {plan.data_proximo_vencimento
                  ? new Date(plan.data_proximo_vencimento).toLocaleDateString()
                  : '---'}
              </p>

              {/* Botão para exibir parcelas (similar ao modo tabela) */}
              <ActionButton onClick={() => toggleExpanded(plan.contrato_id)}>
                {expandedContracts[plan.contrato_id] ? 'Ocultar Parcelas' : 'Ver Parcelas'}
              </ActionButton>

              {/* Parcelas no modo lista */}
              {expandedContracts[plan.contrato_id] && (
                <ParcelasList>
                  {plan.parcelas && plan.parcelas.length > 0 ? (
                    plan.parcelas.map((parcela) => (
                      <li key={parcela.id}>
                        <strong>Parcela {parcela.numero_parcela}</strong> <br />
                        Vencimento: {parcela.data_vencimento
                          ? new Date(parcela.data_vencimento).toLocaleDateString()
                          : '---'}<br />
                        Status: {parcela.status_pagamento}<br />
                        Pagamento: {parcela.data_pagamento
                          ? new Date(parcela.data_pagamento).toLocaleDateString()
                          : '---'}
                      </li>
                    ))
                  ) : (
                    <li>Nenhuma parcela encontrada.</li>
                  )}
                </ParcelasList>
              )}
            </ListItem>
          ))}
        </ListContainer>
      )}
    </DashboardContainer>
  );
};

export default PlansDashboard;

/* ====================== Styled Components ====================== */

const DashboardContainer = styled.div`
  min-height: 100vh; /* Garante que o container ocupe a tela toda */
  background: 
    linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)),
    url('https://images.squarespace-cdn.com/content/v1/65160421ff819966b2caae23/5ccc84d8-6c00-4575-870f-6d1e3a9635c3/Very_Cute_Dog.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  animation: backgroundMove 30s ease-in-out infinite;

  @keyframes backgroundMove {
    0% { background-position: center; }
    50% { background-position: calc(50% + 10px) calc(50% + 10px); }
    100% { background-position: center; }
  }
`;


/* Botões superiores (Sair, Imprimir, Modo Lista/Tabela) */
const HeaderActions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-bottom: 10px;
`;

/* Botões de ação */
const LogoutButton = styled.button`
  padding: 6px 10px;
  background: #dc3545; /* vermelho */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background: #c82333;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 4px 8px;
  }
`;

const PrintButton = styled.button`
  padding: 6px 10px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background: #0056b3;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 4px 8px;
  }
`;

const ListModeButton = styled.button`
  padding: 6px 10px;
  background: #ffc107; /* amarelo */
  color: #000;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background: #e0a800;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 4px 8px;
  }
`;

/* Info do Admin */
const AdminInfo = styled.div`
  background: #fafafa;
  border: 1px solid #ddd;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 8px;
  p {
    margin: 5px 0;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 10px;
  }
`;

/* Info do Convênio (nome, documentos, etc.) */
const ConvenioInfo = styled.div`
  background: #fdfdfd;
  border: 1px solid #ddd;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 8px;

  h3 {
    margin-top: 0;
  }

  ul {
    margin: 0;
    padding-left: 20px;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 10px;
  }
`;

/* Tabela de contratos (modo tabela) */
const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;

  /* Responsividade: telas <768px -> pseudo-cards */
  @media (max-width: 768px) {
    thead {
      display: none;
    }
    tbody tr {
      display: block;
      margin-bottom: 15px;
      border: 1px solid #ccc;
    }
    tbody td {
      display: block;
      text-align: left;
      border: none;
      border-bottom: 1px solid #eee;
      padding: 6px;
      position: relative;
      font-size: 14px;   /* menor no mobile */
      line-height: 1.2;  /* compacto */
    }
    tbody td:before {
      content: attr(data-label);
      font-weight: bold;
      display: inline-block;
      width: 120px;
    }
  }
`;

const Th = styled.th`
  border: 1px solid #ccc;
  padding: 8px;
  background: #f2f2f2;
  text-align: center;
`;

const Td = styled.td`
  border: 1px solid #ccc;
  padding: 8px;
  text-align: center;

  @media (max-width: 768px) {
    border: none;
    display: block;
    text-align: left;
    font-size: 14px;
    line-height: 1.2;
    padding: 6px;

    &:nth-of-type(1):before { content: "ID: "; }
    &:nth-of-type(2):before { content: "Usuário: "; }
    &:nth-of-type(3):before { content: "Pet: "; }
    &:nth-of-type(4):before { content: "Convênio: "; }
    &:nth-of-type(5):before { content: "Modalidade: "; }
    &:nth-of-type(6):before { content: "Status: "; }
    &:nth-of-type(7):before { content: "Início: "; }
    &:nth-of-type(8):before { content: "Últ. Pagto: "; }
    &:nth-of-type(9):before { content: "Próx. Mens.: "; }
    &:nth-of-type(10):before { content: "Ações: "; }
  }
`;

const ActionButton = styled.button`
  padding: 6px 10px;
  background: #17a2b8;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background: #138496;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 4px 6px;
  }
`;

/* Tabela de parcelas */
const ParcelasTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;

  th, td {
    border: 1px solid #aaa;
    padding: 6px;
    text-align: center;
  }

  th {
    background: #ddd;
  }

  @media (max-width: 768px) {
    thead {
      display: none;
    }
    tr {
      display: block;
      margin-bottom: 15px;
      border: 1px solid #ccc;
    }
    td {
      display: block;
      text-align: left;
      border: none;
      border-bottom: 1px solid #eee;
      padding: 6px;
      position: relative;
      font-size: 14px;
      line-height: 1.2;
    }
    td:before {
      font-weight: bold;
      display: inline-block;
      width: 120px;
    }
    td:nth-of-type(1):before { content: "Parcela: "; }
    td:nth-of-type(2):before { content: "Vencimento: "; }
    td:nth-of-type(3):before { content: "Status: "; }
    td:nth-of-type(4):before { content: "Pagto: "; }
  }
`;

/* Estilo para a lista (modo card/list) */
const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
`;

const ListItem = styled.div`
  border: 1px solid #ccc;
  background: #fff;
  padding: 15px;
  border-radius: 8px;

  h4 {
    margin-top: 0;
  }

  p {
    margin: 5px 0;
    font-size: 15px;
    line-height: 1.4;
  }

  @media (max-width: 768px) {
    padding: 10px;
    p {
      font-size: 14px;
      line-height: 1.3;
    }
  }
`;

/* Lista de parcelas no modo card/list */
const ParcelasList = styled.ul`
  margin-top: 10px;
  list-style: none;
  padding-left: 0;

  li {
    background: #fafafa;
    margin-bottom: 5px;
    border: 1px solid #ddd;
    padding: 8px;
    border-radius: 4px;

    @media (max-width: 768px) {
      font-size: 14px;
      line-height: 1.3;
    }
  }
`;

/* Tela de login */
const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 80vh; /* Garante que ocupe a tela toda */
  background: 
    url('https://images.squarespace-cdn.com/content/v1/65160421ff819966b2caae23/5ccc84d8-6c00-4575-870f-6d1e3a9635c3/Very_Cute_Dog.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  animation: backgroundMove 30s ease-in-out infinite;

  @keyframes backgroundMove {
    0% { background-position: center; }
    50% { background-position: calc(50% + 10px) calc(50% + 10px); }
    100% { background-position: center; }
  }
`;


const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Input = styled.input`
  padding: 8px;
  margin: 8px;
  width: 220px;

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 6px;
  }
`;

const LoginButton = styled.button`
  padding: 8px 16px;
  background: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background: #218838;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 6px 12px;
  }
`;

const ErrorText = styled.p`
  color: red;
  margin-top: 10px;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;
