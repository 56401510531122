import React, { useState, useRef, useEffect } from 'react';
import Webcam from 'react-webcam';
import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from 'react-leaflet';
import L from 'leaflet';
import HeartExplosion from './../Resgate/Components/HeartExplosion';

// Import dos componentes de estilo (Styled Components ou CSS Modules)
import {
  WebcamStyled,
  ButtonsContainer,
  PreviewContainer,
  CloseButton,
  ButtonCamera
} from './Components/CSS/styleCamera';

import userLocationIconImage from '../../imagens/icon-Map.png';
import pinIcon from '../../imagens/icon-Map.png';
import './PetAlertForm.css';

// Componente para permitir marcar a localização no mapa ao clicar
function LocationMarker({ setLocation }) {
  useMapEvents({
    click(e) {
      setLocation({ lat: e.latlng.lat, lng: e.latlng.lng });
    }
  });
  return null;
}

function PetAlertForm({ closeForm }) {
  // ---------- ESTADOS DE ETAPAS ----------
  const [step, setStep] = useState(1); // Etapas 1 a 4

  // ---------- TIPO DE ALERTA ----------
  const [alertType, setAlertType] = useState(null); // 'found' ou 'lost'

  // ---------- MODO DE ENVIO DE FOTO ----------
  const [mediaMode, setMediaMode] = useState(null); // 'camera' ou 'file'

  // ---------- REFERÊNCIA DA WEBCAM ----------
  const webcamRef = useRef(null);

  // ---------- DADOS DO FORMULÁRIO ----------
  const [petType, setPetType] = useState('');
  const [comments, setComments] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [captureDatetime, setCaptureDatetime] = useState('');

  // ---------- IMAGEM ----------
  const [imageFile, setImageFile] = useState(null);
  const [previewSrc, setPreviewSrc] = useState(null);

  // ---------- MAPA E FORM DE ENDEREÇO ----------
  const [showAddressForm, setShowAddressForm] = useState(false);
  const [address, setAddress] = useState({
    state: '',
    city: '',
    neighborhood: '',
    street: ''
  });

  // ---------- LISTAS DE ESTADOS/CIDADES (via IBGE) ----------
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  // ---------- MAPA: POSIÇÃO INICIAL E MARCAÇÃO ----------
  // Valor padrão é Brasília, mas será atualizado pela geolocalização
  const [initialPosition, setInitialPosition] = useState([-15.7941, -47.8825]);
  const [markerPosition, setMarkerPosition] = useState(null);

  // ---------- ANIMAÇÃO DE FINALIZAÇÃO ----------
  const [showHeartExplosion, setShowHeartExplosion] = useState(false);

  // ---------- CARREGAR DATA/HORA E GEOLOCALIZAÇÃO ----------
  useEffect(() => {
    const now = new Date().toISOString();
    setCaptureDatetime(now);

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          const { latitude, longitude } = pos.coords;
          const userCoords = { lat: latitude, lng: longitude };
          setInitialPosition([latitude, longitude]);
          // Definindo o marcador inicial com a localização do usuário
          setMarkerPosition(userCoords);
          setLatitude(latitude.toFixed(6));
          setLongitude(longitude.toFixed(6));
        },
        (err) => {
          console.error('Erro ao obter geolocalização:', err);
          // Em caso de erro, mantém a posição padrão (Brasília)
        }
      );
    }

    // Carrega estados do IBGE
    fetch('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
      .then((r) => r.json())
      .then((data) => {
        const sorted = data.sort((a, b) => a.nome.localeCompare(b.nome));
        setStates(sorted);
      })
      .catch((err) => console.error('Erro ao buscar estados:', err));
  }, []);

  // ---------- CARREGAR CIDADES QUANDO O ESTADO MUDAR ----------
  useEffect(() => {
    if (!address.state) return;
    fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${address.state}/municipios`)
      .then((r) => r.json())
      .then((data) => {
        const sorted = data.sort((a, b) => a.nome.localeCompare(b.nome));
        setCities(sorted);
      })
      .catch((err) => console.error('Erro ao buscar cidades:', err));
  }, [address.state]);

  // ---------- FUNÇÃO: CAPTURAR FOTO DA WEBCAM ----------
  const handleCaptureFromWebcam = () => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      if (imageSrc) {
        const file = dataURLtoFile(imageSrc, 'captured.jpg');
        setImageFile(file);
        setPreviewSrc(imageSrc);
      }
    }
  };

  // ---------- FUNÇÃO: CONVERTER BASE64 EM FILE ----------
  function dataURLtoFile(dataurl, filename) {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  // ---------- FUNÇÃO: LIDAR COM UPLOAD DE ARQUIVO ----------
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      const reader = new FileReader();
      reader.onload = (ev) => {
        setPreviewSrc(ev.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // ---------- FUNÇÃO: ENVIAR DADOS ----------
  const handleSubmit = async () => {
    try {
      // Usa a posição marcada, se houver; caso contrário, usa os valores atuais de latitude/longitude
      let finalLat = latitude;
      let finalLng = longitude;
      if (!showAddressForm && markerPosition) {
        finalLat = markerPosition.lat.toFixed(6);
        finalLng = markerPosition.lng.toFixed(6);
      }

      const formData = new FormData();
      formData.append('alert_type', alertType);
      formData.append('pet_type', petType);
      formData.append('latitude', finalLat);
      formData.append('longitude', finalLng);
      formData.append('capture_datetime', captureDatetime);
      formData.append('comments', comments);

      if (imageFile) {
        formData.append('capturedImage', imageFile);
      }

      const response = await fetch('https://vidapet.tech/upload.php', {
        method: 'POST',
        body: formData,
      });

      const respText = await response.text();
      console.log('Resposta do servidor:', respText);

      if (response.ok) {
        setShowHeartExplosion(true);
      } else {
        console.error('Erro ao enviar:', respText);
      }
    } catch (error) {
      console.error('Erro:', error);
    }
  };

  // ---------- QUANDO A ANIMAÇÃO TERMINA ----------
  const handleAnimationComplete = () => {
    setShowHeartExplosion(false);
    closeForm();
  };

  // ---------- ÍCONES PARA LEAFLET ----------
  const userLocationIcon = L.icon({
    iconUrl: userLocationIconImage,
    iconSize: [38, 38],
    iconAnchor: [19, 38],
  });
  const customPinIcon = L.icon({
    iconUrl: pinIcon,
    iconSize: [30, 40],
    iconAnchor: [15, 40],
  });

  // ---------- RENDER DAS ETAPAS ----------
  function renderStep() {
    // ETAPA 1: Tipo de Alerta
    if (step === 1) {
      return (
        <div className="step-content">
          <CloseButton size={30} onClick={closeForm} />
          <h2>Tipo de Alerta</h2>
          <p>Você encontrou um pet ou está procurando o seu?</p>
          <div className="button-group">
            <button
              className={`btn-choose ${alertType === 'found' ? 'selected' : ''}`}
              onClick={() => {
                setAlertType('found');
                setStep(2);
              }}
            >
              Encontrei um Pet
            </button>
            <button
              className={`btn-choose ${alertType === 'lost' ? 'selected' : ''}`}
              onClick={() => {
                setAlertType('lost');
                setStep(2);
              }}
            >
              Meu Pet Está Perdido
            </button>
          </div>
        </div>
      );
    }

    // ETAPA 2: Enviar Foto
    if (step === 2) {
      return (
        <div className="step-content">
          <CloseButton size={30} onClick={closeForm} />
          <h2>Enviar Foto</h2>
          <p>Use a câmera ou escolha uma imagem do seu dispositivo.</p>

          {!mediaMode && !previewSrc && (
            <div className="button-group">
              <button className="btn-choose" onClick={() => setMediaMode('camera')}>
                Abrir Câmera
              </button>
              <button className="btn-choose" onClick={() => setMediaMode('file')}>
                Arquivo do Dispositivo
              </button>
            </div>
          )}

          {mediaMode === 'camera' && !previewSrc && (
            <div className="cameraSection">
              <WebcamStyled
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={{ facingMode: 'environment' }}
              />
              <ButtonsContainer>
                <ButtonCamera onClick={handleCaptureFromWebcam} />
              </ButtonsContainer>
            </div>
          )}

          {mediaMode === 'camera' && previewSrc && (
            <PreviewContainer show={true}>
              <img src={previewSrc} alt="Preview da webcam" />
              <div className="button-group">
                <button
                  className="btn-secondary"
                  onClick={() => {
                    setPreviewSrc(null);
                    setImageFile(null);
                  }}
                >
                  Tirar outra
                </button>
                <button className="btn-primary" onClick={() => setStep(3)}>
                  Confirmar
                </button>
              </div>
            </PreviewContainer>
          )}

          {mediaMode === 'file' && (
            <div style={{ marginTop: '15px' }}>
              {!previewSrc && (
                <input type="file" accept="image/*" onChange={handleFileChange} />
              )}

              {previewSrc && (
                <PreviewContainer show={true}>
                  <img src={previewSrc} alt="Preview arquivo" />
                  <div className="button-group">
                    <button
                      className="btn-secondary"
                      onClick={() => {
                        setPreviewSrc(null);
                        setImageFile(null);
                      }}
                    >
                      Trocar foto
                    </button>
                    <button className="btn-primary" onClick={() => setStep(3)}>
                      Confirmar
                    </button>
                  </div>
                </PreviewContainer>
              )}
            </div>
          )}

          {!previewSrc && (
            <div className="nav-buttons" style={{ marginTop: 20 }}>
              <button className="btn-secondary" onClick={() => setStep(1)}>
                Voltar
              </button>
            </div>
          )}
        </div>
      );
    }

    // ETAPA 3: Localização do Pet
    if (step === 3) {
      return (
        <div className="step-content">
          <CloseButton size={30} onClick={closeForm} />
          <h2>Localização do Pet</h2>
          {!showAddressForm ? (
            <>
              <p>
                Marque no mapa a última localização vista do pet ou{' '}
                <button
                  className="btn-link"
                  onClick={() => setShowAddressForm(true)}
                  style={{ marginLeft: '5px' }}
                >
                  clique aqui para preencher um endereço
                </button>
                .
              </p>
              <div className="map-container">
                <MapContainer
                  center={markerPosition || initialPosition}
                  zoom={13}
                  style={{ width: '100%', height: '300px' }}
                >
                  <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                  {/* Exibe o marcador inicial apenas se markerPosition não estiver definido */}
                  {!markerPosition && (
                    <Marker position={initialPosition} icon={userLocationIcon}>
                      <Popup>Sua localização (aprox.)</Popup>
                    </Marker>
                  )}
                  {markerPosition && (
                    <Marker position={markerPosition} icon={customPinIcon}>
                      <Popup>Local do Pet</Popup>
                    </Marker>
                  )}
                  <LocationMarker setLocation={setMarkerPosition} />
                </MapContainer>
              </div>
            </>
          ) : (
            <>
              <p>Preencha o endereço para identificar onde o pet foi visto.</p>
              <div className="address-form">
                <label>
                  Estado:
                  <select
                    value={address.state}
                    onChange={(e) =>
                      setAddress((prev) => ({ ...prev, state: e.target.value }))
                    }
                  >
                    <option value="">--Selecione--</option>
                    {states.map((st) => (
                      <option key={st.id} value={st.sigla}>
                        {st.nome}
                      </option>
                    ))}
                  </select>
                </label>
                <label>
                  Cidade:
                  <select
                    value={address.city}
                    onChange={(e) =>
                      setAddress((prev) => ({ ...prev, city: e.target.value }))
                    }
                  >
                    <option value="">--Selecione--</option>
                    {cities.map((c) => (
                      <option key={c.id} value={c.nome}>
                        {c.nome}
                      </option>
                    ))}
                  </select>
                </label>
                <label>
                  Bairro:
                  <input
                    type="text"
                    value={address.neighborhood}
                    onChange={(e) =>
                      setAddress((prev) => ({ ...prev, neighborhood: e.target.value }))
                    }
                  />
                </label>
                <label>
                  Rua:
                  <input
                    type="text"
                    value={address.street}
                    onChange={(e) =>
                      setAddress((prev) => ({ ...prev, street: e.target.value }))
                    }
                  />
                </label>
              </div>
              <button
                className="btn-link"
                style={{ marginTop: '10px' }}
                onClick={() => setShowAddressForm(false)}
              >
                Voltar para o Mapa
              </button>
            </>
          )}
          <div className="nav-buttons">
            <button className="btn-secondary" onClick={() => setStep(2)}>
              Voltar
            </button>
            <button className="btn-primary" onClick={() => setStep(4)}>
              Próximo
            </button>
          </div>
        </div>
      );
    }

    // ETAPA 4: Detalhes do Pet
    if (step === 4) {
      return (
        <div className="step-content">
          <CloseButton size={30} onClick={closeForm} />
          <h2>Detalhes do Pet</h2>
          <div className="field">
            <label>Tipo de Pet:</label>
            <select
              value={petType}
              onChange={(e) => setPetType(e.target.value)}
            >
              <option value="">--Selecione--</option>
              <option value="cachorro">Cachorro</option>
              <option value="gato">Gato</option>
              <option value="outros">Outros</option>
            </select>
          </div>
          <div className="field">
            <label>Comentários / Observações:</label>
            <textarea
              rows={4}
              value={comments}
              onChange={(e) => setComments(e.target.value)}
              placeholder={
                alertType === 'found'
                  ? 'Ex: Local exato, estado de saúde, coleira, cor...'
                  : 'Ex: Nome, cor, última vez visto, bairro...'
              }
            />
          </div>
          <div className="nav-buttons">
            <button className="btn-secondary" onClick={() => setStep(3)}>
              Voltar
            </button>
            <button className="btn-primary" onClick={handleSubmit}>
              Enviar Alerta
            </button>
          </div>
        </div>
      );
    }

    return null;
  }

  // ---------- ANIMAÇÃO DE CORAÇÕES ----------
  if (showHeartExplosion) {
    return (
      <div className="modalCamera">
        <div className="explosion-container">
          <HeartExplosion onAnimationComplete={handleAnimationComplete} />
        </div>
      </div>
    );
  }

  // ---------- RENDER PRINCIPAL ----------
  return (
    <div className="modalCamera">
      {/* Barra de progresso simples */}
      <div className="progress-bar">
        <div className="progress" style={{ width: `${(step / 4) * 100}%` }} />
      </div>
      {/* Conteúdo do formulário */}
      <div className="form-container">{renderStep()}</div>
    </div>
  );
}

export default PetAlertForm;
