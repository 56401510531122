import React, { useState } from 'react';
import { auth, provider } from "../../../firebase";
import { FcGoogle } from "react-icons/fc";
import { setUserLoginDetails } from "../UserInfo";
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import logo from '../../../imagens/logoOficial2024.png';
import "./Login.css"; // Certifique-se de que esse arquivo contenha os estilos fornecidos

const Login = ({ closeLogin }) => {
  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleGoogleLogin = () => {
    auth.signInWithPopup(provider)
      .then(async (result) => {
        const user = result.user;
        // Define os detalhes do usuário no Redux
        dispatch(setUserLoginDetails({
          name: user.displayName,
          email: user.email,
          photo: user.photoURL
        }));

        // Sincroniza o usuário com o backend, se necessário
        // Exemplo: await axios.post('https://vidapet.tech/sync-user.php', { ... });

        if (closeLogin) closeLogin();
        // Redireciona para o dashboard
        navigate('/dashboard');
      })
      .catch((error) => {
        setError('Erro no login com Google: ' + error.message);
      });
  };

  return (
    <div className="signup-container">
      <div className="signup-card">
        <div className="signup-logo">
          <a href="/home">
            <img src={logo} alt="VidaPet.Tech Logo" />
          </a>
        </div>
        <h1>Entrar</h1>
        <p>Entre com sua conta Google para continuar</p>
        <div className='social-icons'>
          <button className='buttonGoogle' onClick={handleGoogleLogin}>
            <FcGoogle size={25} /> Entrar com Google
          </button>
        </div>
        {error && <p className="signup-error">{error}</p>}
      </div>
    </div>
  );
};

export default Login;
