import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";

/* Ícones do Remix Icons */
import { 
  RiStethoscopeLine,   // Teleconsulta
  RiAnchorLine,        // Resgate
  RiMoneyDollarCircleLine,  // Carteira
  RiToolsFill,         // Serviços
  RiLogoutBoxLine,     // Sair
  RiCloudFill, 
  RiArrowRightSLine
} from "react-icons/ri";

/* Ícone de pata vindo de Game Icons */
import { GiPawPrint } from "react-icons/gi";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { auth } from "../firebase";
import {
  selectUserName,
  selectUserPhoto,
  setUserLoginDetails,
  setSignOutState,
} from "../pages/Login/UserInfo";
import Login from "../pages/Login/Login";
import Registro from "../pages/Login/Registro";
import EsqueciSenha from "../pages/Login/EsqueciSenha";
import Wallet from "./Wallet/Wallet";
import logo from "../imagens/logoOficial2024.png";

const PageHeaders = () => {
  const [isLoginVisible, setIsLoginVisible] = useState(false);
  const [isRegistroVisible, setIsRegistroVisible] = useState(false);
  const [isEsqueciSenhaVisible, setIsEsqueciSenhaVisible] = useState(false);
  const [menuIsVisible, setMenuIsVisible] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userName = useSelector(selectUserName);
  const userPhoto = useSelector(selectUserPhoto);

  // ======================== LÓGICA DE AUTENTICAÇÃO ========================
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
        const profileInfo = {
          firebaseId: user.uid,
          email: user.email,
          displayName: user.displayName,
          photoURL: user.photoURL,
        };
        syncProfileWithBackend(profileInfo);
      } else {
        dispatch(setSignOutState());
      }
    });
    return () => unsubscribe();
  }, [dispatch]);

  const setUser = (user) => {
    dispatch(
      setUserLoginDetails({
        name: user.displayName,
        email: user.email,
        photo: user.photoURL,
      })
    );
  };

  const syncProfileWithBackend = (profileInfo) => {
    const backendUrl = "https://vidapet.tech/sync-user.php";
    axios
      .post(backendUrl, profileInfo)
      .then((response) => {
        console.log("Perfil sincronizado:", response.data);
        localStorage.setItem("firebaseId", profileInfo.firebaseId);
      })
      .catch((error) => {
        console.error("Erro ao sincronizar:", error);
      });
  };

  // Handlers de login/registro
  const openLoginMenu = () => {
    setIsLoginVisible(true);
    setIsRegistroVisible(false);
    setIsEsqueciSenhaVisible(false);
    setMenuIsVisible(true);
  };
  const closeLogin = () => {
    setIsLoginVisible(false);
    setMenuIsVisible(false);
  };
  const openRegistro = () => {
    setIsRegistroVisible(true);
    setIsLoginVisible(false);
    setIsEsqueciSenhaVisible(false);
    setMenuIsVisible(true);
  };
  const closeRegistro = () => {
    setIsRegistroVisible(false);
    setIsLoginVisible(true);
  };
  const openEsqueciSenha = () => {
    setIsEsqueciSenhaVisible(true);
    setIsLoginVisible(false);
    setIsRegistroVisible(false);
  };
  const closeEsqueciSenha = () => {
    setIsEsqueciSenhaVisible(false);
    setIsLoginVisible(true);
  };

  const handleSignOut = () => {
    auth
      .signOut()
      .then(() => {
        dispatch(setSignOutState());
        localStorage.removeItem("firebaseId");
        setMenuIsVisible(false);
        navigate("/home");
      })
      .catch((err) => alert(err.message));
  };

  return (
    <Container>
      <LeftSection>
        <Logo>
          <a href="/home">
            <img src={logo} alt="VidaPet.Tech Logo" />
          </a>
        </Logo>
        <NavMenu>
          <a href="/dashboard">
            <span>Gestão de Pets</span>
          </a>
          <a href="/Resgate">
            <span>Resgate</span>
          </a>
          <a href="/sobrenos">
            <span>Sobre Nós</span>
          </a>
          <a href="/servicos">
            <span>Serviço</span>
          </a>
          <a href="/Telemedicine">
            <span>TeleConsulta Vet</span>
          </a>
          <a href="/Blog">
            <span>Blogs & Noticias</span>
          </a>
        </NavMenu>
      </LeftSection>

      <RightSection>
        {!userName ? (
          <LoginHint onClick={openLoginMenu}>
            <HintText>Cadastrar / Entrar</HintText>
            <ArrowIcon>
              <RiArrowRightSLine size={25} />
            </ArrowIcon>
            <InicioIcon>
              <RiCloudFill size={35} />
            </InicioIcon>
          </LoginHint>
        ) : (
          <SignOut onClick={() => setMenuIsVisible(!menuIsVisible)}>
            <UserImgContainer>
              <UserImg src={userPhoto} alt={userName} />
              <UserRing />
            </UserImgContainer>

            {/* Menu radial: Inverter a ordem aqui */}
            {menuIsVisible && (
              <RadialMenu>
                {/* 1) Sair */}
                <RadialItem onClick={handleSignOut}>
                  <RiLogoutBoxLine size={22} />
                </RadialItem>

               

                {/* 3) Serviços */}
                <RadialItem
                  onClick={() => {
                    navigate("/servicos");
                    setMenuIsVisible(false);
                  }}
                >
                  <RiToolsFill size={22} />
                </RadialItem>

                                {/* 5) Resgate */}
                <RadialItem
                  onClick={() => {
                    navigate("/Resgate");
                    setMenuIsVisible(false);
                  }}
                >
                  <RiAnchorLine size={22} />
                </RadialItem>

                {/* 6) Teleconsulta */}
                <RadialItem
                  onClick={() => {
                    navigate("/Telemedicine");
                    setMenuIsVisible(false);
                  }}
                >
                  <RiStethoscopeLine size={22} />
                </RadialItem>

                   {/* 2) Pets */}
                <RadialItem
                  onClick={() => {
                    navigate("/dashboard");
                    setMenuIsVisible(false);
                  }}
                >
                  <GiPawPrint size={22} />
                </RadialItem>



              </RadialMenu>
            )}
          </SignOut>
        )}
      </RightSection>

      {/* Se não logado, exibe o mini-menu de login/registro */}
      {menuIsVisible && !userName && (
        <UserMenu>
          {isLoginVisible && (
            <Login
              closeLogin={closeLogin}
              openRegistro={openRegistro}
              openEsqueciSenha={openEsqueciSenha}
            />
          )}
          {isRegistroVisible && (
            <Registro
              closeRegistro={closeRegistro}
              closeUserMenu={() => null}
              setUser={() => null}
            />
          )}
          {isEsqueciSenhaVisible && (
            <EsqueciSenha
              closeEsqueciSenha={closeEsqueciSenha}
              openLoginMenu={openLoginMenu}
            />
          )}
        </UserMenu>
      )}
    </Container>
  );
};

export default PageHeaders;

/* ============================= STYLED COMPONENTS ============================= */

const Container = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 16px;
  background: #00a4e3;
  color: #ffffff;
  height: 60px;
`;

const LeftSection = styled.div`
  display: flex;
  align-items: center;
`;

const RightSection = styled.div`
  display: flex;
  align-items: center;
`;

const Logo = styled.div`
  margin-top: 2px;
  max-height: 50px;
  img {
    max-height: 40px;
    width: auto;
  }
`;

const NavMenu = styled.div`
  display: flex;
  margin-left: 25px;
  a {
    padding: 0 12px;
    text-decoration: none;
    span {
      color: #ffffff;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 1.42px;
      position: relative;
      &:before {
        background-color: #ff7f50;
        border-radius: 0 0 4px 4px;
        bottom: -6px;
        content: "";
        height: 2px;
        left: 0;
        opacity: 0;
        position: absolute;
        right: 0;
        transform: scaleX(0);
        transition: all 250ms;
        visibility: hidden;
      }
    }
    &:hover span:before {
      transform: scaleX(1);
      visibility: visible;
      opacity: 1;
    }
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

const LoginHint = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #ff7f50;
`;

const HintText = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  margin-right: 12px;
`;

const ArrowIcon = styled.div`
  margin-right: 8px;
`;

const InicioIcon = styled.div`
  margin-right: 16px;
  cursor: pointer;
  color: #ff7f50;
`;

const SignOut = styled.div`
  position: relative;
  height: 48px;
  width: 48px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
`;

const UserImgContainer = styled.div`
  position: relative;
  height: 48px;
  width: 48px;
`;

const UserImg = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 50%;
  object-fit: cover;
`;

const UserRing = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 101%;
  width: 101%;
  border: 3px solid #ff7f50;
  border-radius: 50%;
  pointer-events: none;
`;

/*  
   * Ajustamos o top no mobile para descer mais. 
   * Se ainda ficar alto, aumente `top: 80%` ou algo maior no @media. 
*/
const RadialMenu = styled.div`
  position: absolute;
  top: 305%; /* desktop */
  left: 50%;
  width: 400px;
  height: 400px;

  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  z-index: 2000;

  animation: fadeIn 0.4s ease forwards;
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.7) translate(-50%, -50%);
    }
    to {
      opacity: 1;
      transform: scale(1) translate(-50%, -50%);
    }
  }

  pointer-events: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 450px) {
    width: 300px;
    height: 300px;

    /* Mude aqui para descer mais no mobile */
    top: 80%; 
    left: 50%;
    transform: translate(-50%, -80%);
  }
`;

const RadialItem = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;

  min-width: 80px;
  min-height: 80px;
  padding: 12px;
  background: rgba(255, 255, 255, 0.95);
  color: #333;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: background 0.3s, color 0.3s;

  /* 6 itens => ~100° a ~220° => 24° steps */
  &:nth-of-type(1) {
    transform: rotate(100deg) translate(150px) rotate(-100deg);
  }
  &:nth-of-type(2) {
    transform: rotate(124deg) translate(150px) rotate(-124deg);
  }
  &:nth-of-type(3) {
    transform: rotate(148deg) translate(150px) rotate(-148deg);
  }
  &:nth-of-type(4) {
    transform: rotate(172deg) translate(150px) rotate(-172deg);
  }
  &:nth-of-type(5) {
    transform: rotate(196deg) translate(150px) rotate(-196deg);
  }
  &:nth-of-type(6) {
    transform: rotate(220deg) translate(150px) rotate(-220deg);
  }

  &:hover {
    background: #ff7f50;
    color: #fff;
  }

  @media (max-width: 450px) {
    min-width: 60px;
    min-height: 60px;
    /* Se quiser redefinir as transform, faça aqui. Ex: each item with angles. */
  }
`;

const UserMenu = styled.div`
  position: fixed;
  top: 60px;
  right: 0;
  width: 300px;
  max-height: 60vh;
  background-color: #333;
  border: 1px solid #555;
  border-radius: 8px 0 0 8px;
  padding: 20px;
  z-index: 999;
  overflow-y: auto;

  @media (max-width: 768px) {
    width: 90%;
    right: 0;
    margin-right: 5%;
  }
`;
