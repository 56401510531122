  import React, { useState, useEffect } from "react";
  import { FaPhoneAlt } from "react-icons/fa";
  import petIcon from "../../../../imagens/MascoteLiliVidaPetTech.png"; // Imagem padrão do pet

  const TagDetails = ({ pet }) => {
    // Estados separados para nome e telefone do tutor
    const [nomeTutor, setNomeTutor] = useState("Tutor");
    const [telefoneTutor, setTelefoneTutor] = useState("Telefone");

    // Busca os dados do tutor através da API, similar à Carteirinha
    useEffect(() => {
      const fetchTutorData = async () => {
        try {
          const response = await fetch(
            `https://vidapet.tech/api_carteira_pet.php?petId=${pet.petId || pet.id}`
          );
          if (!response.ok) {
            throw new Error("Erro ao buscar dados do tutor");
          }
          const data = await response.json();
          setNomeTutor(data.observacao01 || "Tutor");
          setTelefoneTutor(data.observacao02 || "Telefone");
        } catch (error) {
          console.error("Erro ao buscar dados do tutor:", error);
        }
      };

      if (pet) {
        fetchTutorData();
      }
    }, [pet]);

    // Define a imagem do pet: se existir, usa a URL; caso contrário, usa o petIcon
    const petImage = pet.pet_3x4
      ? `https://vidapet.tech/vidapet/pets/${pet.pet_3x4}?t=${new Date().getTime()}`
      : petIcon;

    return (
      <div className="tag-details">
        <div className="pet-info">
          <img src={petImage} alt={pet.petName} className="pet-image" />
          <h2>{pet.petName}</h2>
          <p>
            <strong>Raça:</strong> {pet.breed}
          </p>
          {pet.age && <p><strong>Idade:</strong> {pet.age} anos</p>}
        </div>
        <div className="tutor-info">
          <h3>Dados do Tutor</h3>
          {telefoneTutor && (
            <p>
              <FaPhoneAlt /> {telefoneTutor}
            </p>
          )}
          {nomeTutor && (
            <p>
              <strong>Nome:</strong> {nomeTutor}
            </p>
          )}
        </div>
      </div>
    );
  };

  export default TagDetails;
