import React, { useState, useEffect, useCallback, lazy, Suspense } from 'react';
import axios from 'axios';
import './PetList.css';
import HealthEventForm from './HealthEventForm';
import HealthEventsView from './HealthEventsView';
import petIcon from '../../../imagens/MascoteLiliVidaPetTech.png';
import styled from 'styled-components';
import PetForm from './PetForm';
import { FaRegEdit, FaQrcode } from "react-icons/fa";
import { IoMdCloseCircle } from "react-icons/io";
import { MdDelete } from "react-icons/md";

const Carteirinha = lazy(() => import("../PetCard/Carteirinha"));

const PetList = ({ userId, reloadPets }) => {
  const [pets, setPets] = useState([]);
  const [isPetsLoaded, setIsPetsLoaded] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [editingPetId, setEditingPetId] = useState(null);
  const [editPetData, setEditPetData] = useState({ name: '', breed: '', age: '' });
  const [showHealthEvents, setShowHealthEvents] = useState({});
  const [showPetForm, setShowPetForm] = useState(false);  
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedPet, setSelectedPet] = useState(null);

  const loadPets = useCallback(async () => {
    try {
      const response = await axios.get(`https://vidapet.tech/petList.php?userId=${userId}`);
      const parsedData = response.data;

      if (parsedData.pets && Array.isArray(parsedData.pets)) {
        setPets(parsedData.pets);
      } else {
        console.log('Nenhum pet encontrado.');
      }
    } catch (error) {
      console.error('Erro ao carregar pets:', error);
    } finally {
      setIsPetsLoaded(true);
    }
  }, [userId]);

  useEffect(() => {
    loadPets();
  }, [userId, loadPets]);

  const handleAttachPhoto = async (petId, photoFile) => {
    if (!photoFile) {
      console.error('Nenhum arquivo selecionado.');
      return;
    }
  
    setIsUploading(true);
    const formData = new FormData();
    const photoFileName = `pet${petId}_${Date.now()}.jpg`;
    formData.append('photo', photoFile, photoFileName);
    formData.append('petId', petId);
  
    try {
      const response = await axios.post(`https://vidapet.tech/upload_pet_photo.php`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      if (response.status === 200 && response.data.includes('sucesso')) {
        await loadPets();
        setEditingPetId(null);
        setSelectedFile(null);
      } else {
        console.error('Falha ao enviar foto do pet:', response.data);
      }
    } catch (error) {
      console.error('Erro ao enviar foto do pet:', error);
    } finally {
      setIsUploading(false);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleUploadPhoto = (petId) => {
    if (selectedFile) {
      handleAttachPhoto(petId, selectedFile);
    } else {
      alert('Selecione sua foto antes de salvar.');
    }
  };

  const handleEditPet = async (petId, updatedFields) => {
    try {
      const response = await axios.post(`https://vidapet.tech/edit_delete_pet.php`, {
        PetID: petId,
        fields: updatedFields
      });
      if (response.status === 200 && response.data.status === 'success') {
        await loadPets();
        setEditingPetId(null);
      } else {
        console.error('Falha ao atualizar pet:', response.data.message);
      }
    } catch (error) {
      console.error('Erro ao atualizar pet:', error);
    }
  };

  const handleSaveEditPet = async (petId) => {
    try {
      const updatedPetData = {};

      if (editPetData.name && editPetData.name.trim() !== '') {
        updatedPetData.PetName = editPetData.name;
      }

      if (editPetData.breed && editPetData.breed.trim() !== '') {
        updatedPetData.Breed = editPetData.breed;
      }

      if (editPetData.age && editPetData.age > 0) {
        updatedPetData.Age = editPetData.age;
      }

      if (Object.keys(updatedPetData).length === 0 && !selectedFile) {
        alert("Nenhuma alteração foi feita.");
        return;
      }

      if (selectedFile) {
        await handleAttachPhoto(petId, selectedFile);
      }

      if (Object.keys(updatedPetData).length > 0) {
        await handleEditPet(petId, updatedPetData);
      }

      alert("Dados atualizados com sucesso!");

      setEditingPetId(null);
    } catch (error) {
      console.error('Erro ao salvar edição do pet:', error);
      alert("Houve um erro ao atualizar os dados do pet. Tente novamente.");
    }
  };

  const handleDeletePet = async (petId) => {
    try {
      const confirmDelete = window.confirm('Você realmente quer excluir? Esta ação não pode ser desfeita.');
      if (confirmDelete) {
        const response = await axios.delete(`https://vidapet.tech/CRUD-pets.php?PetID=${petId}`);
        if (response.status === 200 && response.data.includes('sucesso')) { 
          alert("Pet excluído com sucesso.");
          await loadPets(); 
        } else {
          alert("Erro ao excluir o pet.");
        }
      }
    } catch (error) {
      console.error('Erro ao excluir pet:', error);
      alert("Erro ao tentar excluir o pet. Por favor, tente novamente.");
    }
  };

  const handleEditPhoto = (petId) => {
    setEditingPetId(petId);
    const petToEdit = pets.find(pet => pet.petId === petId);
    if (petToEdit) {
      setEditPetData({
        name: petToEdit.petName,
        breed: petToEdit.breed,
        age: petToEdit.age,
      });
    } else {
      console.error(`Pet with ID ${petId} not found`);
    }
  };

  const cancelEditPhoto = () => {
    setEditingPetId(null);
  };

  const toggleHealthEvents = (petId) => {
    setShowHealthEvents((prevShowHealthEvents) => ({
      ...prevShowHealthEvents,
      [petId]: !prevShowHealthEvents[petId],
    }));
  };

  const handleShowPetCard = (pet) => {
    setSelectedPet(pet);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedPet(null);
  };

  const handleEditInputChange = (event) => {
    const { name, value } = event.target;
    setEditPetData((prevData) => ({ ...prevData, [name]: value }));
  };

  const openPetForm = () => {
    setShowPetForm(true);
  };

  const closePetForm = () => {
    setShowPetForm(false);
    loadPets(); 
  };

  return (
    <div className="pet-list-container">
      <button className="add-pet-button" onClick={openPetForm}>Adicionar Novo Pet</button>
      {showPetForm && (
        <ModalBackground>
          <PetForm userId={userId} updatePetList={loadPets} closeForm={closePetForm} />
        </ModalBackground>
      )}
      {isPetsLoaded && pets.length > 0 ? (
        <div className="pet-cards-container">
          {pets.map((pet) => (
            <div key={pet.petId} className="pet-card">
              <div className="pet-header">
                <div className="photo-pet">
                  {editingPetId === pet.petId ? (
                    <div className="edit-photo-pet">
                      <IoMdCloseCircle onClick={cancelEditPhoto} className="closeButton" />
                      <MdDelete onClick={() => handleDeletePet(pet.petId)} className='deleteButton' />

                      <div className="edit-actions">
                        <label className="custom-file-label" htmlFor={`customFile_${pet.petId}`}>
                          Escolher Foto Pet
                        </label>
                        <input
                          type="file"
                          id={`customFile_${pet.petId}`}
                          className="custom-file-input"
                          accept="image/*"
                          onChange={handleFileChange}
                        />
                        <button
                          onClick={() => handleSaveEditPet(pet.petId)}
                          className="salveButton"
                        >
                          Salvar
                        </button>
                      </div>

                      <div className="edit-details">
                        <label>
                          Nome:
                          <input
                            type="text"
                            name="name"
                            value={editPetData.name}
                            onChange={handleEditInputChange}
                          />
                        </label>
                        <label>
                          Raça:
                          <input
                            type="text"
                            name="breed"
                            value={editPetData.breed}
                            onChange={handleEditInputChange}
                          />
                        </label>
                        <label>
                          Idade:
                          <input
                            type="number"
                            name="age"
                            value={editPetData.age}
                            onChange={handleEditInputChange}
                          />
                        </label>
                      </div>

                      {isUploading && <p>Enviando foto...</p>}
                    </div>
                  ) : (
                    <img
                      src={
                        pet.pet_3x4
                          ? `https://vidapet.tech/vidapet/pets/${pet.pet_3x4}?t=${new Date().getTime()}`
                          : petIcon
                      }
                      alt={pet.petName}
                      className="pet-photo"
                    />
                  )}
                </div>
              </div>
              <div className="pet-info">
                <h3>{pet.petName}</h3>
                <p><strong>Raça:</strong> {pet.breed}</p>
                <p><strong>Idade:</strong> {pet.age} anos</p>
                <div className="pet-card-icons">
                  <button className="icon-button" onClick={() => handleShowPetCard(pet)}>
                    <FaQrcode />
                  </button>
                  <button className="icon-button" onClick={() => toggleHealthEvents(pet.petId)}>
                    {/* Ícone para eventos de saúde */}
                  </button>
                  <button className="icon-button" onClick={() => handleEditPhoto(pet.petId)}>
                    <FaRegEdit />
                  </button>
                </div>
              </div>
              {showHealthEvents[pet.petId] && (
                <div className="expanded-content">
                  <HealthEventsView petId={pet.petId} />
                  <HealthEventForm petId={pet.petId} reloadEvents={() => toggleHealthEvents(pet.petId)} />
                </div>
              )}
            </div>
          ))}
        </div>
      ) : (
        <p>{isPetsLoaded ? 'Nenhum pet disponível.' : 'Carregando...'}</p>
      )}
      {modalIsOpen && selectedPet && (
        <ModalBackground>
          <ModalContent>
            <Suspense fallback={<div>Loading...</div>}>
              <Carteirinha 
                pet={selectedPet} 
                onClose={closeModal} 
                userId={userId} 
              />
            </Suspense>
          </ModalContent>
        </ModalBackground>
      )}
    </div>
  );
};

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: white;
  border-radius: 16px;
  width: 320px;
  max-height: 90vh;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
`;

export default PetList;
