import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Story = () => {
  const navigate = useNavigate();
  const [petRegistered, setPetRegistered] = useState(false);

  const goToDashboard = () => navigate('/dashboard');
  const goToRescue = () => navigate('/resgate');
  const goToPetCard = () => navigate('/carteirinha');

  const handleGetQrCode = () => {
    if (!petRegistered) {
      alert('É necessário cadastrar seu pet gratuitamente antes de solicitar a Tag QR Code. Na carteirinha digital do seu pet, você encontrará opções para solicitar a Tag QR Code Simples ou comprar a Tag Pingente.');
      navigate('/dashboard');
    } else {
      navigate('/dashboard'); // Adapte o caminho conforme necessário
    }
  };

  return (
    <div className="feature-section" style={{ maxWidth: '800px', margin: 'auto', padding: '20px' }}>
      <h1>🌟 Bem-vindo ao VidaPet.tech!</h1>
      
      <h2>📗 Carteirinha Digital de Saúde Pet</h2>
      <p>Vantagens:</p>
      <ul>
        <li>🔖 Cadastre as vacinas, exames e documentos do seu pet.</li>
        <li>📋 Mantenha tudo organizado e acessível.</li>
        <li>💉 Essencial para cuidar da saúde do seu pet!</li>
      </ul>
      <button onClick={goToDashboard} className="cta-button" style={{ margin: '10px 0' }}>💳 Cadastre seu Pet</button>
      
      <h2>🔍 Tag QR Code</h2>
      <p>Opções de Tag QR Code:</p>
      <ul>
        <li>🏷️ <strong>Tag QR Code Simples:</strong> Gratuita, ideal para identificação básica do seu pet.</li>
        <li>🔗 <strong>Tag Pingente:</strong> Mais durável e esteticamente agradável, disponível para compra.</li>
        <li>📲 Se perdido, permite contato direto com você pelo QR Code, facilitando um reencontro seguro e rápido.</li>
      </ul>
      <button onClick={handleGetQrCode} className="cta-button" style={{ margin: '10px 0' }}>💳 Obtenha sua Tag QR Code</button>
      
      <h2>🆘 Saiba Mais sobre Resgate</h2>
      <p>
        Descubra como nossa tecnologia inovadora ajuda a proteger e cuidar de pets perdidos ou abandonados:
      </p>
      <ul>
        <li>📸 Capture uma foto com o VidaPet.tech e ajude a reunir pets com suas famílias.</li>
      </ul>
      <p>
        No ecossistema de resgate, qualquer pessoa pode tirar uma foto de um pet perdido ou abandonado. A foto, juntamente com a geolocalização, é adicionada a um mapa interativo, permitindo que o dono do pet possa encontrá-lo facilmente. Se o pet possuir uma Tag QR Code, ao escanear, informações do pet e do tutor são disponibilizadas instantaneamente.
      </p>
      <button onClick={goToRescue} className="cta-button" style={{ margin: '10px 0' }}>🆘 Saiba Mais sobre Resgate</button>
    </div>
  );
};

export default Story;
