import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaArrowCircleRight } from 'react-icons/fa';
import './AdoptionView.css';

const AdoptionView = () => {
  const [adoptionData, setAdoptionData] = useState([]);
  const [filteredAdoptionData, setFilteredAdoptionData] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    // Buscar dados de adoção do backend
    fetch('https://vidapet.tech/api/DB/vidapettech/adoption/getadoptioncases.php')
      .then(response => response.json())
      .then(data => {
        console.log('Dados recebidos:', data); // Verifique os dados no console

        // Filtra casos com estado/cidade válidos
        const validCases = data.filter(
          (item) =>
            item.state &&
            item.city &&
            item.state.trim() !== '' &&
            item.city.trim() !== ''
        );

        setAdoptionData(validCases);
        setFilteredAdoptionData(validCases);

        // Extrair estados únicos
        const uniqueStates = [
          ...new Set(validCases.map((item) => item.state).filter(Boolean)),
        ];
        setStates(uniqueStates);
      })
      .catch((err) => console.error('Erro ao buscar dados:', err));
  }, []);

  // Quando o usuário seleciona um Estado, carrega as cidades desse estado
  const handleStateChange = (e) => {
    const state = e.target.value;
    setSelectedState(state);
    setSelectedCity('');

    if (state) {
      const citiesInState = adoptionData
        .filter((item) => item.state.toLowerCase() === state.toLowerCase())
        .map((item) => item.city);
      const uniqueCities = [...new Set(citiesInState)];
      setCities(uniqueCities);
    } else {
      setCities([]);
    }
  };

  const handleCityChange = (e) => {
    setSelectedCity(e.target.value);
  };

  // Sempre que state ou city mudarem, refiltra a lista
  useEffect(() => {
    if (selectedState && selectedCity) {
      const filtered = adoptionData.filter(
        (item) =>
          item.state.toLowerCase() === selectedState.toLowerCase() &&
          item.city.toLowerCase() === selectedCity.toLowerCase()
      );
      setFilteredAdoptionData(filtered);
    } else if (selectedState) {
      const filtered = adoptionData.filter(
        (item) => item.state.toLowerCase() === selectedState.toLowerCase()
      );
      setFilteredAdoptionData(filtered);
    } else {
      // Se não tiver nada selecionado, mostra tudo
      setFilteredAdoptionData(adoptionData);
    }
  }, [selectedState, selectedCity, adoptionData]);

  const handleFollow = (id) => {
    // Navega para a página de detalhes, ex: /adoptions/12
    navigate(`/adoptions/${id}`);
  };

  // Handler para o novo botão
  const handleAddPetAdoption = () => {
    const userConfirmed = window.confirm(
      'Para adicionar um Pet para adoção, você precisa cadastrar o Pet na lista de Pets, e após o cadastro do mesmo, clicar em Adoção para que o mesmo apareça na área de adoção.\n\nDeseja ser redirecionado para o painel de controle?'
    );

    if (userConfirmed) {
      window.location.href = 'https://vidapet.tech/dashboard';
    }
  };

  return (
    <div className="adoption-container">
      <div className="filter-section">
        <label>Estado:</label>
        <select value={selectedState} onChange={handleStateChange}>
          <option value="">Todos</option>
          {states.map((st, i) => (
            <option key={i} value={st}>
              {st}
            </option>
          ))}
        </select>

        <label>Cidade:</label>
        <select
          value={selectedCity}
          onChange={handleCityChange}
          disabled={!selectedState}
        >
          <option value="">Todas</option>
          {cities.map((ct, i) => (
            <option key={i} value={ct}>
              {ct}
            </option>
          ))}
        </select>
      </div>

      {/* Novo Botão Adicionado */}
      <div className="add-pet-button-wrapper">
        <button className="add-pet-button" onClick={handleAddPetAdoption}>
          Quero colocar um Pet para adoção
        </button>
      </div>

      <div className="cards-grid">
        {filteredAdoptionData.length > 0 ? (
          filteredAdoptionData.map((adopt) => (
            <div className="adoption-card" key={adopt.id}>
              <div className="adoption-image">
                <img
                  src={
                    adopt.pet_3x4
                      ? adopt.pet_3x4
                      : 'https://via.placeholder.com/300x200?text=Sem+Imagem'
                  }
                  alt={adopt.petName || adopt.title || 'Pet para adoção'}
                />
              </div>
              <div className="card-content">
                {/* Mostra o nome do pet (petName), se existir. Senão, o title */}
                <h3>{adopt.petName || adopt.title || 'Adoção Pet'}</h3>
                <p>
                  <strong>Motivo:</strong> {adopt.reason || 'Não informado'}
                </p>
                <p>
                  <strong>Local:</strong> {adopt.city}, {adopt.state}
                </p>
                <p>
                  <strong>Comentário:</strong> {adopt.comments || 'Nenhum comentário adicional.'}
                </p>
              </div>
              <div className="action-button-wrapper">
                <button
                  className="follow-button"
                  onClick={() => handleFollow(adopt.id)}
                >
                  Acompanhar Adoção <FaArrowCircleRight />
                </button>
              </div>
            </div>
          ))
        ) : (
          <p>Nenhuma adoção encontrada.</p>
        )}
      </div>
    </div>
  );
};

export default AdoptionView;
