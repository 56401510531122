import React from 'react';
import styled from 'styled-components';
import Equipe from './Time/Equipe';
import Parceiros from './Parceiros/Parceiros';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background-color: #f5f5f5;
`;

const Title = styled.h2`
  font-size: 2.5rem;
  color: #0F1E32;
  margin-bottom: 2rem;
  text-align: center;
`;

const Summary = styled.p`
  font-size: 1.2rem;
  color: #333;
  max-width: 800px;
  text-align: justify;
  margin-bottom: 2rem;
`;

const Timeline = styled.div`
  position: relative;
  max-width: 900px;
  margin: 2rem auto;
  padding: 20px;

  @media (max-width: 768px) {
    padding: 10px;
    max-width: 100%;
    margin: 1rem auto;
  }

  &::after {
    content: '';
    position: absolute;
    width: 6px;
    background-color: #FF7F50;
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);

    @media (max-width: 768px) {
      left: 30px;
      width: 4px;
    }
  }
`;

const TimelineItem = styled.div`
  padding: 10px 40px;
  position: relative;
  background-color: inherit;
  width: 50%;

  @media (max-width: 768px) {
    width: 100%;
    padding: 10px 10px 10px 60px;
    margin-bottom: 20px;
  }

  &::after {
    content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    background-color: white;
    border: 4px solid #FF7F50;
    top: 15px;
    border-radius: 50%;
    z-index: 1;

    @media (max-width: 768px) {
      width: 20px;
      height: 20px;
      left: 10px;
      top: 20px;
    }
  }

  &:nth-child(odd) {
    left: 0;
    transform: translateX(-30px);

    @media (max-width: 768px) {
      transform: translateX(0);
      left: 0;
    }

    &::after {
      right: -12.5px;

      @media (max-width: 768px) {
        right: auto;
      }
    }
  }

  &:nth-child(even) {
    left: 50%;
    transform: translateX(30px);

    @media (max-width: 768px) {
      left: 0;
      transform: translateX(0);
    }

    &::after {
      left: -12.5px;

      @media (max-width: 768px) {
        left: 10px;
      }
    }
  }
`;

const TimelineContent = styled.div`
  padding: 20px;
  background-color: white;
  position: relative;
  border-radius: 6px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const Year = styled.h3`
  margin: 0;
  font-size: 1.5rem;
  color: #FF7F50;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 1.3rem;
    text-align: left;
  }
`;

const EventTitle = styled.h4`
  margin-top: 10px;
  font-size: 1.2rem;
  color: #0F1E32;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 1.1rem;
    text-align: left;
  }
`;

const Text = styled.p`
  margin: 10px 0 0 0;
  font-size: 1rem;
  color: #333;
  text-align: justify;

  @media (max-width: 768px) {
    font-size: 0.95rem;
  }
`;

const IndexSobreNos = () => {
  return (
    <Container>
      <Title>Nossa História</Title>
      <Summary>
        A Startup VidaPet.Tech é um ecossistema digital voltado para o cuidado e a saúde dos pets. Nossa plataforma integra soluções de teleconsulta veterinária, carteirinha digital de saúde pet, resgate de pets perdidos e marketplace especializado, oferecendo uma experiência completa para tutores, veterinários e amantes dos animais. Com tecnologia inovadora e foco em segurança, estamos revolucionando a forma como você cuida do seu pet, conectando serviços e informações em um único lugar.
      </Summary>
      <Timeline>
        <TimelineItem>
          <TimelineContent>
            <Year>2022</Year>
            <EventTitle>Início da Jornada</EventTitle>
            <Text>
              A história da Startup VidaPet.Tech começou em 2022 com Andre Benther e sua cachorra Lili. Inspirado pela experiência com Lili, Andre iniciou o desenvolvimento de um sistema para captura e resgate de pets, unificando informações de saúde e registros de pets perdidos.
            </Text>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineContent>
            <Year>2023</Year>
            <EventTitle>Conexões e Avanços</EventTitle>
            <Text>
              Em 2023, A VidaPet.Tech expandiu o serviço, criando a Carteirinha Digital de Saúde Pet, melhorando o sistema de resgate e atualizando a tecnologia da plataforma.
            </Text>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineContent>
            <Year>2024</Year>
            <EventTitle>Incubadora e Registro no INPI</EventTitle>
            <Text>
              No final de 2023 e início de 2024, a Startup VidaPet.Tech foi selecionada para a incubadora OCA e fez o registro no INPI, consolidando oficialmente a marca VidaPet.Tech e recebendo apoio significativo para o desenvolvimento e sucesso de nossa startup.
            </Text>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineContent>
            <Year>2024</Year>
            <EventTitle>Teleconsulta Veterinária</EventTitle>
            <Text>
              A plataforma agora inclui uma solução de teleconsulta veterinária que está em fase de testes. Nossa meta é que essa funcionalidade esteja totalmente operacional até o final de 2024.
            </Text>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineContent>
            <Year>2024</Year>
            <EventTitle>Programa de Aceleração IBMEC Hubs</EventTitle>
            <Text>
              No final de 2024, a VidaPet.Tech foi aceita no programa de aceleração da IBMEC Hubs, uma instituição renomada que desempenhará um papel crucial em nosso crescimento. Este programa é uma oportunidade excepcional para aprimorarmos nossas soluções e expandirmos nossa rede de influência no mercado pet.
            </Text>
          </TimelineContent>
        </TimelineItem>
      </Timeline>
      
      <Equipe />
      <Parceiros />
    </Container>
  );
};

export default IndexSobreNos;
