import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './DoacaoPixQRCode.css';

const DoacaoPixQRCode = ({ onClose }) => {
  const navigate = useNavigate();
  const [clientName, setClientName] = useState('');
  const [clientCpf, setClientCpf] = useState('');
  const [clientEmail, setClientEmail] = useState('');
  const [selectedAmount, setSelectedAmount] = useState(null);
  const [customAmount, setCustomAmount] = useState('');
  const [coupon, setCoupon] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [error, setError] = useState(null);
  const [oBigFator, setOBigFator] = useState(null);
  const [oBigFatorQR, setOBigFatorQR] = useState(null);
  const [timeLeft, setTimeLeft] = useState(600); // 10 minutos
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [donationStep, setDonationStep] = useState("choose"); // "choose" ou "form"
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  // Opções de doação atualizadas (título e descrição separados):
  const donationOptions = [
    {
      amount: 20,
      title: 'R$20,00 - Promoção - Plano Confort 1 ano',
      description: '(1 Teleconsulta Orientativa + 1 Tag QR-Code para seu Pet)',
    },
    {
      amount: 50,
      title: 'R$50,00 - Plano Confort 1 ano',
      description: '(1 Teleconsulta Orientativa + 1 Tag QR-Code para seu Pet)',
    },
    {
      amount: 100,
      title: 'R$100,00 - Plano Plus 1 ano',
      description: '(3 Teleconsultas: orientativa, acompanhamento e emergência + 1 Tag QR-Code com Geolocalização e Chat)',
    },
    {
      amount: null,
      title: 'Outro valor',
      description: '(a partir de R$5,00)',
      custom: true,
    },
  ];

  const isValidCpf = (cpf) => /^\d{11}$/.test(cpf);
  const isValidName = (name) => name.trim().length > 0;
  const isValidEmail = (email) => /\S+@\S+\.\S+/.test(email);

  const getPixQrCode = async (billingId) => {
    try {
      setStatusMessage('Obtendo QR Code Pix...');
      const response = await axios.get(
        `https://vidapet.tech/api/pgs/asaas/get_pix_qr.php?billing_id=${billingId}`
      );
      const dataSplit = response.data.split('}{');
      let qrCodeData;
      if (dataSplit.length === 2) {
        const part1 = dataSplit[0] + '}';
        const part2 = '{' + dataSplit[1];
        qrCodeData = { ...JSON.parse(part1), ...JSON.parse(part2) };
      } else {
        qrCodeData = JSON.parse(response.data);
      }
      setOBigFator(qrCodeData.encodedImage);
      setOBigFatorQR(qrCodeData.payload);
      return qrCodeData;
    } catch (error) {
      console.error('Erro ao obter QR Code Pix:', error);
      setError('Falha ao carregar QR Code. Por favor, tente novamente.');
      throw error;
    }
  };

  const recordDonation = async (extraData = {}) => {
    try {
      const payload = {
        client_name: clientName,
        client_cpf: clientCpf,
        client_email: clientEmail,
        amount:
          customAmount && parseFloat(customAmount) >= 5
            ? parseFloat(customAmount)
            : selectedAmount,
        status_pagamento: 'pago',
        metodo_pagamento:
          coupon.trim().toUpperCase() === 'PAGO' ? 'CUPOM' : 'PIX',
        billing_id: coupon.trim().toUpperCase() === 'PAGO' ? null : oBigFatorQR,
        coupon_code: coupon ? coupon : null,
        ...extraData,
      };

      await axios.post(
        'https://vidapet.tech/api/DB/vidapettech/serv_Apis/Donat/program_donation_records.php',
        payload
      );
      console.log('Registro de doação criado com sucesso.');
    } catch (error) {
      console.error('Erro ao registrar a doação:', error);
    }
  };

  const handleDonationSelection = (option) => {
    if (option.custom) {
      setSelectedAmount(null);
    } else {
      setSelectedAmount(option.amount);
    }
    setShowConfirmationModal(true);
  };

  const handleProceedToForm = () => {
    setShowConfirmationModal(false);
    setDonationStep("form");
  };

  const handleDonation = async () => {
    setIsProcessing(true);
    setError(null);
    const amount =
      customAmount && parseFloat(customAmount) >= 5
        ? parseFloat(customAmount)
        : selectedAmount;
    if (!isValidCpf(clientCpf) || !isValidName(clientName) || !isValidEmail(clientEmail)) {
      setError('Preencha os campos corretamente (CPF, nome e email).');
      setIsProcessing(false);
      return;
    }
    try {
      setStatusMessage('Verificando cliente...');
      let clientResponse = await axios.get(
        `https://vidapet.tech/api/pgs/asaas/clients_added_db.php?cpf=${clientCpf}`
      );
      let clientId = clientResponse.data.client_id;
      if (!clientId) {
        setStatusMessage('Criando novo cliente...');
        clientResponse = await axios.post(
          'https://vidapet.tech/api/pgs/asaas/create_client.php',
          {
            nome_completo: clientName,
            cpf: clientCpf,
            email: clientEmail,
          }
        );
        if (clientResponse.data.status !== 'success') {
          throw new Error(clientResponse.data.message || 'Erro ao criar cliente.');
        }
        clientId = clientResponse.data.id;
      }
      setStatusMessage('Gerando pagamento...');
      const billingResponse = await axios.post(
        'https://vidapet.tech/api/pgs/asaas/create_billing.php',
        {
          customer: clientId,
          value: amount,
        }
      );
      if (!billingResponse.data.id) {
        throw new Error('Erro ao gerar pagamento.');
      }
      await getPixQrCode(billingResponse.data.id);
      setPaymentStatus('pending');
      console.log('⌛ Monitorando pagamento...');
      let attempts = 0;
      const interval = setInterval(async () => {
        if (attempts >= 20) {
          clearInterval(interval);
          setPaymentStatus('timeout');
          setError('Tempo limite para confirmação de pagamento excedido.');
          return;
        }
        console.log(`🔄 Tentativa ${attempts + 1} de verificação do pagamento...`);
        const paymentStatusResponse = await axios.get(
          `https://vidapet.tech/api/pgs/asaas/check_payment_status.php?billing_id=${billingResponse.data.id}`
        );
        if (paymentStatusResponse.data.paymentConfirmed) {
          clearInterval(interval);
          setPaymentStatus('confirmed');
          setStatusMessage('Pagamento confirmado! Obrigado por sua doação.');
          console.log('🎉 Pagamento confirmado!');
          await recordDonation();
          setShowSuccessModal(true);
        }
        attempts++;
      }, 10000);
    } catch (error) {
      console.error('❌ Erro ao processar pagamento:', error);
      setError(error.message || 'Erro ao processar pagamento.');
    } finally {
      setIsProcessing(false);
    }
  };

  const handleCouponSubmit = async () => {
    if (coupon.trim().toUpperCase() === 'PAGO') {
      setPaymentStatus('confirmed');
      setStatusMessage('Pagamento confirmado via cupom!');
      await recordDonation();
      setShowSuccessModal(true);
    } else {
      setError('Cupom inválido.');
    }
  };

  useEffect(() => {
    let timer;
    if (timeLeft > 0 && paymentStatus !== 'confirmed') {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [timeLeft, paymentStatus]);

  const formatTime = (seconds) => {
    const min = Math.floor(seconds / 60);
    const sec = seconds % 60;
    return `${min}:${sec < 10 ? '0' + sec : sec}`;
  };

  const handleSuccessModalClose = () => {
    setShowSuccessModal(false);
    onClose();
  };

  return (
    <div className="doacao-container">
      <h2>Faça sua Doação</h2>
      <p>E vamos mudar o Mundo como lidamos com os Pets!</p>

      {donationStep === "choose" && (
        <div className="options">
          {donationOptions.map((option, index) => (
            <div key={index} className="option-item">
              <div className="option-info">
                <h3>{option.title}</h3>
                <p>{option.description}</p>
              </div>
              <button
                className="option-button"
                onClick={() => handleDonationSelection(option)}
              >
                Escolher
              </button>
            </div>
          ))}
        </div>
      )}

      {showConfirmationModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>Confirmação de Doação</h3>
            <p>
              Você selecionou R$ {selectedAmount !== null ? selectedAmount : 'Outro valor'}.
            </p>
            <p>
              Preencha os dados abaixo para gerar o Pix da sua doação.
            </p>
            <p>
              Após a confirmação do pagamento, você receberá nosso e-mail com o certificado e as instruções para ativação do seu plano.
            </p>
            <button onClick={handleProceedToForm}>Prosseguir</button>
          </div>
        </div>
      )}

      {donationStep === "form" && paymentStatus !== 'confirmed' && (
        <>
          <input
            type="text"
            placeholder="Nome completo"
            value={clientName}
            onChange={(e) => setClientName(e.target.value)}
          />
          <input
            type="text"
            placeholder="CPF (somente números)"
            value={clientCpf}
            onChange={(e) => setClientCpf(e.target.value)}
          />
          <input
            type="email"
            placeholder="Email"
            value={clientEmail}
            onChange={(e) => setClientEmail(e.target.value)}
          />
          {selectedAmount === null && (
            <input
              type="number"
              placeholder="Digite um valor (mínimo R$5,00)"
              value={customAmount}
              onChange={(e) => setCustomAmount(e.target.value)}
            />
          )}
          <button onClick={handleDonation} disabled={isProcessing}>
            {isProcessing ? 'Processando...' : 'Gerar QR Code'}
          </button>
        </>
      )}

      {oBigFator && (
        <div className="qr-code-container">
          <h3>QR Code Pix:</h3>
          <img src={`data:image/png;base64,${oBigFator}`} alt="QR Code Pix" />
          <p>Copia e Cola: {oBigFatorQR}</p>
          <button onClick={() => navigator.clipboard.writeText(oBigFatorQR)}>
            Copiar Código Pix
          </button>
          {paymentStatus === 'pending' && (
            <p className="timer">Tempo restante para pagamento: {formatTime(timeLeft)}</p>
          )}
        </div>
      )}

      {donationStep === "form" && paymentStatus !== 'confirmed' && oBigFator && (
        <div className="coupon-container">
          <input
            type="text"
            placeholder="Cupom"
            value={coupon}
            onChange={(e) => setCoupon(e.target.value)}
          />
          <button onClick={handleCouponSubmit}>Validar Cupom</button>
        </div>
      )}

      <button className="close-button" onClick={onClose}>
        Fechar
      </button>

      {error && <p className="error-message">{error}</p>}
      {statusMessage && <p className="status-message">{statusMessage}</p>}

      {showSuccessModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>Parabéns!</h3>
            <p>
              Sua doação foi realizada com sucesso. Agora, aguarde nosso e-mail com o certificado e as instruções para ativação do seu plano.
            </p>
            <button onClick={handleSuccessModalClose}>OK</button>
          </div>
        </div>
      )}

      {/* Botão para conhecer os planos (exibido somente se ainda não foi escolhida uma opção) */}
      {donationStep === "choose" && (
        <button className="marketplace-button" onClick={() => navigate('/planos-promocao')}>
          👉 Conheça os nossos planos!
        </button>
      )}
    </div>
  );
};

export default DoacaoPixQRCode;
