import React, { useState, useEffect } from 'react';
import { FaTrashAlt } from 'react-icons/fa'; // Ícone de lixeira para exclusão
import './RescueFeeds.css'; // Importação correta do CSS

const RescueFeeds = ({ rescueId, refreshFeeds }) => {
  const [rescueHistory, setRescueHistory] = useState([]);

  useEffect(() => {
    const fetchRescueHistory = async () => {
      try {
        const response = await fetch(`https://vidapet.tech/api/resgate/rescue_feeds.php?rescue_id=${rescueId}`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        });
        const data = await response.json();

        if (data.success) {
          // Filtra as mensagens que não foram deletadas
          const filteredHistory = data.history.filter(event => event.is_deleted !== 'sim');
          setRescueHistory(filteredHistory);
        } else {
          console.error('Erro ao buscar o histórico:', data.message);
        }
      } catch (error) {
        console.error('Erro ao buscar o histórico do resgate:', error);
      }
    };

    fetchRescueHistory();
  }, [rescueId, refreshFeeds]); // Atualiza o histórico quando rescueId ou refreshFeeds mudar

  const handleDeleteComment = async (msgId, userId) => {
    const confirmDelete = window.confirm('Você realmente deseja excluir este comentário?');
    if (confirmDelete) {
      const secondConfirm = window.confirm('Tem certeza? Esta ação não pode ser desfeita.');
      if (secondConfirm) {
        try {
          const response = await fetch('https://vidapet.tech/api/resgate/rescue_msgs.php', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              rescue_id: rescueId,
              user_id: userId,
              msg_id: msgId, // Usando msg_id para identificar a mensagem
              is_deleted: 'sim',  // Atualizando corretamente o campo is_deleted
            }),
          });

          const data = await response.json();
          if (data.success) {
            // Remove diretamente o comentário deletado do estado local, sem refazer a busca
            setRescueHistory((prevHistory) =>
              prevHistory.filter((event) => event.msg_id !== msgId)
            );
            alert('Comentário excluído com sucesso.');
          } else {
            alert('Erro ao excluir o comentário.');
          }
        } catch (error) {
          console.error('Erro ao excluir o comentário:', error);
        }
      }
    }
  };

  const formatMessage = (userId, message, actionType) => {
    if (actionType === 'found' || actionType === 'rescued') {
      return `Usuário ID: ${userId} marcou o pet como ${actionType === 'found' ? 'encontrado' : 'resgatado'}.`;
    } else if (!message.includes('Status alterado para')) {
      return `Comentário do Usuário (ID: ${userId}): ${message}`;
    }
    return message;
  };

  return (
    <div className="rescue-feeds-container">
      <h3>Histórico do Caso</h3>
      <div className="rescue-feeds-content">
        {rescueHistory.length > 0 ? (
          rescueHistory.map((event, index) => (
            <div key={index} className={`history-event ${!event.message.includes('Status alterado para') ? 'user-comment' : ''}`}>
              <p>
                <strong>{new Date(event.date).toLocaleString()}</strong>: {formatMessage(event.user_id, event.message, event.action_type)}
                {!event.message.includes('Status alterado para') && (
                  <FaTrashAlt
                    className="delete-icon"
                    onClick={() => handleDeleteComment(event.msg_id, event.user_id)} // Usando msg_id
                  />
                )}
              </p>
            </div>
          ))
        ) : (
          <p>Nenhum evento registrado ainda.</p>
        )}
      </div>
    </div>
  );
};

export default RescueFeeds;
