import React from 'react';
import { FaPaw, FaVideo, FaShieldAlt, FaQuestionCircle } from 'react-icons/fa';

const DashboardHome = ({ onAccess }) => {
  return (
    <div style={styles.container}>
      {/* Overlay escurecendo levemente o fundo */}
      <div style={styles.overlay}></div>

      {/* Conteúdo principal, acima do overlay */}
      <div style={styles.content}>
        <div style={styles.header}>
          <p style={styles.subtitle}>
            Aqui você pode gerenciar facilmente todas as informações de saúde, vacinas e documentos do seu Pet.
          </p>
        </div>

        <div style={styles.tutorialContainer}>
          <div style={styles.tutorialStep}>
            <FaPaw size={24} color="#FF6347" style={styles.tutorialIcon} />
            <div style={styles.stepInfo}>
              <h4 style={styles.stepTitle}>Lista de Pets</h4>
              <p style={styles.stepDesc}>
                Cadastre cada pet e acompanhe suas informações vitais, vacinas e exames em um só lugar.
              </p>
              <button style={styles.navButton} onClick={() => onAccess('pets')}>
                Ir para a Lista de Pets
              </button>
            </div>
          </div>

          <div style={styles.tutorialStep}>
            <FaVideo size={24} color="#FF6347" style={styles.tutorialIcon} />
            <div style={styles.stepInfo}>
              <h4 style={styles.stepTitle}>Teleconsulta Vet</h4>
              <p style={styles.stepDesc}>
                Faça consultas por vídeochamada com seu veterinário, sem sair de casa!
              </p>
              <button style={styles.navButton} onClick={() => onAccess('teleconsulta')}>
                Acessar Teleconsulta Vet
              </button>
            </div>
          </div>

          <div style={styles.tutorialStep}>
            <FaShieldAlt size={24} color="#FF6347" style={styles.tutorialIcon} />
            <div style={styles.stepInfo}>
              <h4 style={styles.stepTitle}>Convênios e Planos</h4>
              <p style={styles.stepDesc}>
                Escolha o melhor plano de saúde ou convênio para proteger seu pet de imprevistos.
              </p>
              <button style={styles.navButton} onClick={() => onAccess('convenios')}>
                Ver Planos e Convênios
              </button>
            </div>
          </div>
        </div>

        <div style={styles.helpContainer}>
          <FaQuestionCircle size={24} color="#FF6347" />
          <p style={styles.helpText}>
            Em caso de dúvidas, consulte nosso{' '}
            <a href="/faq" style={styles.helpLink}>
              FAQ
            </a>{' '}
            ou fale conosco no WhatsApp.
          </p>
        </div>
      </div>
    </div>
  );
};

export default DashboardHome;

/* ===================== Estilos Inline ===================== */
const styles = {
  container: {
    position: 'relative',        // Importante para overlay
    backgroundColor: 'transparent',
    padding: '20px',
    minHeight: '100vh',
  },

  // Camada escura semitransparente para melhorar contraste
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.4)', // Ajuste a opacidade a gosto
    zIndex: 1,
  },

  // Conteúdo principal fica acima do overlay
  content: {
    position: 'relative',
    zIndex: 2,
    color: '#fff',              // Texto branco para melhor contraste
  },

  header: {
    marginBottom: '15px',
  },

  subtitle: {
    fontSize: '18px',          // Aumentado para melhor leitura
    color: '#fff',
    lineHeight: '1.5',
    fontWeight: 'normal',
  },

  tutorialContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    marginTop: '20px',
    marginBottom: '20px',
  },

  tutorialStep: {
    backgroundColor: 'transparent',
    borderRadius: '8px',
    padding: '10px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  tutorialIcon: {
    marginRight: '10px',
  },

  stepInfo: {
    flex: 1,
  },

  stepTitle: {
    color: '#FF6347',
    fontSize: '20px',           // Aumentado
    marginBottom: '8px',
    fontWeight: 'bold',
  },

  stepDesc: {
    color: '#fff',
    fontSize: '16px',           // Aumentado
    lineHeight: '1.4',
  },

  navButton: {
    backgroundColor: '#FF6347',
    color: '#fff',
    border: 'none',
    padding: '12px 16px',
    borderRadius: '6px',
    fontSize: '15px',
    cursor: 'pointer',
    marginTop: '10px',
  },

  helpContainer: {
    backgroundColor: 'transparent',
    borderRadius: '8px',
    padding: '12px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  helpText: {
    marginLeft: '8px',
    fontSize: '15px',
    lineHeight: '1.4',
  },

  helpLink: {
    color: '#FF6347',
    textDecoration: 'none',
    marginLeft: '4px',
  },
};
