import React, { useEffect, useState } from 'react';
import './Cartalogo.css'; 
import Filtro from './Components/FilterServicos';
import { Link } from 'react-router-dom';

function Servicos() {
  const [partners, setPartners] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [bairro, setBairro] = useState('');
  const [categoria, setCategoria] = useState('');
  const [cidade, setCidade] = useState('');
  const [estado, setEstado] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const [showPopup, setShowPopup] = useState(true); // Estado para controlar visibilidade do popup

  useEffect(() => {
    fetchPartners();
  }, [bairro, categoria, cidade, estado]);

  const fetchPartners = () => {
    let url = 'https://vidapet.tech/parceiros_mkt.php';
    const params = [];

    if (bairro) params.push(`bairro=${bairro}`);
    if (categoria) params.push(`categoria=${categoria}`);
    if (cidade) params.push(`cidade=${cidade}`);
    if (estado) params.push(`estado=${estado}`);

    if (params.length > 0) {
      url += '?' + params.join('&');
    }

    fetch(url)
      .then(response => response.json())
      .then(data => {
        if (Array.isArray(data)) {
          setPartners(data);
        } else {
          console.error('Unexpected data format:', data);
        }
      })
      .catch(error => console.error('Error fetching partners:', error));
  };

  const filteredPartners = partners.filter(partner =>
    partner.nome.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleWhatsApp = () => {
    const message = "Olá! Gostaria de cadastrar meu serviço ou loja pet na sua vitrine.";
    const phoneNumber = "5521982992635"; // Substitua com o número de WhatsApp desejado
    window.open(`https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`, '_blank');
  };

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const closePopup = () => {
    setShowPopup(false); // Fecha o popup
  };

  return (
    <div className="servicos-container">
      {/* Popup de Chamada à Ação */}
      {showPopup && (
        <div className="call-to-action-fixed">
          <button className="close-button" onClick={closePopup}>X</button>
          <h2>Transforme Seu Negócio Pet e Atraia Mais Clientes!</h2>
          <p>Não deixe que a oportunidade de aumentar sua visibilidade passe. Ao cadastrar sua loja ou serviço na nossa vitrine, você se conecta com um público apaixonado por pets, pronto para descobrir o que você oferece.</p>
          <p><strong>Não cadastrar agora é perder de exposição. Aproveite a chance de se destacar agora!</strong></p>
          <button className="cta-button" onClick={handleWhatsApp}>
            Quero me cadastrar agora e crescer meu negócio!
          </button>
        </div>
      )}

      {/* Botão para alternar a visibilidade dos filtros */}
      <button className="filter-button" onClick={toggleFilters}>
        {showFilters ? 'Ocultar Filtros' : 'Mostrar Filtros'}
      </button>

      {/* Exibe o Filtro se showFilters for true */}
      {showFilters && (
        <Filtro 
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          bairro={bairro}
          setBairro={setBairro}
          categoria={categoria}
          setCategoria={setCategoria}
          cidade={cidade}
          setCidade={setCidade}
          estado={estado}
          setEstado={setEstado}
        />
      )}

      <div className="servicos-grid">
        {filteredPartners.length > 0 ? (
          filteredPartners.map(partner => (
            <div className="servicos-item" key={partner.id}>
              <Link to={`/servico/${partner.id}`}>
                <img src={partner.logo_url} alt={partner.nome_fantasia} />
                <h3>{partner.nome_fantasia}</h3>
                <p><strong>Endereço:</strong> {partner.endereco_comercial} {partner.bairro}</p>
                <p><strong>WhatsApp:</strong> {partner.whatsapp}</p>
                {partner.categoria}
              </Link>
              <div className="partner-links">
                <a href={`/servico/${partner.id}`} target="_blank" rel="noopener noreferrer" className='FollowButton'>Saiba Mais</a>
              </div>
            </div>
          ))
        ) : (
          <p>Nenhum parceiro encontrado</p>
        )}
      </div>
    </div>
  );
}

export default Servicos;
