import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import axios from 'axios';

// Este modal é importado e usado tanto em "ConveniosInfo" (mensalidade)
// quanto em "ParcelasListModal" (parcela específica)

const MensalidadePayments = ({ convenio, parcela, onClose }) => {
  // Se a "parcela" vier preenchida, pegamos o valor dela. Senão, assumimos valor do convênio
  const valorMensal = parcela 
    ? (parcela.valor || 50)
    : (convenio.modalidades && convenio.modalidades.length)
      ? convenio.modalidades[0].valor
      : 50;

  // Descrição que aparece no modal
  const descricao = parcela
    ? `Parcela #${parcela.numero_parcela}`
    : `Próxima Mensalidade`;

  // Estados do formulário
  const [clientName, setClientName] = useState('');
  const [clientCpf, setClientCpf] = useState('');
  const [clientEmail, setClientEmail] = useState('');

  // Controle de processamento e mensagens
  const [isProcessing, setIsProcessing] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');
  const [error, setError] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(null);

  // QR Code
  const [qrBase64, setQrBase64] = useState(null);
  const [qrPayload, setQrPayload] = useState(null);

  // Timer de 10min para pagamento
  const [timeLeft, setTimeLeft] = useState(600);

  // Tentativas de checagem do pagamento
  const [checkAttempts, setCheckAttempts] = useState(0);

  // Cupom
  const [coupon, setCoupon] = useState('');

  // ========== Validações básicas ==========
  const isValidCpf = (cpf) => /^\d{11}$/.test(cpf);
  const isValidName = (name) => name.trim().length>0;
  const isValidEmail = (email) => /\S+@\S+\.\S+/.test(email);

  // ========== Lógica de pagamento Asaas (simplificada) ==========
  const checkClientInDatabase = useCallback(async (cpf)=> {
    setStatusMessage('Verificando cliente no banco de dados...');
    const resp = await axios.get(`https://vidapet.tech/api/pgs/asaas/clients_added_db.php?cpf=${cpf}`);
    return resp.data;
  },[]);

  const createClientInAsaas = useCallback(async (name, cpf, email)=> {
    setStatusMessage('Criando cliente no Asaas...');
    const resp = await axios.post('https://vidapet.tech/api/pgs/asaas/create_client.php',{
      nome_completo: name, cpf, email
    });
    return resp.data;
  },[]);

  const getClientIdAfterDelay = useCallback(async (cpf)=> {
    setStatusMessage('Aguardando confirmação do cliente...');
    let retries=10;
    while(retries>0){
      await new Promise(r=>setTimeout(r,2000));
      try{
        const c = await checkClientInDatabase(cpf);
        if(c && c.client_id) return c.client_id;
      } catch{}
      retries--;
    }
    throw new Error('ID do cliente não apareceu no DB após várias tentativas.');
  },[checkClientInDatabase]);

  const createBillingInAsaas = useCallback(async (customerId)=> {
    setStatusMessage('Criando cobrança no Asaas...');
    const resp = await axios.post('https://vidapet.tech/api/pgs/asaas/create_billing.php',{
      customer: customerId,
      value: valorMensal
    });
    return resp.data;
  },[valorMensal]);

  const registerBillingInBackend = useCallback(async (data)=> {
    setStatusMessage('Registrando cobrança no backend...');
    const resp = await axios.post(
      'https://vidapet.tech/api/pgs/asaas/create_billing_db.php',
      data,
      { headers: { 'Content-Type': 'application/json' } }
    );
    return resp.data;
  },[]);

  const getPixQrCode = useCallback(async (billingId)=> {
    setStatusMessage('Obtendo QR Code Pix...');
    const resp = await axios.get(`https://vidapet.tech/api/pgs/asaas/get_pix_qr.php?billing_id=${billingId}`);
    const splitted = resp.data.split('}{');
    if(splitted.length===2){
      const [p1,p2] = splitted.map((x,i)=>i===0?x+'}':'{'+x);
      return JSON.parse(p1);
    } else {
      return JSON.parse(resp.data);
    }
  },[]);

  const checkPaymentStatus = useCallback(async (billingId)=> {
    setStatusMessage('Verificando status do pagamento...');
    const resp = await axios.get(`https://vidapet.tech/api/pgs/asaas/check_payment_status.php?billing_id=${billingId}`);
    return resp.data;
  },[]);

  // ========== Registrar pagamento (serv_pagamentos_convenios) e atualizar convênio ==========
  const registerConvenioPayment = useCallback(async (codigoTransacao)=> {
    try {
      const dataPagamento = new Date().toISOString().split('T')[0];

      // (a) Cria registro em serv_pagamentos_convenios
      await axios.post('https://vidapet.tech/api/DB/vidapettech/serv_Apis/Convenios/create_convenio_payment.php', {
        convenio_contrato_id: convenio.contrato_id,
        data_pagamento: dataPagamento,
        valor: valorMensal,
        status_pagamento: 'pago',
        metodo_pagamento: 'PIX',
        codigo_transacao: codigoTransacao
      });

      // (b) Se for parcela, chamamos pay_parcela_convenio.php
      if (parcela) {
        await axios.post('https://vidapet.tech/api/DB/vidapettech/serv_Apis/Convenios/pay_parcela_convenio.php', {
          parcela_id: parcela.id
        });
      }

      // (c) Atualiza convênio (data_ultimo_pagamento, data_proximo_vencimento)
      const prox = new Date();
      prox.setMonth(prox.getMonth()+1);
      const dataProx = prox.toISOString().split('T')[0];

      await axios.post('https://vidapet.tech/api/DB/vidapettech/serv_Apis/Convenios/update_user_pet_convenio.php',{
        contrato_id: convenio.contrato_id,
        data_ultimo_pagamento: dataPagamento,
        data_proximo_vencimento: dataProx
      });

      // Final
      setPaymentStatus('confirmed');
      setStatusMessage(`${descricao} paga com sucesso!`);
    } catch(err) {
      console.error(err);
      setError('Erro ao registrar pagamento.');
    }
  },[convenio, parcela, valorMensal, descricao]);

  // ========== Monitoramento do pagamento (loop) ==========
  const monitorPayment = useCallback((billingId)=>{
    const interval = setInterval(async ()=>{
      try{
        const st = await checkPaymentStatus(billingId);
        setCheckAttempts(a=>a+1);

        // Confirmou pagamento
        if(st.paymentConfirmed){
          clearInterval(interval);
          setPaymentStatus('confirmed');
          setStatusMessage(`${descricao} confirmada via Pix!`);
          // Chama registerConvenioPayment => insere e atualiza
          await registerConvenioPayment(billingId);
        }

        // Se passar de X tentativas, desiste
        if(checkAttempts>=20 && paymentStatus!=='confirmed'){
          clearInterval(interval);
          setPaymentStatus('timeout');
          setError('Tempo limite excedido para confirmação.');
        }
      } catch(e){
        clearInterval(interval);
        setError('Erro no monitoramento de pagamento.');
      }
    },10000);
  },[
    checkPaymentStatus, checkAttempts, paymentStatus,
    registerConvenioPayment, descricao
  ]);

  // ========== Timer de 10min (timeLeft) ==========
  useEffect(()=>{
    if(timeLeft>0 && paymentStatus!=='confirmed'){
      const timer = setInterval(()=>setTimeLeft(t=>t-1),1000);
      return ()=>clearInterval(timer);
    } else if(timeLeft<=0 && paymentStatus!=='confirmed'){
      setPaymentStatus('timeout');
      setError('Tempo limite para confirmação de pagamento excedido.');
    }
  },[timeLeft,paymentStatus]);

  // ========== handleGeneratePayment (inicia a cobrança Pix) ==========
  const handleGeneratePayment = useCallback(async ()=>{
    setError(null);

    // Validações simples
    if(!isValidCpf(clientCpf)){
      setError('CPF inválido (11 dígitos)');
      return;
    }
    if(!isValidName(clientName)){
      setError('Nome completo inválido');
      return;
    }
    if(!isValidEmail(clientEmail)){
      setError('Email inválido');
      return;
    }

    setIsProcessing(true);
    setStatusMessage('Gerando cobrança Pix...');

    try{
      // 1) Verifica/Cria cliente Asaas
      let clientId;
      const existing = await checkClientInDatabase(clientCpf);
      if(existing.status==='success' && existing.client_id){
        clientId = existing.client_id;
      } else {
        const created = await createClientInAsaas(clientName, clientCpf, clientEmail);
        if(created.status==='success'){
          const cid = await getClientIdAfterDelay(clientCpf);
          clientId = cid;
        } else {
          throw new Error('Falha ao criar cliente no Asaas');
        }
      }

      // 2) Cria cobrança
      const billing = await createBillingInAsaas(clientId);
      if(!billing || !billing.id){
        throw new Error('Falha inesperada ao criar cobrança');
      }

      // 3) Registra cobrança no backend
      const billData = {
        client_id: clientId,
        amount: billing.value,
        asaas_billing_id: billing.id,
        convenio_id: convenio.contrato_id,
        modalidade_id: 0 
      };
      const reg = await registerBillingInBackend(billData);
      if(reg.status==='pending'){
        // 4) Obter QR Code
        const qr = await getPixQrCode(billing.id);
        if(qr.encodedImage && qr.payload){
          setQrBase64(qr.encodedImage);
          setQrPayload(qr.payload);
          setStatusMessage(`Pague ${descricao} (R$ ${billing.value.toFixed(2)}) via Pix`);
          // 5) Monitorar
          monitorPayment(billing.id);
        } else {
          throw new Error('Não foi possível obter QR Code Pix');
        }
      } else {
        throw new Error('Erro ao registrar cobrança no backend');
      }
    } catch(e){
      console.error(e);
      setError(e.message || 'Erro ao gerar pagamento Pix');
    } finally {
      setIsProcessing(false);
    }
  },[
    clientName, clientCpf, clientEmail,
    convenio, descricao, valorMensal,
    checkClientInDatabase, createClientInAsaas, getClientIdAfterDelay,
    createBillingInAsaas, registerBillingInBackend, getPixQrCode, monitorPayment
  ]);

  // ========== handleCouponSubmit (cupom de teste) ==========
  const handleCouponSubmit = useCallback(async()=>{
    if(coupon.trim().toUpperCase()==='PAGO'){
      // Marca pagamento como confirmado
      setPaymentStatus('confirmed');
      setStatusMessage(`${descricao} paga via Cupom!`);
      // Chama registerConvenioPayment sem passar pela Asaas
      await registerConvenioPayment('CUPOM-PAGO');
    } else {
      setError('Cupom inválido');
    }
  },[coupon, registerConvenioPayment, descricao]);

  // ========== Renderização Condicional ==========

  // Pagamento Concluído
  if(paymentStatus==='confirmed'){
    return (
      <MiniModalOverlay>
        <MiniModalContent>
          <h3>Pagamento Concluído!</h3>
          <p>{statusMessage}</p>
          <button onClick={onClose}>Fechar</button>
        </MiniModalContent>
      </MiniModalOverlay>
    );
  }

  // Tempo Esgotado
  if(paymentStatus==='timeout'){
    return (
      <MiniModalOverlay>
        <MiniModalContent>
          <h3>Tempo Esgotado!</h3>
          <p>{error}</p>
          <button onClick={onClose}>Fechar</button>
        </MiniModalContent>
      </MiniModalOverlay>
    );
  }

  // Fluxo normal
  return (
    <MiniModalOverlay>
      <MiniModalContent>
        <h3>{descricao}</h3>
        <p>Valor: R$ {valorMensal}</p>

        <input
          type="text"
          placeholder="Nome completo"
          value={clientName}
          onChange={(e)=>setClientName(e.target.value)}
        />
        <input
          type="text"
          placeholder="CPF (somente números)"
          value={clientCpf}
          onChange={(e)=>setClientCpf(e.target.value)}
        />
        <input
          type="email"
          placeholder="Email"
          value={clientEmail}
          onChange={(e)=>setClientEmail(e.target.value)}
        />

        <button onClick={handleGeneratePayment} disabled={isProcessing}>
          {isProcessing ? 'Processando...' : `Gerar Pix (${descricao})`}
        </button>

        {statusMessage && <p>{statusMessage}</p>}
        {error && <p style={{color:'red'}}>{error}</p>}

        {/* Se já temos QR code e não está confirmado */}
        {qrBase64 && qrPayload && paymentStatus!=='confirmed' && (
          <>
            <div style={{margin:'10px 0'}}>
              <img
                src={`data:image/png;base64,${qrBase64}`}
                alt="QR Pix"
                style={{width:'200px',height:'200px'}}
              />
              <p>Copia e Cola: {qrPayload}</p>
              <button onClick={()=>{
                navigator.clipboard.writeText(qrPayload);
                alert('Código Pix copiado!');
              }}>
                Copiar Código Pix
              </button>
              <p>
                Tempo restante: {Math.floor(timeLeft/60)}:
                {(timeLeft%60).toString().padStart(2,'0')}
              </p>
            </div>
            <hr/>
            <h4>Ou usar Cupom:</h4>
            <input
              type="text"
              placeholder="Cupom"
              value={coupon}
              onChange={(e)=>setCoupon(e.target.value)}
            />
            <button onClick={handleCouponSubmit}>Validar Cupom</button>
          </>
        )}

        <button onClick={onClose} style={{marginTop:'15px'}}>
          Fechar
        </button>
      </MiniModalContent>
    </MiniModalOverlay>
  );
};

export default MensalidadePayments;

// ===================== Styled Components =====================
const MiniModalOverlay = styled.div`
  position:fixed;
  top:0; left:0;
  width:100%; height:100%;
  background:rgba(0,0,0,0.5);
  z-index:3000; 
  display:flex;
  justify-content:center;
  align-items:center;
`;
const MiniModalContent = styled.div`
  background:#fff;
  padding:20px;
  border-radius:8px;
  width:400px;
  max-height:80vh;
  overflow:auto;
`;
