import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import styled, { keyframes } from 'styled-components';
import JitsiMeeting from '../Components/VetCalls/JitsiMeeting';
import HealthEventForm from '../Components/HealthEventForm';
import VetList from '../Components/VetCalls/VetList';
import HealthEventsView from '../Components/HealthEventsView';
import VetDashCarrocel from './VetDashCarrocel';
import DraggableWindow from './Components/DraggableWindow';

// Definição global de ErrorMessage
const ErrorMessage = styled.p`
  color: red;
  padding: 10px;
`;

/* ==================================================
   1) Animação de Fundo e Container
================================================== */
const backgroundAnimation = keyframes`
  0% { background-position: center; }
  50% { background-position: top; }
  100% { background-position: center; }
`;

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* Mantendo o background 'fixed' se quiser o efeito de parallax */
  background: url('https://content.presspage.com/uploads/2497/mostcommonmedicalconditionsthatpromptveterinaryvisits.jpg?10000')
    no-repeat center center;
  background-size: cover;
  animation: ${backgroundAnimation} 20s ease-in-out infinite;
`;

/* ==================================================
   2) Menu Lateral
================================================== */
const SidePanel = styled.div`
  width: 300px;
  background-color: white;
  padding: 20px;
  border-right: 1px solid #e1e4e8;
  overflow-y: auto;
`;

const MenuTitle = styled.h3`
  margin: 0 0 10px;
`;

const MenuItem = styled.button`
  width: 100%;
  background: transparent;
  border: none;
  text-align: left;
  padding: 8px 0;
  font-size: 16px;
  cursor: pointer;
  color: #444;
  &:hover {
    color: #007bff;
  }
`;

/* ==================================================
   3) Layout Pós-Login
================================================== */
const DashboardLayout = styled.div`
  display: flex;
  /* Substituímos height por min-height para permitir expansão vertical */
  min-height: 100vh;
  background-color: #f0f2f5;
  /* Se quiser garantir que não apareça barra de rolagem horizontal, use: */
  overflow-x: hidden;
`;

const MainContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow-y: auto;
`;

/* ==================================================
   4) Outros Estilos (Seções, Formulários, etc)
================================================== */
const SectionWrapper = styled.div`
  background: rgba(255, 255, 255, 0.8);
  margin: 10px 0;
  padding: 20px;
  border-radius: 8px;
`;

const SectionTitle = styled.h2`
  margin-top: 0;
`;

/* ==================================================
   Componente Principal - VetDashboard
================================================== */
const VetDashboard = () => {
  // Estados Gerais
  const [vetId, setVetId] = useState('');
  const [clinicName, setClinicName] = useState('');
  const [error, setError] = useState('');
  const [rooms, setRooms] = useState([]);
  const [roomName, setRoomName] = useState('');
  const [pets, setPets] = useState([]);
  const [selectedPet, setSelectedPet] = useState('');
  const [healthEvents, setHealthEvents] = useState([]);
  const [password, setPassword] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [gridView, setGridView] = useState(false);

  // Estados para usuários e janelas
  const [users, setUsers] = useState({});
  const [selectedUser, setSelectedUser] = useState('');
  const [selectedSection, setSelectedSection] = useState(null);

  // Controle de janelas arrastáveis
  const [windows, setWindows] = useState({
    generateLink: false,
    atendimento: false,
    users: false,
    historico: false,
    teleconsultaConfig: false,
  });

  // Estados para teleconsulta
  const [uniqueLink, setUniqueLink] = useState('');
  const [teleconsultaPrices, setTeleconsultaPrices] = useState({
    type1: '',
    type2: '',
    type3: '',
    type4: '',
  });
  const [teleconsultaCoupons, setTeleconsultaCoupons] = useState({
    type1: '',
    type2: '',
    type3: '',
    type4: '',
  });

  const correctPassword = 'vet901';

  // Log do vetId no VetDashboard
  useEffect(() => {
    console.log('VetDashboard - vetId:', vetId);
  }, [vetId]);

  const fetchClinicInfo = useCallback(async () => {
    try {
      const response = await axios.get(`https://vidapet.tech/api/clinic_info.php?vetId=${vetId}`);
      console.log('Clinic Info response:', response.data);
      if (response.data.status === 'success') {
        setClinicName(response.data.clinicName);
      } else {
        setClinicName('');
      }
    } catch (err) {
      console.error('Erro ao buscar informações da clínica:', err);
      setError('Erro ao buscar informações da clínica.');
    }
  }, [vetId]);

  const fetchRooms = useCallback(async () => {
    try {
      const response = await axios.get(`https://vidapet.tech/api/calls/get_rooms.php?vetId=${vetId}`);
      console.log('FetchRooms - response:', response.data);
      setRooms(response.data);
    } catch (err) {
      console.error('Erro ao buscar salas:', err);
      setError('Erro ao buscar salas.');
    }
  }, [vetId]);

  const fetchPets = useCallback(async () => {
    try {
      const response = await axios.get('https://vidapet.tech/api/calls/vet_petList.php');
      console.log('FetchPets - response:', response.data);
      if (response.data.status === 'success' && Array.isArray(response.data.pets)) {
        setPets(response.data.pets);
      } else {
        setPets([]);
      }
    } catch (err) {
      console.error('Erro ao buscar pets:', err);
      setError('Erro ao buscar pets.');
    }
  }, []);

  const fetchUsersAndPets = useCallback(async () => {
    try {
      const response = await axios.get('https://vidapet.tech/api/calls/vet_PetList.php');
      console.log('FetchUsersAndPets - response:', response.data);
      if (response.data.status === 'success') {
        setUsers(response.data.usersWithPets);
      } else {
        setUsers({});
      }
    } catch (err) {
      console.error('Erro ao buscar usuários e pets:', err);
      setError('Erro ao buscar usuários e pets.');
    }
  }, []);

  const fetchHealthEvents = useCallback(async (petId) => {
    try {
      const response = await axios.get(`https://vidapet.tech/api/health-events.php?petId=${petId}`);
      console.log('FetchHealthEvents - response:', response.data);
      if (response.data.status === 'success' && Array.isArray(response.data.events)) {
        setHealthEvents(response.data.events);
      } else {
        setHealthEvents([]);
      }
    } catch (err) {
      console.error('Erro ao buscar eventos de saúde:', err);
      setError('Erro ao buscar eventos de saúde.');
    }
  }, []);

  const handleAddHealthEvent = async (eventDescription) => {
    if (selectedPet) {
      try {
        const eventType = 'Atendimento';
        const userDate = new Date().toISOString().split('T')[0];
        const formData = new FormData();
        formData.append('eventType', eventType);
        formData.append('eventDescription', eventDescription);
        formData.append('petId', selectedPet);
        formData.append('userDate', userDate);

        const response = await axios.post('https://vidapet.tech/health-events.php', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        console.log('AddHealthEvent - response:', response.data);
        fetchHealthEvents(selectedPet);
      } catch (err) {
        console.error('Erro ao adicionar evento de saúde:', err);
      }
    }
  };

  const handleGenerateLink = async () => {
    try {
      const response = await axios.post('https://vidapet.tech/api/generate_link.php', { vetId });
      console.log('GenerateLink - response:', response.data);
      setUniqueLink(response.data.link);
    } catch (err) {
      console.error('Erro ao gerar link único:', err);
      setError('Erro ao gerar link único.');
    }
  };

  const handleSaveTeleconsultaConfig = async () => {
    try {
      const response = await axios.post('https://vidapet.tech/api/save_teleconsulta_config.php', {
        vetId,
        prices: teleconsultaPrices,
      });
      console.log('SaveTeleconsultaConfig - response:', response.data);
      if (response.data.status === 'success') {
        alert('Preços salvos com sucesso!');
      } else {
        alert('Erro ao salvar os preços.');
      }
    } catch (err) {
      console.error('Erro ao salvar a configuração de teleconsulta:', err);
      setError('Erro ao salvar a configuração de teleconsulta.');
    }
  };

  const handleGenerateCoupon = async (type) => {
    try {
      const response = await axios.post('https://vidapet.tech/api/generate_coupon.php', {
        vetId,
        type,
        price: teleconsultaPrices[type],
      });
      console.log(`GenerateCoupon for ${type} - response:`, response.data);
      setTeleconsultaCoupons((prev) => ({ ...prev, [type]: response.data.coupon }));
    } catch (err) {
      console.error(`Erro ao gerar cupom para ${type}:`, err);
      setError(`Erro ao gerar cupom para ${type}.`);
    }
  };

  const handleSaveCoupon = async (type) => {
    try {
      const response = await axios.post('https://vidapet.tech/api/save_coupon.php', {
        vetId,
        type,
        coupon: teleconsultaCoupons[type],
      });
      console.log(`SaveCoupon for ${type} - response:`, response.data);
      if (response.data.status === 'success') {
        alert(`Cupom para ${type} salvo com sucesso!`);
      } else {
        alert(`Erro ao salvar o cupom para ${type}.`);
      }
    } catch (err) {
      console.error(`Erro ao salvar cupom para ${type}:`, err);
      setError(`Erro ao salvar cupom para ${type}.`);
    }
  };

  const handlePasswordSubmit = () => {
    if (password === correctPassword) {
      setIsAuthenticated(true);
    } else {
      setError('Senha incorreta.');
    }
  };

  useEffect(() => {
    if (vetId) {
      fetchClinicInfo();
      fetchRooms();
      fetchPets();
      fetchUsersAndPets();
    }
  }, [vetId, fetchClinicInfo, fetchRooms, fetchPets, fetchUsersAndPets]);

  const openWindow = (win) => {
    setWindows((prev) => ({ ...prev, [win]: true }));
  };
  const closeWindow = (win) => {
    setWindows((prev) => ({ ...prev, [win]: false }));
  };

  if (!isAuthenticated) {
    return (
      <DashboardContainer>
        <SidePanel>
          <MenuTitle>VidaPet - Login Vet</MenuTitle>
          <div style={{ padding: '20px' }}>
            <h2>Insira a senha para acessar o Painel</h2>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Senha"
              style={{ marginRight: '10px' }}
            />
            <button onClick={handlePasswordSubmit}>Entrar</button>
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </div>
        </SidePanel>
      </DashboardContainer>
    );
  }

  return (
    <DashboardContainer>
      <DashboardLayout>
        {/* Menu Lateral */}
        <SidePanel>
          <MenuTitle>Menu</MenuTitle>
          <MenuItem onClick={() => openWindow('generateLink')}>Gerar Link</MenuItem>
          <MenuItem onClick={() => openWindow('atendimento')}>Atendimento</MenuItem>
          <MenuItem onClick={() => openWindow('users')}>Usuários</MenuItem>
          <MenuItem onClick={() => openWindow('historico')}>Histórico</MenuItem>
          <MenuItem onClick={() => openWindow('teleconsultaConfig')}>Config. Teleconsulta</MenuItem>

          <div style={{ marginTop: '20px' }}>
            <label htmlFor="vetIdInput">Digite seu ID de Veterinário:</label>
            <input
              type="text"
              id="vetIdInput"
              value={vetId}
              onChange={(e) => setVetId(e.target.value)}
              placeholder="Insira seu ID"
              style={{ width: '100%', marginTop: '5px' }}
            />
            <button onClick={() => {
              console.log('Mostrar Salas - vetId:', vetId);
              fetchRooms();
            }} style={{ marginTop: '10px' }}>
              Mostrar Salas
            </button>
            {vetId && (
              <VetList
                vetId={vetId} // Passando o vetId para o VetList
                onSelectRoom={(roomName) => {
                  console.log('Sala selecionada:', roomName);
                  setRoomName(roomName);
                }}
              />
            )}
            <button onClick={() => alert('Função de criar sala chamada.')} style={{ marginTop: '10px' }}>
              Criar Nova Sala
            </button>
          </div>
        </SidePanel>

        {/* Conteúdo Principal */}
        <MainContent>
          {error && <ErrorMessage>{error}</ErrorMessage>}
          {roomName && (
            <DraggableWindow
              title="VideoChamada"
              onClose={() => setRoomName('')}
              defaultPosition={{ x: 80, y: 80, width: 600, height: 500 }}
            >
              <JitsiMeeting roomName={roomName} />
            </DraggableWindow>
          )}
        </MainContent>
      </DashboardLayout>

      {/* Janela de Atendimento */}
      {windows.atendimento && (
        <DraggableWindow
          title="Atendimento"
          onClose={() => closeWindow('atendimento')}
          defaultPosition={{ x: 120, y: 100, width: 600, height: 400 }}
        >
          <SectionWrapper>
            <SectionTitle>Novo Atendimento</SectionTitle>
            <div style={{ marginBottom: '10px' }}>
              <label>Escolha um usuário: </label>
              <select
                value={selectedUser}
                onChange={(e) => {
                  setSelectedUser(e.target.value);
                  setSelectedPet('');
                }}
                style={{ marginLeft: '5px' }}
              >
                <option value="">Selecione...</option>
                {Object.keys(users).map((email) => (
                  <option key={email} value={email}>
                    {users[email].displayName || email}
                  </option>
                ))}
              </select>
            </div>
            {selectedUser && users[selectedUser] && (
              <div style={{ marginBottom: '10px' }}>
                <label>Escolha um pet: </label>
                <select
                  value={selectedPet}
                  onChange={(e) => {
                    setSelectedPet(e.target.value);
                    fetchHealthEvents(e.target.value);
                  }}
                  style={{ marginLeft: '5px' }}
                >
                  <option value="">Selecione...</option>
                  {users[selectedUser].pets.map((pet) => (
                    <option key={pet.petId} value={pet.petId}>
                      {pet.petName || 'Nome não disponível'}
                    </option>
                  ))}
                </select>
              </div>
            )}
            {selectedPet && (
              <>
                <HealthEventsView
                  petId={selectedPet}
                  gridView={gridView}
                  reloadEvents={() => fetchHealthEvents(selectedPet)}
                />
                <HealthEventForm petId={selectedPet} onSubmit={handleAddHealthEvent} />
              </>
            )}
            {selectedPet && (
              <button onClick={() => setGridView(!gridView)} style={{ marginTop: '10px' }}>
                {gridView ? 'Visualizar como lista' : 'Visualizar como grade'}
              </button>
            )}
          </SectionWrapper>
        </DraggableWindow>
      )}

      {/* Janela de Usuários */}
      {windows.users && (
        <DraggableWindow
          title="Usuários Cadastrados"
          onClose={() => closeWindow('users')}
          defaultPosition={{ x: 200, y: 150, width: 800, height: 400 }}
        >
          <SectionWrapper>
            <SectionTitle>Usuários Cadastrados</SectionTitle>
            {Object.keys(users).length === 0 ? (
              <p>Nenhum usuário encontrado.</p>
            ) : (
              <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                  <tr style={{ backgroundColor: '#f0f0f0' }}>
                    <th style={{ border: '1px solid #ccc', padding: '8px' }}>Nome</th>
                    <th style={{ border: '1px solid #ccc', padding: '8px' }}>Email</th>
                    <th style={{ border: '1px solid #ccc', padding: '8px' }}>Pets</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(users).map(([email, user]) => (
                    <tr key={email}>
                      <td style={{ border: '1px solid #ccc', padding: '8px' }}>
                        {user.displayName || 'Nome não disponível'}
                      </td>
                      <td style={{ border: '1px solid #ccc', padding: '8px' }}>{email}</td>
                      <td style={{ border: '1px solid #ccc', padding: '8px' }}>
                        {user.pets && user.pets.length > 0 ? (
                          <ul>
                            {user.pets.map((pet) => (
                              <li key={pet.petId}>
                                {pet.petName} - {pet.breed || 'Sem raça'}
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <p>Sem pets cadastrados.</p>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </SectionWrapper>
        </DraggableWindow>
      )}

      {/* Janela de Histórico */}
      {windows.historico && (
        <DraggableWindow
          title="Histórico de Atendimentos"
          onClose={() => closeWindow('historico')}
          defaultPosition={{ x: 150, y: 120, width: 700, height: 400 }}
        >
          <SectionWrapper>
            <SectionTitle>Histórico de Atendimentos</SectionTitle>
            <p>Selecione um pet para ver os eventos:</p>
            <div style={{ marginBottom: '10px' }}>
              <label>Escolha um usuário: </label>
              <select
                value={selectedUser}
                onChange={(e) => {
                  setSelectedUser(e.target.value);
                  setSelectedPet('');
                }}
                style={{ marginLeft: '5px' }}
              >
                <option value="">Selecione...</option>
                {Object.keys(users).map((email) => (
                  <option key={email} value={email}>
                    {users[email].displayName || email}
                  </option>
                ))}
              </select>
            </div>
            {selectedUser && users[selectedUser] && (
              <div style={{ marginBottom: '10px' }}>
                <label>Escolha um pet: </label>
                <select
                  value={selectedPet}
                  onChange={(e) => {
                    setSelectedPet(e.target.value);
                    fetchHealthEvents(e.target.value);
                  }}
                  style={{ marginLeft: '5px' }}
                >
                  <option value="">Selecione...</option>
                  {users[selectedUser].pets.map((pet) => (
                    <option key={pet.petId} value={pet.petId}>
                      {pet.petName || 'Nome não disponível'}
                    </option>
                  ))}
                </select>
              </div>
            )}
            {selectedPet && (
              <>
                <HealthEventsView
                  petId={selectedPet}
                  gridView={gridView}
                  reloadEvents={() => fetchHealthEvents(selectedPet)}
                />
                <HealthEventForm petId={selectedPet} onSubmit={handleAddHealthEvent} />
              </>
            )}
            {selectedPet && (
              <button onClick={() => setGridView(!gridView)} style={{ marginTop: '10px' }}>
                {gridView ? 'Visualizar como lista' : 'Visualizar como grade'}
              </button>
            )}
          </SectionWrapper>
        </DraggableWindow>
      )}

      {/* Janela de Config. Teleconsulta */}
      {windows.teleconsultaConfig && (
        <DraggableWindow
          title="Config. Teleconsulta"
          onClose={() => closeWindow('teleconsultaConfig')}
          defaultPosition={{ x: 220, y: 200, width: 500, height: 300 }}
        >
          <SectionWrapper>
            <SectionTitle>Configuração de Teleconsulta</SectionTitle>
            <p>Informe os preços para cada tipo de teleconsulta e gere cupons para descontos:</p>
            {['type1', 'type2', 'type3', 'type4'].map((type) => (
              <div key={type} style={{ marginBottom: '10px' }}>
                <label style={{ marginRight: '5px' }}>Preço {type}:</label>
                <input
                  type="number"
                  value={teleconsultaPrices[type]}
                  onChange={(e) =>
                    setTeleconsultaPrices((prev) => ({ ...prev, [type]: e.target.value }))
                  }
                  placeholder="Valor"
                  style={{ marginRight: '5px', width: '80px' }}
                />
                <button onClick={() => handleGenerateCoupon(type)}>Gerar Cupom</button>
                {teleconsultaCoupons[type] && (
                  <div style={{ marginTop: '5px' }}>
                    <strong>Cupom:</strong> {teleconsultaCoupons[type]}
                    <button onClick={() => handleSaveCoupon(type)} style={{ marginLeft: '5px' }}>
                      Salvar Cupom
                    </button>
                  </div>
                )}
              </div>
            ))}
            <button onClick={handleSaveTeleconsultaConfig} style={{ marginTop: '10px' }}>
              Salvar Preços
            </button>
          </SectionWrapper>
        </DraggableWindow>
      )}

      {/* Janela de Gerar Link */}
      {windows.generateLink && (
        <DraggableWindow
          title="Gerar Link Único"
          onClose={() => closeWindow('generateLink')}
          defaultPosition={{ x: 300, y: 220, width: 400, height: 200 }}
        >
          <SectionWrapper>
            <SectionTitle>Gerar Link Único</SectionTitle>
            <div style={{ marginBottom: '10px' }}>
              <label htmlFor="vetIdInput" style={{ marginRight: '5px' }}>
                Seu ID de Veterinário:
              </label>
              <input
                type="text"
                id="vetIdInput"
                value={vetId}
                onChange={(e) => setVetId(e.target.value)}
                placeholder="Insira seu ID"
                style={{ marginRight: '10px', width: '100%' }}
              />
              <button onClick={handleGenerateLink}>Gerar Link</button>
            </div>
            {uniqueLink && (
              <div>
                <p>Envie este link para seu cliente:</p>
                <input
                  type="text"
                  readOnly
                  value={uniqueLink}
                  onClick={(e) => e.target.select()}
                  style={{ width: '100%' }}
                />
              </div>
            )}
          </SectionWrapper>
        </DraggableWindow>
      )}
    </DashboardContainer>
  );
};

export default VetDashboard;
