import React, { useState } from "react";
import { Rnd } from "react-rnd";
import styled from "styled-components";
import { FaWindowMinimize, FaWindowMaximize, FaTimes } from "react-icons/fa";

const WindowContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  height: 100%;
`;

const TitleBar = styled.div`
  background: #007bff;
  color: #fff;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: move;
`;

const Title = styled.span`
  font-weight: bold;
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 5px;
`;

const WindowButton = styled.button`
  background: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

const ContentArea = styled.div`
  flex: 1;
  overflow: auto;
  padding: 10px;
`;

function DraggableWindow({
  title,
  children,
  onClose,
  defaultPosition = { x: 100, y: 100, width: 400, height: 300 },
}) {
  const [isMinimized, setIsMinimized] = useState(false);
  const [isMaximized, setIsMaximized] = useState(false);
  const [prevSize, setPrevSize] = useState(defaultPosition);

  const handleMinimize = () => {
    setIsMinimized(!isMinimized);
  };

  const handleMaximize = (ref, position) => {
    if (!isMaximized) {
      // Salva o tamanho/posição anterior
      setPrevSize({
        x: position.x,
        y: position.y,
        width: ref.style.width,
        height: ref.style.height,
      });
      setIsMaximized(true);
    } else {
      // Restaura o tamanho/posição anterior
      setIsMaximized(false);
    }
  };

  return (
    <Rnd
      default={defaultPosition}
      bounds="window"
      disableDragging={isMinimized || isMaximized}
      enableResizing={!isMinimized && !isMaximized}
      style={{ zIndex: 9999 }}
      onResizeStop={(e, direction, ref, delta, position) => {
        if (isMaximized) {
          setIsMaximized(false);
        }
      }}
    >
      <WindowContainer
        style={
          isMaximized
            ? {
                position: "absolute",
                top: 0,
                left: 0,
                width: "100vw",
                height: "100vh",
                margin: 0,
                borderRadius: 0,
              }
            : {}
        }
      >
        <TitleBar
          style={{
            cursor: isMinimized || isMaximized ? "default" : "move",
          }}
        >
          <Title>{title}</Title>
          <ButtonsContainer>
            <WindowButton onClick={handleMinimize}>
              <FaWindowMinimize />
            </WindowButton>
            <WindowButton
              onClick={(e) =>
                handleMaximize(
                  e.currentTarget.parentElement.parentElement.parentElement,
                  { x: 0, y: 0 }
                )
              }
            >
              <FaWindowMaximize />
            </WindowButton>
            <WindowButton onClick={onClose}>
              <FaTimes />
            </WindowButton>
          </ButtonsContainer>
        </TitleBar>
        {!isMinimized && <ContentArea>{children}</ContentArea>}
      </WindowContainer>
    </Rnd>
  );
}

export default DraggableWindow;
