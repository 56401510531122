import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

// Animação para a transição dos cards
const slideAnimation = keyframes`
  0% { opacity: 0; transform: translateX(-10%); }
  10% { opacity: 1; transform: translateX(0); }
  90% { opacity: 1; transform: translateX(0); }
  100% { opacity: 0; transform: translateX(10%); }
`;

// Container do carrossel
const CarouselWrapper = styled.div`
  width: 100%;
  padding: 20px;
  text-align: center;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.85);
`;

// Card para exibir cada anúncio
const Card = styled.div`
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  margin: 0 auto;
  font-size: 1.2rem;
  max-width: 800px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  animation: ${slideAnimation} 5s ease-in-out infinite;
`;

const VetDashCarrocel = ({ slides, duration = 5000 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex(prev => (prev + 1) % slides.length);
    }, duration);
    return () => clearInterval(interval);
  }, [slides, duration]);

  return (
    <CarouselWrapper>
      <Card key={currentIndex}>
        {slides[currentIndex]}
      </Card>
    </CarouselWrapper>
  );
};

export default VetDashCarrocel;
