import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import './VetCall.css';

function VetList({ onSelectRoom, vetId }) {
  const [rooms, setRooms] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    console.log('VetList - vetId recebido:', vetId);
  }, [vetId]);

  const fetchRooms = useCallback(async () => {
    try {
      const response = await axios.get('https://vidapet.tech/api/calls/get_open_rooms.php');
      console.log('VetList - Resposta da API (todas salas):', response.data);

      let filteredRooms;
      if (vetId) {
        const numericVetId = parseInt(vetId, 10);
        console.log('VetList - numericVetId:', numericVetId);
        filteredRooms = Array.isArray(response.data)
          ? response.data.filter(room => parseInt(room.vet_id, 10) === numericVetId)
          : [];
      } else {
        // Se nenhum vetId for passado, exibe todas as salas.
        filteredRooms = Array.isArray(response.data) ? response.data : [];
      }
      console.log('VetList - Salas filtradas:', filteredRooms);
      setRooms(filteredRooms);
    } catch (error) {
      console.error('Erro ao buscar salas:', error);
      setError(error.message);
    }
  }, [vetId]);

  useEffect(() => {
    fetchRooms();
  }, [fetchRooms]);

  const handleRoomClick = (room) => {
    console.log('VetList - Sala clicada:', room);
    if (onSelectRoom) {
      onSelectRoom(room.room_name);
    }
  };

  if (error) {
    return <div>Erro ao carregar salas: {error}</div>;
  }

  return (
    <div className="vet-list">
      <h1>Salas Pendentes</h1>
      <ul>
        {Array.isArray(rooms) && rooms.length > 0 ? (
          rooms.map((room) => (
            <li key={room.room_name}>
              Sala: {room.room_name} - Veterinário: {room.vet_id}
              <button onClick={() => handleRoomClick(room)}>Selecionar</button>
            </li>
          ))
        ) : (
          <li>Nenhuma sala pendente no momento.</li>
        )}
      </ul>
    </div>
  );
}

export default VetList;
