import React, { useState, useEffect } from 'react';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { useInView } from 'react-intersection-observer';
import './ProgramaDoacaoFundoResgate.css';

ChartJS.register(ArcElement, Tooltip, Legend);

const ProgramaDoacaoFundoResgate = ({ onClose }) => {
  const [chartData, setChartData] = useState([10, 20, 30, 40]);
  const [ref, inView] = useInView({ triggerOnce: true });

  // Simulação
  const [remainingFunds, setRemainingFunds] = useState(20000);
  const [accUsage, setAccUsage] = useState([0, 0, 0, 0]); // ONGs, Clínicas, Adoções, Admin
  const [eventList, setEventList] = useState([]);
  const [simulationRunning, setSimulationRunning] = useState(false);
  const [scenarioIndex, setScenarioIndex] = useState(0);
  const [stepIndex, setStepIndex] = useState(0);

  const scenarios = [
    {
      petName: 'Cachorrinho',
      steps: [
        { text: 'Alerta no mapa: um cachorrinho foi visto na rua', cost: [0, 0, 0, 0] },
        { text: 'ONG resgata o pet e recebe R$190,00', cost: [0, 190, 0, 0] },
        { text: 'Clínica realiza cuidados e sanitização (R$350,00)', cost: [0, 0, 350, 0] },
        { text: 'Família adota e recebe R$180,00 de incentivo', cost: [0, 0, 0, 180] },
      ],
    },
    {
      petName: 'Gatinho',
      steps: [
        { text: 'Alerta no mapa: um gatinho foi avistado abandonado', cost: [0, 0, 0, 0] },
        { text: 'ONG resgata e recebe R$190,00', cost: [0, 190, 0, 0] },
        { text: 'Clínica realiza cuidados (R$350,00)', cost: [0, 0, 350, 0] },
        { text: 'Família adota e recebe R$180,00 de incentivo', cost: [0, 0, 0, 180] },
      ],
    },
  ];

  const startSimulation = () => {
    setRemainingFunds(20000);
    setAccUsage([0, 0, 0, 0]);
    setEventList([]);
    setScenarioIndex(0);
    setStepIndex(0);
    setSimulationRunning(true);
  };

  // Simulação automática
  useEffect(() => {
    let intervalId = null;
    if (simulationRunning) {
      intervalId = setInterval(() => {
        setStepIndex((oldStep) => {
          const subSteps = scenarios[scenarioIndex].steps;
          if (oldStep < subSteps.length) {
            const currentStep = subSteps[oldStep];
            const costArr = currentStep.cost;
            const totalCost = costArr.reduce((a, b) => a + b, 0);

            if (remainingFunds < totalCost) {
              setEventList((prev) => [...prev, 'Fundo insuficiente.']);
              setSimulationRunning(false);
              return oldStep;
            }

            setRemainingFunds((prev) => prev - totalCost);
            setAccUsage((prevUsage) =>
              prevUsage.map((val, i) => val + costArr[i])
            );
            setEventList((prev) => [...prev, currentStep.text]);

            const nextStep = oldStep + 1;
            if (nextStep >= subSteps.length) {
              const nextScenario = scenarioIndex + 1;
              if (nextScenario >= scenarios.length) {
                setEventList((prev) => [...prev, 'Simulação concluída!']);
                setSimulationRunning(false);
              } else {
                setScenarioIndex(nextScenario);
                return 0;
              }
            }
            return nextStep;
          }
          return oldStep;
        });
      }, 3000);
    }
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [simulationRunning, scenarioIndex, remainingFunds, scenarios]);

  // Animação do gráfico estático
  useEffect(() => {
    if (inView) {
      setTimeout(() => {
        setChartData([25, 30, 25, 20]);
      }, 500);
    }
  }, [inView]);

  const dataFundo = {
    labels: ['ONGs Resgatadoras', 'Clínicas Veterinárias', 'Adoções', 'Administração'],
    datasets: [
      {
        label: 'Distribuição do Fundo Dinâmico',
        data: chartData,
        backgroundColor: ['#ff6600', '#ffcc00', '#33cc33', '#6666ff'],
        borderWidth: 1,
      },
    ],
  };

  const dataSimulacao = {
    labels: ['ONG', 'Clínica', 'Adoção', 'Admin'],
    datasets: [
      {
        label: 'Uso do Fundo na Simulação',
        data: accUsage,
        backgroundColor: ['#ff6600', '#ffcc00', '#33cc33', '#6666ff'],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="fdr-container">
      {/* Hero */}
      <body className="fdr-hero">
        <h1>Programa de Doação - Fundo Dinâmico de Resgate</h1>
        <p>
          Sua doação transforma vidas! Cada centavo é direcionado para resgatar, cuidar e
          promover a adoção de pets abandonados.
        </p>
        <button className="fdr-btn" onClick={startSimulation}>
          Iniciar Simulação
        </button>
      </body>

      {/* Storytelling */}
      <section className="fdr-story">
        <h2>O Caminho da Doação</h2>
        <div className="fdr-step">
          <h3>1. Identificação</h3>
          <p>
            Você sai de casa e avista um pet perdido. Com o VidaPet.Tech, você registra o
            ocorrido enviando uma foto e localização.
          </p>
          <img src="/imagens/resgate1.jpg" alt="Pet identificado" />
        </div>
        <div className="fdr-step">
          <h3>2. Alerta e Resgate</h3>
          <p>
            A imagem gera um alerta no mapa interativo. ONGs e agentes resgatadores recebem o
            aviso e decidem intervir.
          </p>
          <img src="/imagens/alerta.jpg" alt="Alerta no mapa" />
        </div>
        <div className="fdr-step">
          <h3>3. Encaminhamento à Clínica</h3>
          <p>
            O pet é resgatado e encaminhado para uma clínica conveniada, onde recebe os
            primeiros cuidados.
          </p>
          <img src="/imagens/resgate-clinica.jpg" alt="Resgate e encaminhamento" />
        </div>
        <div className="fdr-step">
          <h3>4. Cuidados e Formalização</h3>
          <p>
            Na clínica, o pet é avaliado, sanitizado e preparado para a adoção. A ONG e a
            clínica recebem uma compensação pelo serviço.
          </p>
          <img src="/imagens/formalizacao.jpg" alt="Cuidados e formalização" />
        </div>
        <div className="fdr-step">
          <h3>5. Adoção e Incentivo</h3>
          <p>
            Finalmente, o pet é disponibilizado para adoção. A família adotante e o local
            de hospedagem também são beneficiados.
          </p>
          <img src="/imagens/adocao.jpg" alt="Adoção e incentivo" />
        </div>
      </section>

      {/* Transparência */}
      <section className="fdr-report">
        <h2>Transparência Total</h2>
        <p>
          Todo o valor do Fundo Dinâmico de Resgate é utilizado para financiar cada etapa do
          resgate: desde a identificação até a adoção. Relatórios dinâmicos permitem que empresas
          e doadores acompanhem exatamente como seus recursos são aplicados.
        </p>
      </section>

      {/* Gráficos e Simulação */}
      <section className="fdr-simulation" ref={ref}>
        <h2>Impacto do Fundo</h2>
        <div className="fdr-charts-container">
          <div className="fdr-chart-box">
            <h3>Distribuição do Fundo</h3>
            <Pie data={dataFundo} options={{ plugins: { legend: { position: 'bottom' } }, responsive: true }} />
          </div>
          <div className="fdr-chart-box">
            <h3>Simulação do Uso</h3>
            <Pie data={dataSimulacao} options={{ plugins: { legend: { position: 'bottom' } }, responsive: true }} />
          </div>
        </div>
        <div className="fdr-siminfo">
          <p>
            <strong>Fundo Restante:</strong> R${' '}
            {remainingFunds.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
          </p>
          <p>
            <strong>Cenário Atual:</strong> {scenarioIndex + 1} de {scenarios.length}
          </p>
          <div className="fdr-eventlist">
            <h3>Eventos</h3>
            {eventList.map((evt, idx) => (
              <p key={idx}>{evt}</p>
            ))}
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="fdr-footer">
        <p>Contribua para salvar vidas. Sua doação faz a diferença!</p>
      </footer>
    </div>
  );
};

export default ProgramaDoacaoFundoResgate;
