import React, { useState, useEffect } from "react";
import axios from "axios";

// Ícones do react-icons
import { FaQrcode, FaUnlink, FaCamera, FaKeyboard, FaTimes } from "react-icons/fa";

import "./TQRCode_Association.css"; // Ajuste os estilos conforme necessário

const TQRCode_Association = ({ initialCodigo, petId, tutorId, onAssociationSuccess }) => {
  // Inicializa com o código da tag ou string vazia (NÃO usa petId como fallback)
  const [associatedCode, setAssociatedCode] = useState(initialCodigo || "");
  const [tagAssociada, setTagAssociada] = useState(!!initialCodigo);
  const [showModal, setShowModal] = useState(false);
  const [manualCode, setManualCode] = useState("");
  const [showManualInput, setShowManualInput] = useState(false);

  // Endpoints da API
  // Esse endpoint trabalha com as ações "acessar", "associar" e "desassociar"
  const qrApiLink = "https://vidapet.tech/api/db/vidapettech/serv_Apis/tagqrcode/tqrcode.php";
  // Esse endpoint consulta a tag associada usando pet_id
  const getTagByPetIdUrl = "https://vidapet.tech/api/db/vidapettech/serv_Apis/tagqrcode/getTagByPetId.php";

  // Consulta se há uma tag associada para o pet, usando petId
  useEffect(() => {
    axios
      .post(getTagByPetIdUrl, { pet_id: petId })
      .then((res) => {
        if (res.data.status === "success" && res.data.tag && res.data.tag.codigo) {
          console.log("Tag encontrada:", res.data.tag.codigo);
          setAssociatedCode(res.data.tag.codigo);
          setTagAssociada(true);
          onAssociationSuccess(res.data.tag.codigo);
        } else {
          console.log("Nenhuma tag associada para este pet.");
          setAssociatedCode("");
          setTagAssociada(false);
        }
      })
      .catch((err) => {
        console.error("Erro ao consultar tag pelo pet id:", err);
      });
  }, [petId, onAssociationSuccess]);

  // Se houver um initialCodigo (não vazio), tenta acessar a tag via a ação "acessar"
  useEffect(() => {
    if (!initialCodigo) return; // Não executa se estiver vazio
    axios
      .post(qrApiLink, {
        acao: "acessar",
        codigo: initialCodigo
      })
      .then((res) => {
        console.log("Consulta 'acessar' retornou:", res.data);
        if (res.data.status === "success" && res.data.tag && res.data.tag.codigo) {
          setAssociatedCode(res.data.tag.codigo);
          setTagAssociada(true);
          onAssociationSuccess(res.data.tag.codigo);
        } else {
          setAssociatedCode("");
          setTagAssociada(false);
        }
      })
      .catch((err) => {
        console.error("Erro ao acessar tag:", err);
      });
  }, [initialCodigo, qrApiLink, onAssociationSuccess]);

  // Função para realizar a associação via API
  const handleAssociation = (newCode) => {
    if (tagAssociada && newCode !== associatedCode) {
      const confirmReplace = window.confirm(
        `Já existe uma tag associada (${associatedCode}). Deseja substituir por ${newCode}?`
      );
      if (!confirmReplace) {
        console.log("Associação cancelada pelo usuário.");
        return;
      }
    }
    console.log("HandleAssociation chamado com código:", newCode);
    console.log("Dados enviados para a API:", {
      acao: "associar",
      codigo: newCode,
      pet_id: petId,
      tutor_id: tutorId,
    });
    axios
      .post(qrApiLink, {
        acao: "associar",
        codigo: newCode,
        pet_id: petId,
        tutor_id: tutorId,
      })
      .then((res) => {
        console.log("Resposta da API:", res.data);
        if (res.data.status === "success") {
          alert("Tag associada com sucesso.");
          setAssociatedCode(newCode);
          setTagAssociada(true);
          onAssociationSuccess(newCode);
        } else {
          alert("Falha ao associar a tag: " + res.data.message);
        }
      })
      .catch((err) => {
        console.error("Erro ao associar tag:", err);
        alert("Erro ao associar a tag.");
      });
  };

  // Função para realizar a dessassociação via API
  const handleDisassociation = () => {
    const confirmDisassociate = window.confirm(
      `Deseja realmente dessassociar a tag (${associatedCode})?`
    );
    if (!confirmDisassociate) {
      console.log("Dessassociação cancelada pelo usuário.");
      return;
    }
    console.log("HandleDisassociation chamado para código:", associatedCode);
    console.log("Dados enviados para a API:", {
      acao: "desassociar",
      codigo: associatedCode,
    });
    axios
      .post(qrApiLink, {
        acao: "desassociar",
        codigo: associatedCode,
      })
      .then((res) => {
        console.log("Resposta da API (dessassociar):", res.data);
        if (res.data.status === "success") {
          alert("Tag dessassociada com sucesso.");
          setAssociatedCode("");
          setTagAssociada(false);
          onAssociationSuccess("");
        } else {
          alert("Falha ao dessassociar a tag: " + res.data.message);
        }
      })
      .catch((err) => {
        console.error("Erro ao dessassociar tag:", err);
        alert("Erro ao dessassociar a tag.");
      });
  };

  // Opção de leitura simulada (via prompt)
  const handleReadOption = () => {
    const scannedCode = prompt("Simule a leitura do QR Code. Insira o código lido:");
    console.log("Código lido (via prompt):", scannedCode);
    if (scannedCode && scannedCode.trim() !== "") {
      handleAssociation(scannedCode.trim());
    }
    closeModal();
  };

  // Confirmação da inserção manual
  const handleManualSubmit = () => {
    console.log("Código inserido manualmente:", manualCode);
    if (manualCode.trim() !== "") {
      handleAssociation(manualCode.trim());
    } else {
      alert("Por favor, insira um código válido.");
    }
    closeModal();
  };

  // Função para fechar o modal e resetar estados
  const closeModal = () => {
    setShowModal(false);
    setShowManualInput(false);
    setManualCode("");
  };

  return (
    <div className="tqrcode-association">
      {/* Botão principal (associar ou substituir) */}
      <button className="association-button" onClick={() => setShowModal(true)}>
        {tagAssociada ? (
          <>
            <FaQrcode style={{ marginRight: 4 }} />
            Substituir Tag QR Code
          </>
        ) : (
          <>
            <FaQrcode style={{ marginRight: 4 }} />
            Associar Tag QR Code
          </>
        )}
      </button>

      {/* Se já tiver uma tag associada, exibe o texto e o botão de desassociar */}
      {tagAssociada && associatedCode && (
        <div className="tag-associated">
          <p>Tag QR Code já associada: <strong>{associatedCode}</strong></p>
          <button className="disassociate-button" onClick={handleDisassociation}>
            <FaUnlink style={{ marginRight: 4 }} />
            Dessassociar Tag QR Code
          </button>
        </div>
      )}

      {/* Modal para escolher se lê ou insere manualmente */}
      {showModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>Associar Tag QR Code</h3>

            {!showManualInput ? (
              <div className="options">
                {/* Botão para "ler" (simulado via prompt) */}
                <button onClick={handleReadOption}>
                  <FaCamera style={{ marginRight: 4 }} />
                  Ler Tag QR Code
                </button>

                {/* Botão para inserir manualmente */}
                <button onClick={() => setShowManualInput(true)}>
                  <FaKeyboard style={{ marginRight: 4 }} />
                  Inserir Código Manualmente
                </button>

                {/* Cancelar modal */}
                <button onClick={closeModal}>
                  <FaTimes style={{ marginRight: 4 }} />
                  Cancelar
                </button>
              </div>
            ) : (
              <div className="manual-input">
                <input
                  type="text"
                  value={manualCode}
                  onChange={(e) => setManualCode(e.target.value)}
                  placeholder="Insira o código da tag"
                />
                <button onClick={handleManualSubmit}>
                  <FaQrcode style={{ marginRight: 4 }} />
                  Confirmar
                </button>
                <button onClick={closeModal}>
                  <FaTimes style={{ marginRight: 4 }} />
                  Cancelar
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default TQRCode_Association;
