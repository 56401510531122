import React, { useState } from 'react';
import './SignUp.css'; 
import { auth, provider } from '../../../firebase';
import axios from 'axios'; 
import { useNavigate } from 'react-router-dom';
import logo from '../../../imagens/logoOficial2024.png';
import { FcGoogle } from "react-icons/fc";

const SignUp = () => {
  const [error, setError] = useState('');
  const navigate = useNavigate();

  // Função de login com Google
  const handleGoogleLogin = async () => {
    try {
      const result = await auth.signInWithPopup(provider);
      const user = result.user;
      const profileInfo = {
        firebaseId: user.uid,
        email: user.email,
        displayName: user.displayName,
        photoURL: user.photoURL,
      };

      // Sincroniza os dados do usuário com o backend
      const backendResponse = await axios.post('https://vidapet.tech/sync-user.php', profileInfo);
      if (backendResponse.data.status === 'success') {
        // Se o login for bem-sucedido, redireciona para o dashboard
        navigate('/dashboard');
      } else {
        setError('Erro ao sincronizar com o backend: ' + backendResponse.data.message);
      }
    } catch (error) {
      setError('Erro no login com Google: ' + error.message);
    }
  };

  return (
    <div className="signup-container">
      <div className="signup-card">
        <div className="signup-logo">
          <a href="/home">
            <img src={logo} alt="VidaPet.Tech Logo" />
          </a>
        </div>
        <h1>Inscrever-se</h1>
        <p>Registre-se com sua conta Google para continuar</p>

        <div className='social-icons'>
          <button className='buttonGoogle' onClick={handleGoogleLogin}>
            <FcGoogle size={25} /> Registre-se com o Google
          </button>
        </div>

        {error && <p className="signup-error">{error}</p>}
      </div>
    </div>
  );
};

export default SignUp;
