import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Telemedicine.css';
import heroImage from '../../imagens/TeleVet001.webp';
import secondaryImage from '../../imagens/TeleVet002.webp';

const Telemedicine = () => {
  const navigate = useNavigate();

  // Redireciona para o Dashboard
  const redirectToDashboard = () => {
    window.location.href = '/dashboard';
  };

  // Redireciona para o componente TelemedicineVet
  const redirectToTelemedicineVet = () => {
    navigate('/TelemedicineVet');
  };

  return (
    <div className="telemedicine-container">
      {/* ===================== HERO SECTION ===================== */}
      <section className="hero-section-grid">
        <div className="hero-content">
          <h1>Teleconsulta Veterinária VidaPet.Tech</h1>
          <p>Cuidado veterinário no conforto da sua casa!</p>

          <div className="hero-features">
            <div className="feature">
              <i className="icon-video"></i>
              <span>Consultas Online</span>
            </div>
            <div className="feature">
              <i className="icon-clock"></i>
              <span>Atendimento 24/7</span>
            </div>
            <div className="feature">
              <i className="icon-shield"></i>
              <span>Segurança Garantida</span>
            </div>
          </div>

          <img
            src={heroImage}
            alt="Teleconsulta Veterinária"
            className="hero-image"
          />

          {/* ====== AQUI: Botão para USUÁRIOS em destaque (inversão) ====== */}
          <div className="cta-buttons">
            <button
              className="whatsapp-button"
              onClick={() => {
                // Exemplo: poderia levar o usuário a um registro,
                // ou abrir um link. Ajuste conforme necessário.
                navigate('/dashboard'); 
              }}
            >
              Sou Usuário e Quero me Consultar com um Veterinário Online
            </button>
          </div>
        </div>

        {/* ===================== SIDEBAR AGORA PARA VETERINÁRIOS ===================== */}
        <div className="vet-sidebar">
          <h2>Sou Veterinário/Clínica e Quero Atender</h2>
          <p>
            Para expandir a sua área de atuação com a Teleconsulta
            Veterinária VidaPet.Tech, cadastre-se como veterinário
            ou clínica parceira e acesse o painel para gerenciar
            seus atendimentos. 
          </p>

          <button className="dashboard-button" onClick={redirectToTelemedicineVet}>
            Acessar o Painel de Veterinário
          </button>

          <img
            src={secondaryImage}
            alt="Consultas Veterinárias Online"
            className="secondary-image"
          />
        </div>
      </section>

      {/* ===================== BENEFITS SECTION ===================== */}
      <section className="benefits-section">
        <h2>Benefícios</h2>
        <div className="benefits-grid">
          <div className="benefit-card">
            <i className="icon-lightbulb"></i>
            <h3>Comodidade</h3>
            <p>Realize consultas veterinárias no conforto da sua casa.</p>
          </div>
          <div className="benefit-card">
            <i className="icon-heart"></i>
            <h3>Saúde do Pet</h3>
            <p>Receba conselhos médicos para a saúde do seu pet a qualquer momento.</p>
          </div>
          <div className="benefit-card">
            <i className="icon-globe"></i>
            <h3>Atendimento Global</h3>
            <p>Conecte-se com veterinários de qualquer lugar do mundo.</p>
          </div>
        </div>
      </section>

      {/* ===================== INSTRUCTIONS SECTION ===================== */}
      <section className="instructions-section">
        <h2>Instruções de Acesso</h2>
        <p>
          Se você ainda não tem uma conta, crie uma e adicione seu veterinário para acessar
          os serviços de teleconsulta. Simples e rápido!
        </p>
      </section>

      {/* ===================== TESTIMONIAL SECTION ===================== */}
      <section className="testimonial-section">
        <h2>O que dizem nossos usuários</h2>
        <blockquote>
          "A VidaPet.Tech tornou a consulta veterinária mais fácil e acessível. Agora posso cuidar da saúde do meu pet sem sair de casa!"
        </blockquote>
      </section>

      <footer>
        <p>VidaPet.Tech - Todos os direitos reservados.</p>
      </footer>
    </div>
  );
};

export default Telemedicine;
