import React, { useState } from 'react';
import styled from 'styled-components';
import { IoMdCloseCircle } from 'react-icons/io';
import axios from 'axios';
import petIcon from '../../../../imagens/MascoteLiliVidaPetTech.png'; // Ícone padrão

import ParcelasListModal from './ParcelasListModal';
import MensalidadePayments from './MensalidadePayments';

const ConveniosInfo = ({ convenio, onClose }) => {
  // Modal para pagar a “Próxima Mensalidade”
  const [showMensalidadeModal, setShowMensalidadeModal] = useState(false);
  const handlePagarMensalidade = () => setShowMensalidadeModal(true);
  const handleCloseMensalidadeModal = () => setShowMensalidadeModal(false);

  // Modal para listar parcelas e pagar cada uma
  const [showParcelasModal, setShowParcelasModal] = useState(false);
  const handleVerParcelas = () => setShowParcelasModal(true);
  const handleCloseParcelasModal = () => setShowParcelasModal(false);

  return (
    <ModalBackground>
      <ModalContent>
        {/* Cabeçalho */}
        <ModalHeader>
          <h2>Detalhes do Convênio</h2>
          <CloseButton onClick={onClose}>
            <IoMdCloseCircle size={28} />
          </CloseButton>
        </ModalHeader>

        {/* Corpo */}
        <ModalBody>
          <InfoCard>
            {/* Pet */}
            <PetDetails>
              <PetImage 
                src={
                  convenio.pet_imagem 
                    ? `https://vidapet.tech/vidapet/pets/${convenio.pet_imagem}?t=${new Date().getTime()}`
                    : petIcon
                }
                alt={convenio.pet_nome || 'Pet'}
              />
              <p>
                <strong>Pet Conveniado:</strong> {convenio.pet_nome || 'Não informado'}
              </p>
            </PetDetails>

            {/* Dados do convênio */}
            <p><strong>Convênio:</strong> {convenio.convenio_nome}</p>
            <p><strong>Status:</strong> {convenio.status}</p>
            <p>
              <strong>Data de Pagamento:</strong>{' '}
              {convenio.data_ultimo_pagamento
                ? new Date(convenio.data_ultimo_pagamento).toLocaleDateString()
                : '-'}
            </p>
            <p>
              <strong>Próxima Mensalidade:</strong>{' '}
              {convenio.data_proximo_vencimento
                ? new Date(convenio.data_proximo_vencimento).toLocaleDateString()
                : '-'}
            </p>

            {/* Documentos */}
            {convenio.documentos && convenio.documentos.length > 0 && (
              <DocumentSection>
                <h3>Documentação:</h3>
                <ul>
                  {convenio.documentos.map((doc, index) => (
                    <li key={index}>
                      <a href={doc.url} target="_blank" rel="noopener noreferrer">
                        {doc.nome}
                      </a>
                    </li>
                  ))}
                </ul>
              </DocumentSection>
            )}
          </InfoCard>

          {/* Botão Ver Parcelas */}
          <ButtonRow>
            <PayButton
              style={{ backgroundColor: '#17a2b8', marginRight: '10px' }}
              onClick={handleVerParcelas}
            >
              Ver Parcelas
            </PayButton>
            {/* Botão Fechar */}
            <CloseModalButton onClick={onClose}>Fechar</CloseModalButton>
          </ButtonRow>
        </ModalBody>

        {/* Modal: Pagar Próxima Mensalidade */}
        {showMensalidadeModal && (
          <MensalidadePayments
            convenio={convenio}
            parcela={null} // Indica que é “próxima mensalidade”
            onClose={handleCloseMensalidadeModal}
          />
        )}

        {/* Modal: Lista de Parcelas */}
        {showParcelasModal && (
          <ParcelasListModal
            convenio={convenio}
            onClose={handleCloseParcelasModal}
          />
        )}
      </ModalContent>
    </ModalBackground>
  );
};

export default ConveniosInfo;

// ===================== Styled Components =====================
const ModalBackground = styled.div`
  position: fixed;
  top: 0; left: 0;
  width: 100%; height: 100%;
  background: rgba(0,0,0,0.5);
  display: flex; 
  justify-content: center; 
  align-items: center;
  z-index: 2000;
`;

const ModalContent = styled.div`
  background: #fff;
  border-radius: 16px;
  width: 500px;
  max-width: 90%;
  max-height: 80vh;
  padding: 20px;
  position: relative;
  overflow-y: auto;

  @media (max-width: 600px) {
    padding: 15px;
  }
`;

const ModalHeader = styled.div`
  display: flex; 
  justify-content: space-between; 
  align-items: center;
  margin-bottom: 20px;

  h2 {
    font-size: 1.4rem;
    margin: 0;
  }

  @media (max-width: 600px) {
    margin-bottom: 15px;
    h2 {
      font-size: 1.2rem;
    }
  }
`;

const CloseButton = styled.button`
  background: none;
  border: none; 
  cursor: pointer;
  color: #999;
  &:hover {
    color: #666;
  }
`;

const ModalBody = styled.div`
  display: flex; 
  flex-direction: column; 
  align-items: center;

  @media (max-width: 600px) {
    font-size: 0.95rem;
  }
`;

const InfoCard = styled.div`
  width: 100%;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  p {
    margin: 10px 0; 
    font-size: 1.05em;
  }
  strong {
    color: #333;
  }
  @media (max-width: 600px) {
    padding: 15px;
    p {
      font-size: 1em;
      margin: 8px 0;
    }
  }
`;

const PetDetails = styled.div`
  display: flex; 
  align-items: center; 
  margin-bottom: 20px;
`;

const PetImage = styled.img`
  width: 50px; 
  height: 50px; 
  border-radius: 50%; 
  margin-right: 10px; 
  object-fit: cover;
`;

const DocumentSection = styled.div`
  margin-top: 15px;
  width: 100%;
  h3 {
    font-size: 1.1em; 
    margin-bottom: 10px; 
    color: #333;
  }
  ul {
    list-style: none; 
    padding: 0;
  }
  li {
    margin: 5px 0;
  }
  a {
    text-decoration: none;
    color: #007bff;
    font-weight: bold;
  }
  a:hover {
    text-decoration: underline;
  }
`;

const ButtonRow = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: center;
`;

const CloseModalButton = styled.button`
  padding: 10px 15px;
  font-size: 1em;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer; 
  transition: 0.3s;
  &:hover {
    background-color: #0056b3;
  }
  @media (max-width: 600px) {
    font-size: 0.9rem;
    padding: 8px 12px;
  }
`;

const PayButton = styled.button`
  padding: 10px 15px;
  font-size: 1em;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;
  background-color: #28a745;
  &:hover {
    background-color: #218838;
  }
  @media (max-width: 600px) {
    font-size: 0.9rem;
    padding: 8px 12px;
  }
`;
