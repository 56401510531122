import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { IoMdCloseCircle } from 'react-icons/io';
import petIcon from '../../../../imagens/MascoteLiliVidaPetTech.png';
import { getUserIdFromFirebaseId } from '../../../Login/userService';

const PetSelector = ({ onSelect, onClose }) => {
  const [userId, setUserId] = useState(null);
  const [pets, setPets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const obtainUserId = useCallback(async () => {
    try {
      const firebaseId = localStorage.getItem('firebaseId');
      if (firebaseId) {
        const fetchedUserId = await getUserIdFromFirebaseId(firebaseId);
        if (fetchedUserId) {
          setUserId(fetchedUserId);
        } else {
          setError('Falha ao obter ID do usuário.');
        }
      } else {
        setError('Usuário não está autenticado.');
      }
    } catch (error) {
      setError('Erro ao verificar a autenticação.');
    }
  }, []);

  const fetchPets = useCallback(async () => {
    if (!userId) {
      setLoading(false);
      return;
    }
    try {
      const response = await axios.get(`https://vidapet.tech/petList.php?userId=${userId}`);
      const parsedData = response.data;
      if (parsedData.pets && Array.isArray(parsedData.pets)) {
        setPets(parsedData.pets);
      } else {
        setPets([]);
      }
    } catch (err) {
      setError('Não foi possível carregar os pets. Tente novamente mais tarde.');
    } finally {
      setLoading(false);
    }
  }, [userId]);

  useEffect(() => {
    obtainUserId();
  }, [obtainUserId]);

  useEffect(() => {
    if (userId) {
      fetchPets();
    }
  }, [fetchPets, userId]);

  const handleSelectPet = (petId) => {
    onSelect(petId);
  };

  return (
    <ModalOverlay>
      <ModalContainer>
        <Header>
          <Title>Selecione um Pet</Title>
          <CloseButton onClick={onClose}>
            <IoMdCloseCircle size={24} />
          </CloseButton>
        </Header>
        <Body>
          {loading ? (
            <p>Carregando pets...</p>
          ) : error ? (
            <p className="error">{error}</p>
          ) : pets.length > 0 ? (
            <PetList>
              {pets.map((pet) => (
                <PetItem key={pet.petId} onClick={() => handleSelectPet(pet.petId)}>
                  <PetPhoto
                    src={
                      pet.pet_3x4
                        ? `https://vidapet.tech/vidapet/pets/${pet.pet_3x4}?t=${new Date().getTime()}`
                        : petIcon
                    }
                    alt={pet.petName}
                  />
                  <span>{pet.petName}</span>
                </PetItem>
              ))}
            </PetList>
          ) : (
            <p>Nenhum pet encontrado para o usuário.</p>
          )}
        </Body>
      </ModalContainer>
    </ModalOverlay>
  );
};

export default PetSelector;

// ============== Styled Components ==============
const ModalOverlay = styled.div`
  position: fixed;
  top: 0; left: 0;
  width: 100%; height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex; 
  justify-content: center; 
  align-items: center;
  z-index: 1000;
`;

const ModalContainer = styled.div`
  background-color: #fff;
  border-radius: 16px;
  width: 400px;
  max-width: 90%;
  max-height: 80vh;
  padding: 20px;
  position: relative;
  overflow-y: auto;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);

  @media (max-width: 600px) {
    padding: 15px;
  }
`;

const Header = styled.div`
  display: flex; 
  justify-content: space-between; 
  align-items: center;
  margin-bottom: 20px;
  @media (max-width: 600px) {
    margin-bottom: 15px;
  }
`;

const Title = styled.h2`
  margin: 0;
  font-size: 1.4rem;
  @media (max-width: 600px) {
    font-size: 1.2rem;
  }
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: #999;
  &:hover {
    color: #666;
  }
`;

const Body = styled.div`
  @media (max-width: 600px) {
    font-size: 0.95rem;
  }
`;

const PetList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const PetItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  border: 1px solid #ddd;
  border-radius: 8px;
  transition: background-color 0.2s;
  &:hover {
    background-color: #f0f0f0;
  }
  span {
    font-size: 1.1em;
    font-weight: bold;
  }
`;

const PetPhoto = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
  object-fit: cover;
`;
