import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import MensalidadePayments from './MensalidadePayments';

const ParcelasListModal = ({ convenio, onClose }) => {
  const contratoId = convenio.contrato_id;
  const [parcelas, setParcelas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [showPagarParcelaModal, setShowPagarParcelaModal] = useState(false);
  const [selectedParcela, setSelectedParcela] = useState(null);

  useEffect(() => {
    const fetchParcelas = async () => {
      try {
        setLoading(true);
        const resp = await axios.get(
          `https://vidapet.tech/api/DB/vidapettech/serv_Apis/Convenios/get_parcelas_convenio.php?contrato_id=${contratoId}`
        );
        if (resp.data.status === 'success') {
          setParcelas(resp.data.parcelas);
        } else {
          throw new Error(resp.data.message || 'Erro ao buscar parcelas');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchParcelas();
  }, [contratoId]);

  const handlePagarParcela = (parcela) => {
    setSelectedParcela(parcela);
    setShowPagarParcelaModal(true);
  };

  const handleClosePagarParcelaModal = () => {
    setShowPagarParcelaModal(false);
    setSelectedParcela(null);
    // Se quiser, recarregue as parcelas aqui
  };

  if (loading) {
    return (
      <MiniModalOverlay>
        <MiniModalContent>
          <h3>Carregando parcelas...</h3>
        </MiniModalContent>
      </MiniModalOverlay>
    );
  }

  if (error) {
    return (
      <MiniModalOverlay>
        <MiniModalContent>
          <h3>Erro</h3>
          <p>{error}</p>
          <button onClick={onClose}>Fechar</button>
        </MiniModalContent>
      </MiniModalOverlay>
    );
  }

  return (
    <MiniModalOverlay>
      <MiniModalContent>
        <h3>Parcelas do Convênio #{contratoId}</h3>
        {parcelas.length > 0 ? (
          <Table>
            <thead>
              <tr>
                <th>Parcela</th>
                <th>Vencimento</th>
                <th>Status</th>
                <th>Data Pagamento</th>
                <th>Ação</th>
              </tr>
            </thead>
            <tbody>
              {parcelas.map((p) => (
                <tr key={p.id}>
                  <td>{p.numero_parcela}</td>
                  <td>{p.data_vencimento}</td>
                  <td>{p.status_pagamento}</td>
                  <td>{p.data_pagamento || '-'}</td>
                  <td>
                    {p.status_pagamento === 'pendente' ? (
                      <ActionButton onClick={() => handlePagarParcela(p)}>
                        Pagar
                      </ActionButton>
                    ) : '—'}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <p>Nenhuma parcela encontrada.</p>
        )}

        <ButtonRow>
          <button onClick={onClose}>Fechar</button>
        </ButtonRow>

        {showPagarParcelaModal && selectedParcela && (
          <MensalidadePayments
            convenio={convenio}
            parcela={selectedParcela}
            onClose={handleClosePagarParcelaModal}
          />
        )}
      </MiniModalContent>
    </MiniModalOverlay>
  );
};

export default ParcelasListModal;

/* ============== Styled Components ============== */
const MiniModalOverlay = styled.div`
  position: fixed;
  top: 0; left: 0;
  width: 100%; height: 100%;
  background: rgba(0,0,0,0.5);
  display: flex; 
  justify-content: center; 
  align-items: center;
  z-index: 9999;
`;

const MiniModalContent = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 450px; 
  max-width: 90%;
  max-height: 80vh;
  overflow: auto;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);

  h3 {
    margin-top: 0;
    margin-bottom: 15px;
  }

  p {
    margin-bottom: 10px;
  }

  @media (max-width: 600px) {
    width: 95%;
    padding: 15px;
    h3 {
      font-size: 1.1rem;
    }
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 10px;
  font-size: 0.9rem;

  th, td {
    border: 1px solid #ccc;
    padding: 6px 8px;
    text-align: center;
  }

  th {
    background: #f2f2f2;
  }

  /* Responsivo: converte a tabela em "cards" p/ mobile */
  @media (max-width: 600px) {
    thead {
      display: none; /* some o cabeçalho */
    }
    tr {
      display: block;
      margin-bottom: 15px;
      border: 1px solid #ccc;
      border-radius: 6px;
      overflow: hidden;
    }
    td {
      display: block;
      text-align: left;
      border: none;
      border-bottom: 1px solid #eee;
      position: relative;
      padding: 8px;
      font-size: 0.85rem;
    }
    /* Exibe o nome da coluna antes do valor */
    td:before {
      content: attr(data-label);
      font-weight: bold;
      display: inline-block;
      width: 120px;
      color: #555;
    }
    /* Precisamos usar nth-of-type para cada coluna */
    tbody tr td:nth-of-type(1):before { content: "Parcela: "; }
    tbody tr td:nth-of-type(2):before { content: "Vencimento: "; }
    tbody tr td:nth-of-type(3):before { content: "Status: "; }
    tbody tr td:nth-of-type(4):before { content: "Pagamento: "; }
    tbody tr td:nth-of-type(5):before { content: "Ação: "; }
  }
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;

  button {
    background-color: #ccc;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
      background-color: #bbb;
    }
  }
`;

const ActionButton = styled.button`
  background-color: #28a745;
  color: #fff;
  border: none;
  padding: 6px 10px;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #218838;
  }
`;
