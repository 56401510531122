import React, { useState, useRef } from 'react';

/**
 * Estilos para o modal
 * - modalStyle.overlay: Estilo do fundo escuro do modal, que ocupa toda a tela e centraliza o conteúdo.
 * - modalStyle.content: Estilo da caixa do conteúdo do modal, com bordas arredondadas, padding e altura/ largura definidas.
 * - modalStyle.closeButton: Estilo do botão de fechar (X) no canto superior direito do modal.
 * - modalStyle.header: Estilo para os títulos dentro do modal, com maior destaque.
 * - modalStyle.paragraph: Estilo para os parágrafos com o conteúdo descritivo dos termos.
 * - modalStyle.buttonContainer: Contêiner para os botões de ação (Aceitar e Fechar), com espaço entre eles.
 * - modalStyle.button: Estilo genérico para os botões de ação.
 * - modalStyle.acceptButton: Estilo para o botão de aceitação, com cor verde.
 * - modalStyle.closeButtonStyle: Estilo para o botão de fechar, com cor vermelha.
 */
const modalStyle = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  content: {
    background: 'white',
    padding: '20px',
    maxWidth: '500px', // Largura fixa
    width: '100%',
    borderRadius: '8px',
    position: 'relative',
    overflowY: 'auto',
    maxHeight: '70vh', // Altura fixa
  },
  closeButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    background: 'transparent',
    border: 'none',
    fontSize: '20px',
    cursor: 'pointer',
  },
  header: {
    fontSize: '24px',
    fontWeight: 'bold',
  },
  paragraph: {
    fontSize: '16px',
    lineHeight: '1.6',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '20px',
  },
  button: {
    padding: '10px 20px',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '16px',
  },
  acceptButton: {
    backgroundColor: '#4CAF50', // Cor verde para o botão de aceitar
    color: 'white',
  },
  closeButtonStyle: {
    backgroundColor: '#f44336', // Cor vermelha para o botão de fechar
    color: 'white',
  },
};

/**
 * Componente `TermsModal`
 * 
 * O `TermsModal` é um componente React que exibe os termos de uso do serviço VidaPet.Tech. 
 * Ele permite que o usuário leia os termos e aceite ou feche o modal.
 *
 * Funcionalidades:
 * - Exibição de termos de uso com scroll
 * - Botão de aceitação para registrar o consentimento do usuário
 * - Botão de fechamento para fechar o modal sem aceitar os termos
 *
 * Propriedades:
 * - `userId` (string): Identificador único do usuário, necessário para registrar o aceite dos termos.
 * - `onClose` (function): Função de callback para fechar o modal.
 */
function TermsModal({ userId, onClose }) {
  // Estado para controlar se o botão "Aceitar" deve ser habilitado
  const [isEnabled, setIsEnabled] = useState(false);

  // Referência para detectar quando o usuário alcança o final dos termos
  const endOfTermsRef = useRef(null);

  /**
   * Função que lida com o evento de aceitação dos termos.
   * Quando o usuário clica em "Aceitar", um POST é enviado para registrar o aceite no servidor.
   */
  const handleAcceptTerms = () => {
    fetch('https://vidapet.tech/api/DB/vidapettech/termos.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userId }),
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        console.log('Termos de uso aceitos com sucesso.');
        onClose(); // Fecha o modal após o aceite bem-sucedido
      } else {
        console.error('Erro ao aceitar termos de uso:', data.error);
      }
    })
    .catch(error => {
      console.error('Erro na rede:', error);
    });
  };

  /**
   * Função de manipulação de scroll.
   * Verifica se o usuário rolou até o final do conteúdo dos termos e habilita o botão "Aceitar".
   */
  const handleScroll = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
    if (scrollTop + clientHeight === scrollHeight) {
      setIsEnabled(true); // Habilita o botão "Aceitar" quando o final for alcançado
    }
  };

  return (
    <div style={modalStyle.overlay}>
      <div style={modalStyle.content} onScroll={handleScroll}>
        {/* Botão de Fechar */}
        
        {/* Título do Modal */}
        <h2 style={modalStyle.header}>Termos de Uso - VidaPet.Tech</h2>
        
        {/* Introdução aos Termos */}
        <p style={modalStyle.paragraph}>
          Acessando ou utilizando a plataforma VidaPet.Tech, você concorda com os termos descritos abaixo.
        </p>

        {/* Seções dos Termos */}
        <h3 style={modalStyle.header}>1. Aceitação dos Termos</h3>
        <p style={modalStyle.paragraph}>
          Ao acessar ou utilizar o Serviço, você declara que leu, entendeu e concorda em cumprir estes Termos.
        </p>

        <h3 style={modalStyle.header}>2. Descrição do Serviço</h3>
        <p style={modalStyle.paragraph}>
          O VidaPet.Tech oferece uma plataforma multifuncional para interação entre clínicas veterinárias, veterinários independentes e usuários finais.
        </p>

        <h3 style={modalStyle.header}>3. Uso do Serviço</h3>
        <p style={modalStyle.paragraph}>
          O uso do Serviço está sujeito a restrições legais, incluindo, mas não se limitando a, comportamentos prejudiciais e interferências no funcionamento do Serviço.
        </p>

        <h3 style={modalStyle.header}>4. Registro</h3>
        <p style={modalStyle.paragraph}>
          Para utilizar o Serviço, pode ser necessário fornecer informações pessoais verdadeiras e precisas.
        </p>

        <h3 style={modalStyle.header}>5. Privacidade</h3>
        <p style={modalStyle.paragraph}>
          A política de privacidade descreve como coletamos, usamos e protegemos suas informações pessoais.
        </p>

        <h3 style={modalStyle.header}>6. Propriedade Intelectual</h3>
        <p style={modalStyle.paragraph}>
          Todos os direitos relativos ao Serviço, incluindo software e conteúdo, pertencem ao VidaPet.Tech.
        </p>

        <h3 style={modalStyle.header}>7. Limitação de Responsabilidade</h3>
        <p style={modalStyle.paragraph}>
          O VidaPet.Tech não será responsável por danos resultantes do uso ou incapacidade de usar o Serviço.
        </p>

        <h3 style={modalStyle.header}>8. Modificações dos Termos de Uso</h3>
        <p style={modalStyle.paragraph}>
          O VidaPet.Tech pode modificar estes Termos a qualquer momento, e as modificações entrarão em vigor imediatamente.
        </p>

        <h3 style={modalStyle.header}>9. Contato</h3>
        <p style={modalStyle.paragraph}>
          Se você tiver dúvidas sobre os Termos, entre em contato conosco em contato@vidapet.tech.
        </p>

        {/* Referência para o fim do conteúdo dos termos */}
        <div ref={endOfTermsRef}></div>

        {/* Contêiner de Botões */}
        <div style={modalStyle.buttonContainer}>
          {/* Botão de Aceitar */}
          <button
            style={{ ...modalStyle.button, ...modalStyle.acceptButton }}
            onClick={handleAcceptTerms}
            disabled={!isEnabled} // Desabilita o botão até que o final dos termos seja alcançado
          >
            Aceitar
          </button>
          {/* Botão de Fechar */}
          <button
            style={{ ...modalStyle.button, ...modalStyle.closeButtonStyle }}
          >
            Fechar
          </button>
        </div>
      </div>
    </div>
  );
}

export default TermsModal;
