import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { QRCodeCanvas } from 'qrcode.react';
import { FaShareAlt, FaEdit, FaTrashAlt, FaTh, FaBars } from 'react-icons/fa';

const HealthEventsView = ({ petId, reloadEvents, viewOnly = false, gridView = false }) => {
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [editingEvent, setEditingEvent] = useState(null);
  const [viewMode, setViewMode] = useState('grid'); // 'grid' ou 'list'
  const [formData, setFormData] = useState({
    event_type: '',
    event_description: '',
    event_date: '',
    anexo_photo: null,
  });

  const eventTypeOptions = ['Consulta', 'Vacinação', 'Exame', 'Cirurgia', 'Outro'];

  const fetchHealthEvents = async () => {
    try {
      const response = await axios.get(`https://vidapet.tech/get_health_events.php?petId=${petId}`);
      if (Array.isArray(response.data)) {
        setEvents(response.data);
      } else {
        setEvents([]);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Erro ao carregar eventos de saúde:', error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchHealthEvents();
  }, [petId]);

  const handleReloadEvents = () => {
    if (reloadEvents && typeof reloadEvents === 'function') {
      reloadEvents();
    } else {
      fetchHealthEvents();
    }
  };

  const handleEditEvent = (event) => {
    setEditingEvent(event);
    setFormData({
      event_type: event.event_type,
      event_description: event.event_description,
      event_date: event.event_date,
      anexo_photo: event.anexo_photo || null,
    });
  };

  const handleCancelEdit = () => {
    setEditingEvent(null);
    setFormData({
      event_type: '',
      event_description: '',
      event_date: '',
      anexo_photo: null,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData((prev) => ({ ...prev, anexo_photo: file || null }));
  };

  const handleSaveEvent = async () => {
    const { event_type, event_description, event_date, anexo_photo } = formData;
    const formattedDate = new Date(event_date);
    if (isNaN(formattedDate)) {
      console.error("Data inválida");
      return;
    }

    const updatedEvent = {
      action: "updateEvent",
      id: editingEvent.id,
      event_type,
      event_description,
      event_date: formattedDate.toISOString().split('T')[0], 
      anexo_photo: anexo_photo ? anexo_photo.name : null,
    };

    try {
      await axios.put(
        `https://vidapet.tech/CRUD-Health-Events.php?eventId=${editingEvent.id}`,
        updatedEvent
      );
      handleReloadEvents();
      setEditingEvent(null);
      setFormData({
        event_type: '',
        event_description: '',
        event_date: '',
        anexo_photo: null,
      });
    } catch (error) {
      console.error('Erro ao salvar evento:', error);
    }
  };

  const handleDeleteHealthEvent = async (eventId) => {
    const confirmDelete = window.confirm('Você realmente deseja excluir este evento de saúde?');
    if (!confirmDelete) return;

    try {
      await axios.delete(`https://vidapet.tech/CRUD-Health-Events.php?id=${eventId}`);
      handleReloadEvents();
    } catch (error) {
      console.error('Erro ao excluir evento de saúde:', error);
    }
  };

  const handleShare = async (fileUrl) => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Evento de Saúde',
          url: fileUrl,
        });
      } catch (error) {
        console.error('Erro ao compartilhar:', error);
      }
    } else {
      navigator.clipboard.writeText(fileUrl)
        .then(() => alert('Link copiado para a área de transferência!'))
        .catch((error) => console.error('Erro ao copiar o link:', error));
    }
  };

  const renderAttachmentThumbnail = (attachmentPath) => {
    if (!attachmentPath) return null;
    const fileUrl = `https://vidapet.tech/${attachmentPath}`;
    const extension = attachmentPath.split('.').pop().toLowerCase();

    let thumbElement = null;
    if (['jpg', 'jpeg', 'png'].includes(extension)) {
      thumbElement = (
        <a href={fileUrl} download>
          <img 
            src={fileUrl} 
            alt="Anexo" 
            style={{ maxWidth: '100px', cursor: 'pointer' }} 
          />
        </a>
      );
    } else if (extension === 'pdf') {
      thumbElement = (
        <a href={fileUrl} download>
          <img
            src="pdf-icon.png"
            alt="PDF Icon"
            style={{ maxWidth: '100px', cursor: 'pointer' }}
          />
        </a>
      );
    } else {
      thumbElement = (
        <a href={fileUrl} download>
          <span>Arquivo: {attachmentPath}</span>
        </a>
      );
    }

    return (
      <AttachmentContainer>
        {thumbElement}
        <QRCodeCanvas 
          value={fileUrl} 
          size={60} 
          style={{ marginLeft: '8px' }} 
        />
        <IconButton onClick={() => handleShare(fileUrl)}>
          <FaShareAlt size={24} color="#007BFF" />
        </IconButton>
      </AttachmentContainer>
    );
  };

  return (
    <Container gridView={gridView}>
      <Header>
        <h3>Eventos de Saúde</h3>
        <ViewModeSwitch>
          <IconButton onClick={() => setViewMode('list')} disabled={viewMode === 'list'}>
            <FaBars size={24} color={viewMode === 'list' ? "#007BFF" : "#000"} />
          </IconButton>
          <IconButton onClick={() => setViewMode('grid')} disabled={viewMode === 'grid'}>
            <FaTh size={24} color={viewMode === 'grid' ? "#007BFF" : "#000"} />
          </IconButton>
        </ViewModeSwitch>
      </Header>

      {isLoading ? (
        <p>Carregando eventos...</p>
      ) : events && events.length > 0 ? (
        <EventsGrid viewMode={viewMode}>
          {events.map((event) => (
            <EventCard key={event.id} viewMode={viewMode}>
              {editingEvent === event ? (
                <>
                  <div>
                    <label>Tipo de Evento:</label>
                    <select
                      name="event_type"
                      value={formData.event_type}
                      onChange={handleInputChange}
                    >
                      <option value="">Selecione o tipo</option>
                      {eventTypeOptions.map((option) => (
                        <option key={option} value={option}>{option}</option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label>Descrição:</label>
                    <textarea
                      name="event_description"
                      value={formData.event_description}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div>
                    <label>Data:</label>
                    <input
                      type="date"
                      name="event_date"
                      value={formData.event_date}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div>
                    <label>Anexar Foto:</label>
                    <input
                      type="file"
                      accept="image/*,application/pdf"
                      onChange={handleFileChange}
                    />
                  </div>
                  <button onClick={handleSaveEvent}>Salvar</button>
                  <button onClick={handleCancelEdit}>Cancelar</button>
                </>
              ) : (
                <>
                  <div><strong>Tipo:</strong> {event.event_type}</div>
                  <div><strong>Descrição:</strong> {event.event_description}</div>
                  <div><strong>Data:</strong> {event.event_date}</div>
                  <div>{renderAttachmentThumbnail(event.anexo_photo)}</div>
                  {!viewOnly && (
                    <Actions>
                      <IconButton onClick={() => handleEditEvent(event)}>
                        <FaEdit size={24} color="#007BFF" />
                      </IconButton>
                      <IconButton onClick={() => handleDeleteHealthEvent(event.id)}>
                        <FaTrashAlt size={24} color="#FF0000" />
                      </IconButton>
                    </Actions>
                  )}
                </>
              )}
            </EventCard>
          ))}
        </EventsGrid>
      ) : (
        <p>Nenhum evento de saúde encontrado.</p>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: ${(props) => (props.gridView ? 'grid' : 'block')};
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 16px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const ViewModeSwitch = styled.div`
  display: flex;
  gap: 8px;
`;

const EventsGrid = styled.div`
  display: ${(props) => (props.viewMode === 'grid' ? 'grid' : 'block')};
  grid-template-columns: ${(props) => (props.viewMode === 'grid' ? 'repeat(auto-fill, minmax(250px, 1fr))' : 'none')};
  gap: 16px;
`;

const EventCard = styled.div`
  padding: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
`;

const AttachmentContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  gap: 8px;
`;

const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
`;

const Actions = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 8px;
`;

export default HealthEventsView;
