import React, { useState } from 'react';
import styled from 'styled-components';
import { RiHeart2Fill, RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri';
import ProgramaDeDoacao from '../ProgDoacao/ProgramaDoacao';

const DonationCard = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Container>
      <CardContainer onClick={() => setIsExpanded(!isExpanded)}>
        <IconWrapper>
          <RiHeart2Fill size={24} color="#FF6347" />
        </IconWrapper>
        <Text>Doe a partir de R$20,00 e entre para o clube Amigo VidaPet.Tech com benefícios por um ano!        
        </Text>
        <ExpandText>
          {isExpanded ? (
            <>
              Fechar <RiArrowUpSLine size={18} />
            </>
          ) : (
            <>
              Clique para expandir <RiArrowDownSLine size={18} />
            </>
          )}
        </ExpandText>
      </CardContainer>

      {isExpanded && (
        <ExpandedContainer>
          <ProgramaDeDoacao />
        </ExpandedContainer>
      )}
    </Container>
  );
};

export default DonationCard;

// ====== Estilização ======
const Container = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 20px auto;
`;

const CardContainer = styled.div`
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: 0.3s ease;
  
  &:hover {
    background-color: #ffefef;
  }
`;

const IconWrapper = styled.div`
  flex-shrink: 0;
`;

const Text = styled.p`
  margin: 0;
  padding-left: 10px;
  font-size: 16px;
  color: #333;
  flex-grow: 1;
`;

const ExpandText = styled.span`
  font-size: 12px;
  color: #888;
  display: flex;
  align-items: center;
`;

const ExpandedContainer = styled.div`
  margin-top: 10px;
  padding: 15px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;
