import React, { useEffect, useState, useCallback, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import pinIcon from '../../imagens/icon-Map.png';
import userLocationIconImage from '../../imagens/user-location-icon.png';
import Filter from './Filter';
import './RescueView.css';
import { FaArrowCircleRight } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

// Componente de formulário (modal)
import PetAlertForm from './PetAlertForm';

const RescueView = () => {
  // Estados de controle
  const [rescueData, setRescueData] = useState([]);
  const [filteredRescueData, setFilteredRescueData] = useState([]);
  const [mapState, setMapState] = useState('minimized'); // 'initial', 'expanded', 'minimized'
  const [userLocation, setUserLocation] = useState(null);
  const [mapCenter, setMapCenter] = useState([-15.793889, -47.882778]); // Padrão: Brasília
  const [zoomLevel, setZoomLevel] = useState(5);
  const [rescueStatus, setRescueStatus] = useState([]);
  const [expandedImage, setExpandedImage] = useState(null);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [locationError, setLocationError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [userPhotoURLs, setUserPhotoURLs] = useState({});
  const [showPetAlertForm, setShowPetAlertForm] = useState(false);

  // Para infinite scroll: quantos itens são renderizados inicialmente
  const [visibleCount, setVisibleCount] = useState(10);
  const loaderRef = useRef(null);

  const navigate = useNavigate();

  // --- BUSCA DE DADOS ---
  useEffect(() => {
    const fetchRescuesWithDetailsAndFollowers = async () => {
      try {
        const response = await fetch('https://vidapet.tech/rescue_list.php');
        const data = await response.json();
        console.log('Dados recebidos da API rescue_list.php:', data);

        // Filtra casos com coordenadas válidas
        const rescueCases = data.filter(
          (item) =>
            item.latitude &&
            item.longitude &&
            (item.latitude !== 0 || item.longitude !== 0)
        );

        // Busca detalhes e seguidores (concorrência limitada)
        const concurrencyLimit = 5;
        const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
        const detailedRescues = [];
        for (let i = 0; i < rescueCases.length; i += concurrencyLimit) {
          const chunk = rescueCases.slice(i, i + concurrencyLimit);
          const promises = chunk.map(async (rescue) => {
            try {
              const detailResponse = await fetch(
                `https://vidapet.tech/api/resgate/rescues.php?id=${rescue.id}`
              );
              const detailData = await detailResponse.json();
              if (detailData.success) {
                const followersResponse = await fetch(
                  `https://vidapet.tech/api/resgate/rescue_followers.php?rescue_id=${rescue.id}`
                );
                const followersData = await followersResponse.json();
                return {
                  ...rescue,
                  pet_found: detailData.resgate.pet_found,
                  pet_rescued: detailData.resgate.pet_rescued,
                  followers:
                    followersData.success && Array.isArray(followersData.followers)
                      ? followersData.followers
                      : [],
                };
              } else {
                console.error(
                  `Erro ao buscar detalhes do resgate ID ${rescue.id}: ${detailData.mensagem}`
                );
                return { ...rescue, followers: [] };
              }
            } catch (error) {
              console.error(`Erro ao buscar detalhes ou seguidores do ID ${rescue.id}:`, error);
              return { ...rescue, followers: [] };
            }
          });
          const results = await Promise.all(promises);
          detailedRescues.push(...results);
          await delay(100);
        }
        setRescueData(detailedRescues);
        setFilteredRescueData(detailedRescues);
        setRescueStatus(new Array(detailedRescues.length).fill(''));

        // Obter localização do usuário
        const loc = await getUserLocation();
        if (!loc) setLocationError(true);

        // Carrega estados do IBGE
        await fetchStates();

        setIsLoading(false);
        console.log('Dados completos de resgates:', detailedRescues);
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
        setIsLoading(false);
      }
    };

    fetchRescuesWithDetailsAndFollowers();
  }, []);

  // --- GEOLOCALIZAÇÃO DO USUÁRIO ---
  const getUserLocation = async () => {
    return new Promise((resolve) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const userCoords = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setUserLocation(userCoords);
          setMapCenter([userCoords.lat, userCoords.lng]);
          setZoomLevel(10);
          resolve(userCoords);
        },
        async () => {
          try {
            const response = await fetch('https://ipinfo.io/json?token=73b555d19e1f65');
            const data = await response.json();
            const [lat, lng] = data.loc.split(',').map(Number);
            const ipCoords = { lat, lng };
            setMapCenter([lat, lng]);
            setZoomLevel(9);
            resolve(ipCoords);
          } catch (error) {
            console.error('Falha ao obter localização por IP', error);
            const defaultCoords = [-15.793889, -47.882778];
            setMapCenter(defaultCoords);
            setZoomLevel(5);
            setLocationError(true);
            resolve(null);
          }
        }
      );
    });
  };

  // --- CARREGAR ESTADOS E CIDADES (IBGE) ---
  const fetchStates = async () => {
    try {
      const response = await fetch(
        'https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome'
      );
      const data = await response.json();
      setStates(data);
    } catch (error) {
      console.error('Erro ao buscar estados:', error);
    }
  };

  const fetchCities = async (stateCode) => {
    try {
      const response = await fetch(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${stateCode}/municipios?orderBy=nome`
      );
      const data = await response.json();
      setCities(data);
    } catch (error) {
      console.error('Erro ao buscar cidades:', error);
    }
  };

  const getCoordinatesFromCity = async (city, state) => {
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/search?city=${encodeURIComponent(
          city
        )}&state=${encodeURIComponent(state)}&country=Brazil&format=json`
      );
      const data = await response.json();
      if (data.length > 0) {
        const { lat, lon } = data[0];
        return { latitude: parseFloat(lat), longitude: parseFloat(lon) };
      }
    } catch (error) {
      console.error('Erro ao buscar coordenadas:', error);
    }
    return null;
  };

  // --- Filtro de Estado e Cidade ---
  // Observe que as variáveis selectedState e selectedCity foram declaradas uma única vez.
  const handleStateChange = (e) => {
    const state = e.target.value;
    setSelectedState(state);
    setSelectedCity('');
    if (state) fetchCities(state);
  };
  const handleCityChange = (e) => {
    setSelectedCity(e.target.value);
  };
  const handleFilterChange = (filteredData) => {
    setFilteredRescueData(filteredData);
  };
  const toggleFilterVisibility = () => {
    setIsFilterVisible(!isFilterVisible);
  };
  const centerMapOnFilter = useCallback(async () => {
    if (selectedCity && selectedState) {
      const coords = await getCoordinatesFromCity(selectedCity, selectedState);
      if (coords) {
        setMapCenter([coords.latitude, coords.longitude]);
        setZoomLevel(12);
        const filteredData = rescueData.filter(
          (rescue) => rescue.city === selectedCity && rescue.state === selectedState
        );
        setFilteredRescueData(filteredData);
      }
    }
  }, [selectedCity, selectedState, rescueData]);
  useEffect(() => {
    centerMapOnFilter();
  }, [centerMapOnFilter]);

  // --- RECENTRALIZAÇÃO NO USUÁRIO ---
  useEffect(() => {
    if (userLocation) {
      const timeoutId = setTimeout(() => {
        setMapCenter([userLocation.lat, userLocation.lng]);
        setZoomLevel(10);
      }, 6000);
      return () => clearTimeout(timeoutId);
    }
  }, [userLocation]);

  // --- CONTROLE DE EXPANSÃO/MINIMIZAÇÃO DO MAPA ---
  const handleMapToggle = () => {
    if (mapState === 'initial') {
      setMapState('expanded');
    } else if (mapState === 'expanded') {
      setMapState('minimized');
    } else {
      setMapState('initial');
    }
  };

  function MapEventHandlers() {
    const map = useMapEvents({
      moveend() {
        const bounds = map.getBounds();
        const visibleData = rescueData.filter((rescue) => {
          const rescueLatLng = L.latLng(rescue.latitude, rescue.longitude);
          return bounds.contains(rescueLatLng);
        });
        setFilteredRescueData(visibleData);
      },
    });
    return null;
  }

  const rescueIcon = L.icon({
    iconUrl: pinIcon,
    iconSize: [38, 38],
    iconAnchor: [19, 38],
  });

  // --- CARREGAR PHOTO URLs ---
  useEffect(() => {
    const allFollowerIds = rescueData.flatMap((rescue) => rescue.followers);
    const uniqueFollowerIds = [...new Set(allFollowerIds)];
    if (uniqueFollowerIds.length > 0) {
      fetch(
        `https://vidapet.tech/api/resgate/userid_to_photourl.php?userIds=${uniqueFollowerIds.join(',')}`
      )
        .then((response) => response.json())
        .then((data) => {
          setUserPhotoURLs(data);
        })
        .catch((error) => {
          console.error('Erro ao buscar photoURLs:', error);
        });
    }
  }, [rescueData]);

  const truncateText = (text, maxLength) => {
    if (!text) return '';
    if (text.length <= maxLength) return text;
    return text.slice(0, maxLength) + '...';
  };

  const handleFollow = (id) => {
    navigate(`/rescues/${id}`);
  };

  const openImage = (imageUrl) => {
    setExpandedImage(imageUrl);
  };

  const closeImage = () => {
    setExpandedImage(null);
  };

  const ImageWithLoader = ({ src, alt, className, onClick }) => {
    const [imageLoaded, setImageLoaded] = useState(false);
    return (
      <div className="image-placeholder">
        {!imageLoaded && <div className="spinner" />}
        <img
          src={`https://vidapet.tech/${src}`}
          alt={alt}
          className={className}
          style={{
            display: imageLoaded ? 'block' : 'none',
            cursor: onClick ? 'pointer' : 'default',
          }}
          onLoad={() => setImageLoaded(true)}
          onError={(e) => {
            setImageLoaded(true);
            e.target.src = 'https://dummyimage.com/200x200/ccc/000';
          }}
          onClick={onClick}
        />
      </div>
    );
  };

  // --- Infinite Scroll: aumenta visibleCount quando o loader entrar na viewport ---
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setVisibleCount((prev) => prev + 10);
        }
      },
      { threshold: 1 }
    );
    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }
    return () => {
      if (loaderRef.current) observer.unobserve(loaderRef.current);
    };
  }, []);

  return (
    <div className="Container">
      {isLoading && <div className="loading-bar loading">Atualizando dados...</div>}
      {!isLoading && (
        <>
          {locationError && (
            <div className="alert-location">
              Não conseguimos capturar sua localização. Selecione um ponto no mapa ou use o filtro para escolher a cidade.
            </div>
          )}
          {/* Filtro de Resgates */}
          <button className="FilterButton" onClick={toggleFilterVisibility}>
            {isFilterVisible ? 'Ocultar Filtro' : 'Opções de Busca'}
          </button>
          {isFilterVisible && (
            <div className="FilterContainer">
              <div>
                <label>Estado</label>
                <select value={selectedState} onChange={handleStateChange}>
                  <option value="">Selecione um estado</option>
                  {states.map((state) => (
                    <option key={state.id} value={state.sigla}>
                      {state.nome}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label>Cidade</label>
                <select
                  value={selectedCity}
                  onChange={handleCityChange}
                  disabled={!selectedState}
                >
                  <option value="">Selecione uma cidade</option>
                  {cities.map((city) => (
                    <option key={city.id} value={city.nome}>
                      {city.nome}
                    </option>
                  ))}
                </select>
              </div>
              <Filter
                rescueData={rescueData}
                onFilterChange={handleFilterChange}
                selectedState={selectedState}
                selectedCity={selectedCity}
              />
            </div>
          )}
          {/* Mapa de Resgates */}
          <div className={`MapContainerWrapper ${mapState === 'expanded' ? 'expanded' : mapState === 'minimized' ? 'minimized' : ''}`}>
            <MapContainer
              center={mapCenter}
              zoom={zoomLevel}
              scrollWheelZoom={true}
              style={{ height: '100%', width: '100%' }}
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution="&copy; OpenStreetMap"
              />
              <MapEventHandlers />
              {userLocation && (
                <Marker
                  position={userLocation}
                  icon={L.icon({
                    iconUrl: userLocationIconImage,
                    iconSize: [38, 38],
                    iconAnchor: [19, 38],
                  })}
                >
                  <Popup>Sua localização</Popup>
                </Marker>
              )}
              {filteredRescueData.slice(0, visibleCount).map((rescue) => {
                const { id, latitude, longitude, image_path, title, comments, pet_found, pet_rescued, followers = [] } = rescue;
                return (
                  <Marker key={id} position={[latitude, longitude]} icon={rescueIcon}>
                    <Popup>
                      <div className="PopupContent">
                        <div className="badge-container">
                          {pet_found === 'sim' && <div className="badge badge-found">Encontrado</div>}
                          {pet_rescued === 'sim' && <div className="badge badge-rescued">Resgatado</div>}
                        </div>
                        <ImageWithLoader
                          src={image_path}
                          alt={`Resgate ${title}`}
                          onClick={() => openImage(image_path)}
                        />
                        <p className="Comments" title={comments}>{truncateText(comments, 100)}</p>
                        <div className="ActionButtonWrapper">
                          <button className="FollowButton" onClick={() => handleFollow(id)}>
                            Mais Informações <FaArrowCircleRight />
                          </button>
                        </div>
                        <div className="RescueStatusOverlay">
                          {rescueStatus[id] && <span>{`Status: ${rescueStatus[id]}`}</span>}
                        </div>
                        {Array.isArray(followers) && followers.length > 0 && (
                          <div className="FollowersSection">
                            <strong>Usuários Acompanhando:</strong>
                            <ul>
                              {followers.map((followerId) => (
                                <li key={followerId} className="FollowerItem">
                                  <img
                                    src={userPhotoURLs[String(followerId)] || 'https://dummyimage.com/40x40/ccc/000'}
                                    alt={`Usuário ${followerId}`}
                                    className="FollowerImage"
                                    onError={(e) => {
                                      e.target.onerror = null;
                                      e.target.src = 'https://dummyimage.com/40x40/ccc/000';
                                    }}
                                  />
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                    </Popup>
                  </Marker>
                );
              })}
            </MapContainer>
            <div ref={loaderRef} style={{ height: '20px' }}></div>
            {mapState !== 'expanded' && mapState !== 'minimized' && (
              <button className="MapExpandButton" onClick={handleMapToggle}>
                Expandir Mapa
              </button>
            )}
            {mapState === 'expanded' && (
              <button className="MapMinimizeButton" onClick={handleMapToggle}>
                Minimizar Mapa
              </button>
            )}
            {mapState === 'minimized' && (
              <button className="MapExpandButton" onClick={handleMapToggle}>
                Expandir Mapa
              </button>
            )}
          </div>
          {/* Lista de Casos de Resgate */}
          <div className="RescueList">
            {filteredRescueData.slice(0, visibleCount).map((rescue) => {
              const { id, image_path, title, comments, pet_found, pet_rescued, followers = [] } = rescue;
              return (
                <div className="RescueCard" key={id}>
                  <div className="badge-container">
                    {pet_found === 'sim' && <div className="badge badge-found">Encontrado</div>}
                    {pet_rescued === 'sim' && <div className="badge badge-rescued">Resgatado</div>}
                  </div>
                  <ImageWithLoader
                    src={image_path}
                    alt={`Resgate ${title}`}
                    className="RescueImage"
                    onClick={() => openImage(image_path)}
                  />
                  <div className="CardContent">
                    <h2 className="CardTitle">{title}</h2>
                    <p className="Comments" title={comments}>{truncateText(comments, 100)}</p>
                    <div className="ActionButtonWrapper">
                      <button className="FollowButton" onClick={() => handleFollow(id)}>
                        Mais Informações <FaArrowCircleRight />
                      </button>
                    </div>
                    <div className="RescueStatusOverlay">
                      {rescueStatus[id] && <span>{`Status: ${rescueStatus[id]}`}</span>}
                    </div>
                    {Array.isArray(followers) && followers.length > 0 && (
                      <div className="FollowersSection">
                        <strong>Usuários Acompanhando:</strong>
                        <ul>
                          {followers.map((followerId) => (
                            <li key={followerId} className="FollowerItem">
                              <img
                                src={userPhotoURLs[String(followerId)] || 'https://dummyimage.com/40x40/ccc/000'}
                                alt={`Usuário ${followerId}`}
                                className="FollowerImage"
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = 'https://dummyimage.com/40x40/ccc/000';
                                }}
                              />
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          {expandedImage && (
            <div className="ImageModal" onClick={closeImage}>
              <img className="ModalImage" src={`https://vidapet.tech/${expandedImage}`} alt="Imagem Expandida" />
            </div>
          )}
        </>
      )}
      {showPetAlertForm && (
        <div className="modal-overlay">
          <PetAlertForm closeForm={() => setShowPetAlertForm(false)} />
        </div>
      )}
    </div>
  );
};

export default RescueView;
