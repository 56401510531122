import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { IoMdCloseCircle, IoMdHeart, IoMdPrint, IoMdPeople } from 'react-icons/io';
import { FaEdit, FaSave, FaTimes, FaDownload, FaShareAlt } from 'react-icons/fa';
import { QRCodeCanvas } from 'qrcode.react';
import domtoimage from 'dom-to-image';
// Modais que já existem no seu projeto
import HealthEventsModal from './HealthEventsModal';
import VaccineModal from './VaccineModal';
import AdoptMe from './AdoptMe';
import TQRCode_Association from './TQRCode_Association';

// Imagem padrão do pet (caso não exista)
import petIcon from '../../../imagens/MascoteLiliVidaPetTech.png';

// CSS externo (você pode ajustar ou usar styled-components se preferir)
import "./Carteirinha.css";

/**
 * Este componente exibe a carteirinha do pet em estilo "hero":
 *  - Imagem grande (retangular) no topo
 *  - Nome, raça e idade sobrepostos na imagem
 *  - Botões mais concisos e layout compacto
 */
const Carteirinha = ({ pet, onClose }) => {
  // ---------------- ESTADOS PRINCIPAIS ----------------
  const [nomeTutor, setNomeTutor] = useState('');
  const [telefoneTutor, setTelefoneTutor] = useState('');
  const [editMode, setEditMode] = useState(false);

  // Estados para modais
  const [showHealthEventsModal, setShowHealthEventsModal] = useState(false);
  const [showVaccineModal, setShowVaccineModal] = useState(false);
  const [selectedVaccine, setSelectedVaccine] = useState(null);
  const [appliedVaccines, setAppliedVaccines] = useState([]);
  const [healthEvents, setHealthEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showAdoptMeModal, setShowAdoptMeModal] = useState(false);
  const [isInAdoptionProcess, setIsInAdoptionProcess] = useState(false);

  // Imagem do pet
  const [petImage, setPetImage] = useState(
    pet.pet_3x4
      ? `https://vidapet.tech/vidapet/pets/${pet.pet_3x4.split('/').pop()}`
      : petIcon
  );

  // Snapshot (imagem gerada da carteirinha)
  const [snapshotUrl, setSnapshotUrl] = useState(null);
  const [showSnapshotOverlay, setShowSnapshotOverlay] = useState(false);

  // Tag (QR Code)
  const [tagCode, setTagCode] = useState(pet.tagCode || "");

  const qrCodeRef = useRef();

  // ---------------- BUSCAR DADOS (USEEFFECT) ----------------
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Status de adoção
        const adoptionRes = await fetch(
          `https://vidapet.tech/api/db/vidapettech/adoption/adoption_user_pet.php?petId=${pet.petId || pet.id}`
        );
        if (!adoptionRes.ok) throw new Error('Erro ao buscar status de adoção');
        const adoptionData = await adoptionRes.json();
        setIsInAdoptionProcess(adoptionData.isInAdoption);

        // Dados da carteirinha (tutor, telefone, etc.)
        const carteiraRes = await fetch(
          `https://vidapet.tech/api_carteira_pet.php?petId=${pet.petId || pet.id}`
        );
        const carteiraData = await carteiraRes.json();
        setNomeTutor(carteiraData.observacao01 || 'Tutor');
        setTelefoneTutor(carteiraData.observacao02 || 'Telefone');

        // Vacinas aplicadas
        const vaccinesRes = await fetch(
          `https://vidapet.tech/vaccines.php?petId=${pet.petId}`
        );
        const vaccinesData = await vaccinesRes.json();
        setAppliedVaccines(vaccinesData);

        // Eventos de saúde
        const eventsRes = await fetch(
          `https://vidapet.tech/api/health_events.php?petId=${pet.petId}`
        );
        const eventsData = await eventsRes.json();
        setHealthEvents(eventsData);
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
      }
    };
    fetchData();
  }, [pet]);

  // ---------------- FUNÇÕES AUXILIARES ----------------

  // Recebe nova tag do componente TQRCode_Association
  const handleAssociationUpdate = (newCode) => {
    console.log("Nova tag associada recebida do TQRCode_Association:", newCode);
    setTagCode(newCode);
  };

  // Gera snapshot da carteirinha
  const handleSnapshotCarteirinha = async () => {
    try {
      setLoading(true);
      // pequeno delay para renderizar
      await new Promise((resolve) => setTimeout(resolve, 300));

      const element = document.getElementById('carteirinha-hero-area');
      const dataUrl = await domtoimage.toPng(element);
      setSnapshotUrl(dataUrl);
      setShowSnapshotOverlay(true);
    } catch (err) {
      console.error('Erro ao gerar snapshot da carteirinha:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSnapshot = () => {
    setShowSnapshotOverlay(false);
    setSnapshotUrl(null);
  };

  // Compartilhar snapshot via Web Share API
  const handleShareSnapshot = async () => {
    if (!snapshotUrl) return;
    if (!navigator.canShare) {
      alert('Seu dispositivo/navegador não suporta compartilhamento de arquivos.');
      return;
    }
    try {
      const resp = await fetch(snapshotUrl);
      const blob = await resp.blob();
      const fileName = 'CarteirinhaVidaPet.png';
      const filesArray = [new File([blob], fileName, { type: 'image/png' })];
      if (navigator.canShare({ files: filesArray })) {
        await navigator.share({
          files: filesArray,
          title: 'Carteirinha VidaPet',
          text: 'Olha a carteirinha do meu pet!'
        });
      } else {
        alert('Não foi possível compartilhar essa carteirinha.');
      }
    } catch (err) {
      console.error('Erro ao compartilhar:', err);
    }
  };

  // Compartilhar link com o veterinário
  const handleShareWithVet = async () => {
    try {
      const shareLink = `http://vidapet.tech/tagpage/${tagCode}`;
      await navigator.clipboard.writeText(shareLink);
      alert('Link copiado para a área de transferência!');
      console.log('Link gerado e copiado:', shareLink);
    } catch (error) {
      console.error('Erro ao copiar o link:', error);
      alert('Falha ao copiar o link. Por favor, tente novamente.');
    }
  };

  // Salvar informações do tutor
  const handleSaveTutorInfo = async () => {
    try {
      const response = await fetch('https://vidapet.tech/api_carteira_pet.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          petId: pet.petId || pet.id,
          nomePet: pet.petName,
          tipo: pet.Type || "",
          raca: pet.breed || "",
          tutorUserId: pet.userId || "",
          observacao01: nomeTutor,
          observacao02: telefoneTutor,
          observacao03: ""
        }),
      });
      if (!response.ok) {
        const responseData = await response.json();
        throw new Error(`Erro da API: ${responseData.error}`);
      }
      alert('Informações salvas com sucesso!');

      // Atualiza os dados após salvar
      const updatedRes = await fetch(
        `https://vidapet.tech/api_carteira_pet.php?petId=${pet.petId || pet.id}`
      );
      const updatedData = await updatedRes.json();
      setNomeTutor(updatedData.observacao01 || 'Tutor');
      setTelefoneTutor(updatedData.observacao02 || 'Telefone');
      setEditMode(false);
    } catch (error) {
      console.error('Erro ao salvar informações do tutor:', error);
    }
  };

  // Cancelar adoção
  const handleCancelAdoption = async () => {
    if (window.confirm("Tem certeza de que deseja cancelar a adoção?")) {
      try {
        const cancelRes = await fetch(
          'https://vidapet.tech/api/db/vidapettech/adoption/adoption_user_pet.php',
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ petId: pet.petId || pet.id, action: 'delete' })
          }
        );
        const cancelData = await cancelRes.json();
        if (!cancelRes.ok || !cancelData.success) {
          throw new Error(cancelData.message || 'Erro ao cancelar adoção');
        }
        alert('Adoção cancelada com sucesso');
        setIsInAdoptionProcess(false);
      } catch (error) {
        console.error('Erro ao cancelar adoção:', error);
        alert('Erro ao cancelar adoção');
      }
    }
  };

  // Exemplo de clique em uma vacina
  const handleVaccineClick = (vaccine) => {
    alert(`Vacina selecionada: ${vaccine.name}`);
    // ou abrir modal VaccineModal se preferir
  };

  // ---------------- RENDER ----------------
  return (
    <div className="hero-modal-background">
      <div className="hero-modal-content">
        {/* Cabeçalho */}
        <div className="hero-header">
          <div className="hero-logo-container">
            <img src={petIcon} alt="Vida Pet" className="hero-logo" />
            <h2 className="hero-title">VidaPet.Tech</h2>
          </div>
          <IoMdCloseCircle className="hero-close-button" onClick={onClose} />
        </div>

        {/* Área principal que será “printada” */}
        <div id="carteirinha-hero-area" className="hero-print-area">
          {/* Imagem grande no topo (retangular) */}
          <div className="hero-image-container">
            <img
              src={petImage}
              alt={pet.petName}
              className="hero-pet-image"
            />
            {/* Overlay com nome, raça, idade */}
            <div className="hero-overlay">
              <h3 className="hero-pet-name">{pet.petName}</h3>
              <p className="hero-pet-info">
                {pet.breed} • {pet.age} anos
              </p>
            </div>
          </div>

          {/* Tutor e telefone + edição */}
          <div className="hero-tutor-section">
            <div className="hero-tutor-item">
              <span className="hero-label">Tutor:</span>
              {editMode ? (
                <input
                  type="text"
                  value={nomeTutor}
                  onChange={(e) => setNomeTutor(e.target.value)}
                  className="hero-input"
                />
              ) : (
                <span className="hero-value">{nomeTutor}</span>
              )}
            </div>
            <div className="hero-tutor-item">
              <span className="hero-label">Telefone:</span>
              {editMode ? (
                <input
                  type="text"
                  value={telefoneTutor}
                  onChange={(e) => setTelefoneTutor(e.target.value)}
                  className="hero-input"
                />
              ) : (
                <span className="hero-value">{telefoneTutor}</span>
              )}
            </div>
            <button
              className="hero-edit-button"
              onClick={editMode ? handleSaveTutorInfo : () => setEditMode(true)}
            >
              {editMode ? <FaSave /> : <FaEdit />} {editMode ? "Salvar" : "Editar"}
            </button>
          </div>

          {/* QR Code + TQRCode_Association */}
          <div className="hero-qrcode-section">
            {tagCode && (
              <div className="hero-qrcode-container">
                <QRCodeCanvas value={`http://localhost:3002/tagpage/${tagCode}`} size={80} />
              </div>
            )}
            {/* Aqui garantimos que o petId seja passado para o TQRCode_Association */}
            <TQRCode_Association
              initialCodigo={pet.tagCode || ""}
              petId={pet.petId || pet.id}
              tutorId={pet.userId || null}
              onAssociationSuccess={handleAssociationUpdate}
            />
            <button className="hero-share-vet-button" onClick={handleShareWithVet}>
              <FaShareAlt />
              <span>Liberar Acesso ao Vet</span>
            </button>
          </div>

          {/* Eventos de saúde */}
          {healthEvents.length > 0 && (
            <div className="hero-health-events">
              <h3>Eventos de Saúde</h3>
              {healthEvents.map((event) => (
                <div className="hero-event-item" key={event.id}>
                  <div className="hero-event-details">
                    <strong>{event.eventType}</strong>
                    <span>{event.userDate}</span>
                    <span>{event.eventDescription}</span>
                  </div>
                </div>
              ))}
            </div>
          )}

          {/* Vacinas aplicadas */}
          {appliedVaccines.length > 0 && (
            <div className="hero-vaccines">
              <h3>Vacinas Aplicadas</h3>
              <div className="hero-vaccine-grid">
                {appliedVaccines.map((vaccine) => (
                  <div
                    className="hero-vaccine-icon"
                    key={vaccine.id}
                    onClick={() => handleVaccineClick(vaccine)}
                  >
                    <FaEdit />
                    <p>{vaccine.name}</p>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        {/* Botões de ação */}
        <div className="hero-action-buttons">
          <button onClick={() => setShowHealthEventsModal(true)} className="hero-action-btn">
            <IoMdHeart />
            <span>Saúde</span>
          </button>
          <button onClick={handleSnapshotCarteirinha} className="hero-action-btn">
            <IoMdPrint />
            <span>Imagem</span>
          </button>
          {isInAdoptionProcess ? (
            <button onClick={handleCancelAdoption} className="hero-action-btn">
              <IoMdPeople />
              <span>Cancelar</span>
            </button>
          ) : (
            <button onClick={() => setShowAdoptMeModal(true)} className="hero-action-btn">
              <IoMdPeople />
              <span>Doar</span>
            </button>
          )}
        </div>

        {loading && <p className="hero-loading">Gerando imagem...</p>}
      </div>

      {/* Snapshot overlay */}
      {showSnapshotOverlay && snapshotUrl && (
        <div className="hero-snapshot-overlay">
          <div className="hero-snapshot-content">
            <button className="hero-snapshot-close" onClick={handleCloseSnapshot}>
              <FaTimes />
            </button>
            <h3>Carteirinha Gerada</h3>
            <img
              src={snapshotUrl}
              alt="Carteirinha do Pet"
              style={{ maxWidth: '100%', borderRadius: '8px' }}
            />
            <div className="hero-snapshot-actions">
              <a
                href={snapshotUrl}
                download="CarteirinhaVidaPet.png"
                className="hero-snapshot-download"
              >
                <FaDownload /> Salvar
              </a>
              <button onClick={handleShareSnapshot} className="hero-snapshot-share">
                <FaShareAlt /> Compartilhar
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Modais secundários */}
      {showHealthEventsModal && (
        <HealthEventsModal
          petId={pet.petId || pet.id}  // Passa o petId para o modal
          pet={pet}
          onClose={() => setShowHealthEventsModal(false)}
        />
      )}

      {showVaccineModal && (
        <VaccineModal
          pet={pet}
          vaccine={selectedVaccine}
          onClose={() => setShowVaccineModal(false)}
        />
      )}

      {showAdoptMeModal && (
        <AdoptMe
          pet={pet}
          onClose={() => setShowAdoptMeModal(false)}
        />
      )}
    </div>
  );
};

export default Carteirinha;
