import React, { useState, useEffect, useCallback } from 'react';
import { FaArrowCircleRight } from 'react-icons/fa';
import { FaCamera, FaInfoCircle, FaShareAlt } from 'react-icons/fa';
import { BsQrCodeScan } from "react-icons/bs";
import { FaMapMarkerAlt } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

// Importação dos componentes de visualização/alerta
import WebcamCapture from './Components/camera';
import RescueView from '../Resgate/RescueView';
import AdoptionView from './AdoptionView';
import PetLostForm from './Components/PetLostForm';
import PetAlertForm from './PetAlertForm';
import QrCode from './QrCode/QrCode';

function RescueDashboard() {
  const [activeComponent, setActiveComponent] = useState('rescueCases');
  const [showPopup, setShowPopup] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const petId = queryParams.get('qrcode');
  const navigate = useNavigate();

  useEffect(() => {
    if (petId) {
      setActiveComponent('qrCode');
    }
  }, [petId]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPopup(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  const renderContent = () => {
    switch (activeComponent) {
      case 'rescueCases':
        return <RescueView />;
      case 'adoptionCases':
        return <AdoptionView />;
      case 'qrCode':
        return <QrCode petId={petId} />;
      case 'webcam':
        return <WebcamCapture closeWebcam={() => setActiveComponent('rescueCases')} />;
      case 'petLostForm':
        return <PetLostForm />;
      case 'petAlertForm':
        return <PetAlertForm closeForm={() => setActiveComponent('rescueCases')} />;
      default:
        return <RescueView />;
    }
  };

  return (
    <DashboardContainer>
      <Navbar>
        {/* Primeiro botão: Criar Alerta de Pet */}
        <SidebarButton onClick={() => setActiveComponent('petAlertForm')}>
          Criar Alerta de Pet
        </SidebarButton>

        {/* Botão QR Code */}
        <SidebarButton1 onClick={() => setActiveComponent('qrCode')}>
          <IconWrapper><BsQrCodeScan /></IconWrapper>
          QR Code
        </SidebarButton1>

        {/* Botão para alternar entre Resgate e Adoção */}
        <SidebarButton1
          onClick={() =>
            setActiveComponent(activeComponent === 'rescueCases' ? 'adoptionCases' : 'rescueCases')
          }
        >
          <IconWrapper><FaMapMarkerAlt /></IconWrapper>
          {activeComponent === 'rescueCases' ? 'Adote' : 'Resgate'}
        </SidebarButton1>
      </Navbar>

      <ContentContainer>
        {renderContent()}
      </ContentContainer>

      {showPopup && (
        <MobilePopup>
          <h4>🛑 Atenção! 🛑</h4>
          <ol>
            <StepItem>
              <FaMapMarkerAlt className="location-icon" />
              <li>Marque ou preencha a localização do pet.</li>
            </StepItem>
            <StepItem>
              <FaCamera className="photo-icon" />
              <li>Capture ou suba uma foto do pet no botão "Criar Alerta de Pet".</li>
            </StepItem>
            <StepItem>
              <FaInfoCircle className="info-icon" />
              <li>Inclua informações adicionais para facilitar a busca.</li>
            </StepItem>
            <StepItem>
              <FaShareAlt className="share-icon" />
              <li>Compartilhe o caso nas redes sociais.</li>
            </StepItem>
          </ol>
          <CloseButton onClick={() => setShowPopup(false)}>Entendi</CloseButton>
        </MobilePopup>
      )}
    </DashboardContainer>
  );
}

// Animação para bordas piscantes (exemplo)
const blinkingBorder = keyframes`
  0% { border: 2px solid #ff6f61; }
  50% { border: 2px solid #fff; }
  100% { border: 2px solid #ff6f61; }
`;

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #f7f9fc;
`;

const Navbar = styled.div`
  display: flex;
  background-color: #e3f2fd;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
`;

/* Botão para "Criar Alerta de Pet" - usamos um fundo chamativo */
const SidebarButton = styled.button`
  background: #ff5722;
  color: #ffffff;
  height: 60px;
  margin: 10px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  transition: background 0.3s, transform 0.2s, box-shadow 0.2s;

  &:hover {
    background: #ff7043;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
`;

const SidebarButton1 = styled.button`
  background: #1e88e5;
  color: #ffffff;
  height: 60px;
  margin: 10px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  transition: background 0.3s, transform 0.2s, box-shadow 0.2s;

  &:hover {
    background: #1976d2;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
`;

const IconWrapper = styled.div`
  margin-right: 10px;
  font-size: 20px;
`;

const ContentContainer = styled.div`
  flex-grow: 1;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  font-family: 'Roboto', sans-serif;
  margin-top: 10px;
`;

const MobilePopup = styled.div`
  position: fixed;
  bottom: 100px;
  left: 40%;
  transform: translateX(-50%);
  background-color: #fff;
  color: #333;
  padding: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  max-width: 90%;
  z-index: 3000;

  h4 {
    color: #ff9800;
  }

  ol {
    margin: 10px 0;
  }

  .photo-icon {
    color: #ff9800;
  }
  .location-icon {
    color: #1e88e5;
  }
  .upload-icon {
    color: #43a047;
  }
  .info-icon {
    color: #f44336;
  }
  .share-icon {
    color: #ab47bc;
  }
`;

const StepItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;

  li {
    margin-left: 15px;
    list-style: none;
    font-size: 0.85em;
  }

  svg {
    font-size: 2rem;
    min-width: 30px;
  }
`;

const CloseButton = styled.button`
  background-color: #1e88e5;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;

  &:hover {
    background-color: #1565c0;
  }
`;

export default RescueDashboard;
