import React, { useState, useEffect } from 'react';
import { FaWhatsapp, FaCheck } from 'react-icons/fa';
import DoacaoPixQRCode from '../../../ProgDoacao/DoacaoPixQRCode'; // Ajuste o caminho conforme sua estrutura
import ProgramaDoacaoFundoResgate from '../../../ProgDoacao/ProgramaDoacaoFundoResgate'; // Importa o componente do Fundo
import './PlanosPromocao.css';

const PlanosPromocao = () => {
  const [faqOpen, setFaqOpen] = useState(null);
  const [doadores, setDoadores] = useState(18); // Valor inicial simulado
  const totalVagas = 1000;
  const [showDonationModal, setShowDonationModal] = useState(false);
  const [showFundModal, setShowFundModal] = useState(false);

  useEffect(() => {
    // Simulação de aumento de doadores (substitua por chamada à API se necessário)
    const interval = setInterval(() => {
      setDoadores((prev) =>
        prev < totalVagas ? prev + Math.floor(Math.random() * 3) : prev
      );
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  const vagasRestantes = totalVagas - doadores;

  const toggleFaq = (index) => {
    setFaqOpen(faqOpen === index ? null : index);
  };

  const handleFreePlanClick = () => {
    alert('Basta fazer o login/cadastro básico no canto superior da tela');
  };

  return (
    <div className="planos-page">
      <h1 className="planos-title">🚀 Promoção Especial VidaPet.Tech</h1>

      {/* Contador e informações iniciais */}
      <p className="planos-description">
        <span className="riscado">1000 primeiros doadores</span> já estão garantindo suas tags!  
        🎉 <strong>{doadores} pessoas já aderiram</strong> e <strong>restam apenas {vagasRestantes} vagas!</strong>
      </p>

      {/* Cards dos Planos */}
      <div className="planos-container">
        {/* Plano Free */}
        <div className="plano-card free">
          <h2>🆓 Plano Free</h2>
          <p><strong>Totalmente gratuito!</strong></p>
          <ul>
            <li>
              <FaCheck className="check-icon" /> Carteirinha digital para seu pet
            </li>
            <li>
              <FaCheck className="check-icon" /> Acesso às teleconsultas veterinárias básicas
            </li>
            <li>
              <FaCheck className="check-icon" /> Descontos exclusivos na rede de parceiros
            </li>
            <li>
              <FaCheck className="check-icon" /> Controle digital das vacinas e histórico do seu pet
            </li>
          </ul>
          <button className="plano-button" onClick={handleFreePlanClick}>
            Ativar Grátis
          </button>
        </div>

        {/* Plano Confort */}
        <div className="plano-card destaque">
          <h2>🔥 Plano Confort</h2>
          <p><strong>R$ 50,00/ano</strong></p>
          <ul>
            <li>
              <FaCheck className="check-icon" /> Carteirinha digital para seu pet
            </li>
            <li>
              <FaCheck className="check-icon" /> 1 Teleconsulta Veterinária Orientativa para dúvidas essenciais
            </li>
            <li>
              <FaCheck className="check-icon" /> Descontos exclusivos na rede de parceiros
            </li>
            <li>
              <FaCheck className="check-icon" /> Tag QR-Code VidaPet.Tech incluída
            </li>
            <li>
              <FaCheck className="check-icon" /> Alerta enviado ao tutor e acesso aos dados de contato pelo leitor
            </li>
            <li>
              <FaCheck className="check-icon" /> Possibilidade de adquirir quantas tags quiser
            </li>
            <li>
              <FaCheck className="check-icon" /> Proteção e lembretes para vacinas e consultas
            </li>
          </ul>
          <button className="plano-button" onClick={() => setShowDonationModal(true)}>
            Escolher Plano
          </button>
        </div>

        {/* Plano Plus */}
        <div className="plano-card plus">
          <h2>💎 Plano Plus</h2>
          <p><strong>R$ 100,00/ano</strong></p>
          <ul>
            <li>
              <FaCheck className="check-icon" /> Carteirinha digital para seu pet
            </li>
            <li>
              <FaCheck className="check-icon" /> 3 Teleconsultas Veterinárias: orientativa, acompanhamento e emergência
            </li>
            <li>
              <FaCheck className="check-icon" /> Descontos exclusivos na rede de parceiros
            </li>
            <li>
              <FaCheck className="check-icon" /> Tag QR-Code VidaPet.Tech incluída
            </li>
            <li>
              <FaCheck className="check-icon" /> Alerta com geolocalização para o tutor e chat integrado para o leitor
            </li>
            <li>
              <FaCheck className="check-icon" /> Possibilidade de adquirir quantas tags quiser
            </li>
            <li>
              <FaCheck className="check-icon" /> Monitoramento completo da saúde do seu pet
            </li>
          </ul>
          <button className="plano-button" onClick={() => setShowDonationModal(true)}>
            Escolher Plano
          </button>
        </div>
      </div>

      {/* Seção informativa sobre planos e Fundo de Resgate */}
      <div className="info-section">
  <p>
    ✨ <strong>Benefícios Exclusivos:</strong>
  </p>
  <ul>
    <li>🎥 Acesso à <strong>teleconsulta veterinária</strong></li>
    <li>🏷️ 1 Tag QR-Code gratuita <span>(e opção de adquirir mais)</span></li>
    <li>💰 Descontos especiais</li>
    <li>📲 Armazenamento digital completo da saúde do seu pet</li>
  </ul>
  <p>
    🐾 <strong>Impacto Social:</strong> Parte de cada doação é direcionada para o <strong>Fundo de Resgate</strong>, que financia intervenções imediatas para pets abandonados.
  </p>
  <button className="fund-button" onClick={() => setShowFundModal(true)}>
    👉 Conheça o Fundo de Resgate!
  </button>
</div>


      {/* FAQ */}
      <div className="faq-section">
        <h2>📌 Perguntas & Respostas</h2>

        <div className="faq-item" onClick={() => toggleFaq(1)}>
          <h3>❓ Como funciona a promoção?</h3>
          {faqOpen === 1 && (
            <p>
              Ao doar, você obtém durante um ano um plano repleto de benefícios. Você recebe uma tag QR-Code gratuita para seu pet e tem a possibilidade de adquirir tags adicionais com descontos exclusivos. Seu plano inclui teleconsultas veterinárias, acesso à carteirinha digital (que pode ser compartilhada com qualquer profissional através de um link dedicado) e o armazenamento digital completo das informações do seu pet, garantindo que você nunca perca uma vacina ou consulta importante.
            </p>
          )}
        </div>

        <div className="faq-item" onClick={() => toggleFaq(2)}>
          <h3>❓ O que é a Teleconsulta Veterinária?</h3>
          {faqOpen === 2 && (
            <p>
              A teleconsulta permite que você consulte um veterinário para orientações, diagnósticos e acompanhamentos, sem sair de casa. No Plano Confort, você tem direito a 1 teleconsulta por ano, enquanto no Plano Plus, pode contar com até 3 teleconsultas, incluindo uma de emergência.
            </p>
          )}
        </div>

        <div className="faq-item" onClick={() => toggleFaq(3)}>
          <h3>❓ Como funciona a Carteirinha Digital?</h3>
          {faqOpen === 3 && (
            <p>
              A carteirinha digital do VidaPet.Tech armazena todas as informações do seu pet – histórico de vacinas, consultas, exames e muito mais. Ela pode ser acessada por qualquer veterinário ou clínica através de um link dedicado, facilitando o atendimento e o registro dos eventos de saúde do seu pet.
            </p>
          )}
        </div>

        <div className="faq-item" onClick={() => toggleFaq(4)}>
  <h3>❓ E se meu veterinário não estiver cadastrado no VidaPet.Tech?</h3>
  {faqOpen === 4 && (
    <p>
      Sim, claro! Basta você compartilhar seu <strong>link dedicado</strong> da carteirinha digital, e seu veterinário ou profissional poderá inserir dados e registrar eventos de saúde, como recibos de vacinas e resultados de exames.
      <br /><br />
      Além disso, se o seu veterinário desejar, ele pode se cadastrar gratuitamente na plataforma e até realizar videochamadas para atendê-lo, garantindo um acompanhamento completo e o melhor cuidado para o seu pet.
    </p>
  )}
</div>

        <div className="faq-item" onClick={() => toggleFaq(5)}>
          <h3>❓ Posso adquirir mais Tags QR-Code?</h3>
          {faqOpen === 5 && (
            <p>
              Sim! Todos os planos já incluem uma tag gratuita, mas você pode adquirir quantas tags desejar para ampliar a proteção do seu pet ou registrar outros pets, aproveitando os descontos exclusivos da rede de parceiros.
            </p>
          )}
        </div>
      </div>

      {/* WhatsApp */}
      <div
        className="whatsapp-float"
        onClick={() => window.open('https://wa.me/5521982992635', '_blank')}
      >
        <FaWhatsapp size={40} color="#25D366" />
        <span>Fale com a gente!</span>
      </div>

      {/* Botão Call-to-Action */}
      <button className="cta-button" onClick={() => setShowDonationModal(true)}>
        🎯 Quero Garantir Minha Tag QR-Code!
      </button>

      {/* Modal de Doação (overlay fixo) */}
      {showDonationModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button className="close-modal" onClick={() => setShowDonationModal(false)}>
              Fechar
            </button>
            <DoacaoPixQRCode onClose={() => setShowDonationModal(false)} />
          </div>
        </div>
      )}

      {/* Modal do Fundo de Resgate */}
      {showFundModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button className="close-modal" onClick={() => setShowFundModal(false)}>
              Fechar
            </button>
            <ProgramaDoacaoFundoResgate onClose={() => setShowFundModal(false)} />
          </div>
        </div>
      )}
    </div>
  );
};

export default PlanosPromocao;
