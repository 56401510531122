import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import './ConveniosList.css';
import ConvenioPayments from './ConvenioPayments'; // Componente de pagamento
import ConveniosInfo from './ConveniosInfo';       // Componente de detalhes do convênio
import { getUserIdFromFirebaseId } from '../../../Login/userService'; 
import petIcon from '../../../../imagens/MascoteLiliVidaPetTech.png'; // Fallback de ícone

const ConveniosList = () => {
  const [convenios, setConvenios] = useState([]); // Convênios disponíveis
  const [userConvenios, setUserConvenios] = useState([]); // Convênios contratados
  const [selectedConvenio, setSelectedConvenio] = useState(null);
  const [showConvenioModal, setShowConvenioModal] = useState(false);

  // Modal de Pagamento
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [selectedModalidade, setSelectedModalidade] = useState(null);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // userId e petId do usuário
  const [userId, setUserId] = useState(null);
  const [petId, setPetId] = useState(0); // Ajuste conforme sua lógica real

  // 1) Obtém userId via firebaseId
  const obtainUserId = useCallback(async () => {
    try {
      const firebaseId = localStorage.getItem('firebaseId');
      if (firebaseId) {
        const fetchedUserId = await getUserIdFromFirebaseId(firebaseId);
        if (fetchedUserId && fetchedUserId > 0) {
          setUserId(fetchedUserId);
        } else {
          throw new Error('User ID inválido retornado.');
        }
      } else {
        throw new Error('firebaseId não encontrado no localStorage.');
      }
    } catch (err) {
      setError('Usuário não autenticado ou inválido.');
      setLoading(false);
    }
  }, []);

  // 2) Busca convênios disponíveis
  const fetchConvenios = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        'https://vidapet.tech/api/DB/vidapettech/serv_Apis/Convenios/get_convenios.php'
      );
      if (response.data.status === 'success' && Array.isArray(response.data.convenios)) {
        setConvenios(response.data.convenios);
      } else {
        throw new Error(response.data.message || 'Erro ao carregar convênios');
      }
    } catch (err) {
      setError('Não foi possível carregar os convênios. Tente novamente mais tarde.');
      console.error('Erro ao carregar convênios:', err);
    } finally {
      setLoading(false);
    }
  }, []);

  // 3) Busca convênios contratados pelo usuário
  const fetchUserConvenios = useCallback(async () => {
    if (!userId || userId <= 0) {
      setError('Usuário não autenticado ou inválido.');
      setLoading(false);
      return;
    }
    try {
      setLoading(true);
      const response = await axios.get(
        `https://vidapet.tech/api/DB/vidapettech/serv_Apis/Convenios/get_user_convenios.php?userId=${userId}`
      );
      if (response.data.status === 'success' && Array.isArray(response.data.convenios)) {
        setUserConvenios(response.data.convenios);
      } else {
        throw new Error(response.data.message || 'Erro ao carregar convênios do usuário');
      }
    } catch (err) {
      setError('Não foi possível carregar seus convênios. Tente novamente mais tarde.');
      console.error('Erro ao carregar convênios do usuário:', err);
    } finally {
      setLoading(false);
    }
  }, [userId]);

  // Ao montar
  useEffect(() => {
    obtainUserId();
  }, [obtainUserId]);

  // Depois que temos userId
  useEffect(() => {
    if (userId && userId > 0) {
      fetchConvenios();
      fetchUserConvenios();
    }
  }, [fetchConvenios, fetchUserConvenios, userId]);

  // Modal de detalhes do convênio (disponíveis)
  const handleConvenioClick = (convenio) => {
    setSelectedConvenio(convenio);
    setShowConvenioModal(true);
  };
  const handleCloseConvenioModal = () => {
    setShowConvenioModal(false);
    setSelectedConvenio(null);
  };

  // Contratar Modalidade
  const handleContratarModalidade = (modalidade) => {
    const alreadyContracted = userConvenios.some(
      (c) => c.contrato_id === selectedConvenio.contrato_id
    );
    if (alreadyContracted) {
      alert('Seu Pet Já possui esse plano contratado!');
      return;
    }
    setSelectedModalidade(modalidade);
    setShowConvenioModal(false);
    setShowPaymentModal(true);
  };
  const handleClosePaymentModal = () => {
    setShowPaymentModal(false);
    setSelectedModalidade(null);
  };

  // Modal de detalhes dos convênios contratados
  const handleUserConvenioClick = (convenio) => {
    setSelectedConvenio(convenio);
    setShowConvenioModal(false);
    setShowPaymentModal(false);
  };
  const handleCloseUserConvenioInfo = () => {
    setSelectedConvenio(null);
  };

  if (loading) {
    return <div className="loading">Carregando...</div>;
  }
  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="convenios-list">

      {/* Convênios Disponíveis */}
      <section>
        <h2>Convênios Disponíveis</h2>
        <div className="grid">
          {convenios.map((convenio) => (
            <div
              key={convenio.id}
              className="convenio-card"
              onClick={() => handleConvenioClick(convenio)}
            >
              <h3>{convenio.nome}</h3>
              <p>{convenio.descricao_curta}</p>
            </div>
          ))}
        </div>
      </section>

      {/* Meus Convênios */}
      <section>
        <h2>Meus Convênios</h2>
        {userConvenios.length > 0 ? (
          <div className="user-convenios-grid">
            {userConvenios.map((convenio) => {
              const petImageUrl = convenio.pet_imagem
                ? `https://vidapet.tech/vidapet/pets/${convenio.pet_imagem}?t=${new Date().getTime()}`
                : petIcon;
              return (
                <div
                  key={convenio.contrato_id}
                  className="convenio-card user-convenio-card"
                  onClick={() => handleUserConvenioClick(convenio)}
                >
                  <div className="pet-thumb">
                    <img src={petImageUrl} alt={convenio.pet_nome || 'Pet'} />
                  </div>
                  <h3>{convenio.convenio_nome}</h3>
                  <p>Pet: {convenio.pet_nome}</p>
                  <p>Status: {convenio.status}</p>
                  <p>
                    Próxima Mensalidade:{' '}
                    {convenio.data_proximo_vencimento
                      ? new Date(convenio.data_proximo_vencimento).toLocaleDateString()
                      : '-'}
                  </p>
                </div>
              );
            })}
          </div>
        ) : (
          <p>Você ainda não contratou nenhum convênio.</p>
        )}
      </section>

      {/* Modal de Detalhes do Convênio (disponíveis) */}
      {showConvenioModal && selectedConvenio && (
        <div className="modal-overlay">
          <div className="modal-content">
            <div className="modal-header">
              <h2>{selectedConvenio.nome}</h2>
              <button onClick={handleCloseConvenioModal} className="modal-close-btn">
                X
              </button>
            </div>
            <div className="modal-body">
              <p>{selectedConvenio.descricao_completa}</p>
              {selectedConvenio.documentos && selectedConvenio.documentos.length > 0 && (
                <>
                  <h3>Documentos Disponíveis:</h3>
                  <ul>
                    {selectedConvenio.documentos.map((doc) => (
                      <li key={doc.id}>
                        <a
                          href={doc.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="download-link"
                        >
                          {doc.nome}
                        </a>
                      </li>
                    ))}
                  </ul>
                </>
              )}
              <h3>Modalidades Disponíveis:</h3>
              <div className="modalidades-container">
                {selectedConvenio.modalidades && selectedConvenio.modalidades.length > 0 ? (
                  selectedConvenio.modalidades.map((modalidade) => {
                    const alreadyContracted = userConvenios.some(
                      (c) => c.contrato_id === selectedConvenio.contrato_id && c.pet_id === petId
                    );
                    return (
                      <div className="modalidade-item" key={modalidade.id}>
                        <h4>{modalidade.nome}</h4>
                        <p>Valor: R$ {modalidade.valor}</p>
                        {alreadyContracted ? (
                          <button
                            className="btn-contratar"
                            onClick={() => alert('Seu Pet Já possui esse plano contratado!')}
                            disabled
                          >
                            Já Contratado
                          </button>
                        ) : (
                          <button
                            className="btn-contratar"
                            onClick={() => handleContratarModalidade(modalidade)}
                          >
                            Contratar
                          </button>
                        )}
                      </div>
                    );
                  })
                ) : (
                  <p>Não há modalidades cadastradas.</p>
                )}
              </div>
            </div>
            <div className="modal-footer">
              <button className="btn-close" onClick={handleCloseConvenioModal}>
                Fechar
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Modal de detalhes dos convênios contratados */}
      {selectedConvenio && userConvenios.some(c => c.contrato_id === selectedConvenio.contrato_id) && (
        <ConveniosInfo convenio={selectedConvenio} onClose={handleCloseUserConvenioInfo} />
      )}

      {/* Modal de pagamento */}
      {showPaymentModal && selectedModalidade && selectedConvenio && (
        <div className="modal-overlay">
          <div className="modal-content">
            <ConvenioPayments
              userId={userId}
              petId={petId}
              convenioId={selectedConvenio.id}
              modalidadeId={selectedModalidade.id}
              valor={selectedModalidade.valor}
              onClose={handleClosePaymentModal}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ConveniosList;
