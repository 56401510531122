import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { IoMdCloseCircle } from 'react-icons/io';

const AdoptMe = ({ pet, onClose }) => {
  const [reason, setReason] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [neighborhood, setNeighborhood] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  // 1. Buscar a lista de estados (IBGE)
  useEffect(() => {
    const fetchStates = async () => {
      try {
        const response = await axios.get(
          'https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome'
        );
        console.log('Estados carregados:', response.data);
        setStates(response.data);
      } catch (error) {
        console.error('Erro ao carregar estados:', error);
      }
    };
    fetchStates();
  }, []);

  // 2. Quando usuário seleciona um estado, buscar as cidades do IBGE
  const handleStateChange = async (e) => {
    const selectedState = e.target.value;
    setState(selectedState);
    setCity(''); // Limpar cidade ao mudar estado

    console.log('Estado selecionado:', selectedState);

    try {
      const response = await axios.get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${selectedState}/municipios`
      );
      console.log('Cidades carregadas:', response.data);
      setCities(response.data);
    } catch (error) {
      console.error('Erro ao carregar cidades:', error);
    }
  };

  // 3. Submeter o formulário para colocar o pet em adoção
  const handleSubmit = async () => {
    if (!reason.trim() || !state || !city || !neighborhood.trim()) {
      alert('Por favor, preencha todos os campos.');
      return;
    }

    setIsSubmitting(true);

    // Lógica para capturar a imagem do pet
    const petImage = pet.pet_3x4
      ? `https://vidapet.tech/vidapet/pets/${pet.pet_3x4.split('/').pop()}`
      : 'https://via.placeholder.com/300x200?text=Sem+Imagem'; // Caminho padrão

    console.log('Caminho completo da foto do pet:', petImage);

    const payload = {
      pet_id: pet.petId, // Ajustando para pet_id
      title: 'Adoção Pet',
      comments: 'Nenhum comentário adicional.',
      reason,
      state,
      city,
      neighborhood,
      country: 'Brasil',
      image_path: petImage,
    };

    console.log('Dados enviados para a API:', payload);

    try {
      const response = await axios.post(
        'https://vidapet.tech/api/DB/vidapettech/adoption/AdoptionCase.php?action=insert',
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      console.log('Resposta da API:', response.data);

      if (response.status === 200 && response.data.success) {
        alert('Pet colocado para adoção com sucesso!');
        onClose();
      } else {
        alert(
          'Houve um problema ao tentar colocar o pet para adoção: ' +
            response.data.message
        );
      }
    } catch (error) {
      console.error('Erro ao enviar dados:', error);
      alert('Erro ao enviar dados. Tente novamente.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <ModalBackground>
      <ModalContent>
        <Header>
          <Title>Colocar {pet.petName} para Adoção</Title>
          <CloseButton onClick={onClose} />
        </Header>

        <Form>
          <Label>Motivo da Adoção:</Label>
          <SmallTextArea
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            placeholder="Descreva o motivo..."
          />

          <Label>Estado:</Label>
          <Select value={state} onChange={handleStateChange}>
            <option value="">Selecione o estado</option>
            {states.map((estado) => (
              <option key={estado.id} value={estado.sigla}>
                {estado.nome}
              </option>
            ))}
          </Select>

          <Label>Cidade:</Label>
          <Select
            value={city}
            onChange={(e) => setCity(e.target.value)}
            disabled={!state}
          >
            <option value="">Selecione a cidade</option>
            {cities.map((cidade) => (
              <option key={cidade.id} value={cidade.nome}>
                {cidade.nome}
              </option>
            ))}
          </Select>

          <Label>Bairro:</Label>
          <Input
            value={neighborhood}
            onChange={(e) => setNeighborhood(e.target.value)}
            placeholder="Informe o bairro..."
          />

          <ButtonContainer>
            <Button onClick={onClose} disabled={isSubmitting}>
              Cancelar
            </Button>
            <Button primary onClick={handleSubmit} disabled={isSubmitting}>
              {isSubmitting ? 'Enviando...' : 'Confirmar Adoção'}
            </Button>
          </ButtonContainer>
        </Form>
      </ModalContent>
    </ModalBackground>
  );
};

/* ---------- STYLED COMPONENTS COM MEDIA QUERIES ---------- */

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: white;
  border-radius: 15px;
  width: 90%;
  max-width: 500px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  max-height: 90vh;
  overflow-y: auto;

  @media (max-width: 400px) {
    padding: 15px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
`;

const Title = styled.h2`
  font-size: 18px;
  color: #333;
  margin: 0;
  @media (max-width: 400px) {
    font-size: 16px;
  }
`;

const CloseButton = styled(IoMdCloseCircle)`
  font-size: 24px;
  cursor: pointer;
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Label = styled.label`
  font-size: 16px;
  margin-bottom: 8px;
  @media (max-width: 400px) {
    font-size: 14px;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 14px;
  margin-bottom: 20px;

  @media (max-width: 400px) {
    font-size: 12px;
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 14px;
  margin-bottom: 20px;

  @media (max-width: 400px) {
    font-size: 12px;
  }
`;

const SmallTextArea = styled.textarea`
  width: 100%;
  height: 60px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 14px;
  resize: none;
  margin-bottom: 20px;

  @media (max-width: 400px) {
    font-size: 12px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
`;

const Button = styled.button`
  background-color: ${(props) => (props.primary ? '#007bff' : '#f5f5f5')};
  color: ${(props) => (props.primary ? 'white' : '#333')};
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  flex: 1;
  text-align: center;

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    background-color: ${(props) => (props.primary ? '#0056b3' : '#e0e0e0')};
  }

  @media (max-width: 400px) {
    font-size: 12px;
  }
`;

export default AdoptMe;
