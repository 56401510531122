import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import PetList from './Components/PetList';
import PetForm from './Components/PetForm';
import VetCall from './Components/VetCalls/VetCall';
import Carteirinha from './PetCard/Carteirinha';
import Resgate from '../Resgate/RescueDashboard';
import UserChat from './Components/Chat/UserChat';
import DashboardHome from './DashboardHome';
import Cartalogo from '../Cartalogo/Cartalogo';
import ConveniosList from '../MarketPlace/Servicos/Convenios/ConveniosList';
import { getUserIdFromFirebaseId } from '../Login/userService';
import axios from 'axios';
import './Dashboard.css';

import {
  RiCloudFill,         // Home
  RiStethoscopeLine,   // Teleconsulta
  RiToolsFill,         // Serviços
  RiAnchorLine         // Convênios
} from "react-icons/ri";
import { GiPawPrint } from "react-icons/gi";

const IconButton = styled.button`
  background: #007bff; // cor azul para o preenchimento
  border-radius: 50%;
  border: 2px solid #ffa500; // borda laranja
  width: 60px; // ajuste de acordo com a necessidade
  height: 60px; // ajuste de acordo com a necessidade
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 5px;

  &:hover {
    background: #0056b3; // uma tonalidade mais escura de azul para o hover
  }

  &.active {
    background: #004080; // uma tonalidade ainda mais escura para o estado ativo
  }
`;

const Dashboard = () => {
  const [userId, setUserId] = useState(null);
  const [userName, setUserName] = useState('');
  const [userPhoto, setUserPhoto] = useState('');
  const [reloadPets, setReloadPets] = useState(false);
  const [showPetForm, setShowPetForm] = useState(false);
  const [activeTab, setActiveTab] = useState('home');
  const [selectedPet, setSelectedPet] = useState(null);
  const [conveniosVisible, setConveniosVisible] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const firebaseId = localStorage.getItem('firebaseId');
        if (firebaseId) {
          const userId = await getUserIdFromFirebaseId(firebaseId);
          if (userId) {
            setUserId(userId);
          } else {
            console.error('Falha ao obter userId do firebaseId');
          }
        } else {
          console.error('firebaseId não encontrado no localStorage');
        }
      } catch (error) {
        console.error('Erro ao verificar a autenticação:', error);
      }
    };
    checkAuthentication();
  }, []);

  useEffect(() => {
    const fetchUserDetails = async () => {
      if (userId) {
        const response = await axios.get(`https://vidapet.tech/sync-user.php/${userId}`);
        setUserName(response.data.name);
        setUserPhoto(response.data.photoURL);
      }
    };

    fetchUserDetails();
  }, [userId]);

  useEffect(() => {
    const fetchConveniosVisibility = async () => {
      try {
        const resp = await axios.get('https://vidapet.tech/api/DB/vidapettech/serv_Apis/toggle_feature.php?feature=convenios');
        if (resp.data.status === 'success') {
          setConveniosVisible(resp.data.feature_status === 'ON');
        }
      } catch (err) {
        console.error('Erro ao buscar visibilidade de "convenios":', err);
        setConveniosVisible(true);
      }
    };
    fetchConveniosVisibility();
  }, []);

  const updatePetList = () => {
    setReloadPets(!reloadPets);
  };

  const handleAddPetClick = () => {
    setShowPetForm(true);
  };

  const handleCloseForm = () => {
    setShowPetForm(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeTab]);

  const handleTabChange = async (tab) => {
    if (tab === 'pets' || tab === 'servicos' || tab === 'convenios') {
      const firebaseId = localStorage.getItem('firebaseId');
      if (!firebaseId) {
        alert(`Para acessar a área de ${tab}, é necessário estar logado.`);
        setActiveTab('home');
      } else {
        if (!userId) {
          try {
            const fetchedUserId = await getUserIdFromFirebaseId(firebaseId);
            if (fetchedUserId) {
              setUserId(fetchedUserId);
              setActiveTab(tab);
            } else {
              alert('Erro ao carregar informações do usuário. Por favor, tente novamente.');
              setActiveTab('home');
            }
          } catch (error) {
            console.error('Erro ao buscar o userId:', error);
            setActiveTab('home');
          }
        } else {
          setActiveTab(tab);
        }
      }
    } else {
      setActiveTab(tab);
    }
  };

  const handleShowPetCard = (pet) => {
    setSelectedPet(pet);
  };

  const handleClosePetCard = () => {
    setSelectedPet(null);
  };

  return (
    <div className="dashboard-container">
      <aside className="dashboard-sidebar">
        <div className="tabs">
          <IconButton onClick={() => handleTabChange('home')} className={activeTab === 'home' ? 'active' : ''}>
            <RiCloudFill size={30} />
          </IconButton>
          <IconButton onClick={() => handleTabChange('pets')} className={activeTab === 'pets' ? 'active' : ''}>
            <GiPawPrint size={30} />
          </IconButton>
          <IconButton onClick={() => handleTabChange('teleconsulta')} className={activeTab === 'teleconsulta' ? 'active' : ''}>
            <RiStethoscopeLine size={30} />
          </IconButton>
          <IconButton onClick={() => handleTabChange('servicos')} className={activeTab === 'servicos' ? 'active' : ''}>
            <RiToolsFill size={30} />
          </IconButton>
          {conveniosVisible && (
            <IconButton onClick={() => handleTabChange('convenios')} className={activeTab === 'convenios' ? 'active' : ''}>
              <RiAnchorLine size={30} />
            </IconButton>
          )}
        </div>
      </aside>

      <main className="dashboard-content">
        {activeTab === 'home' && <DashboardHome onAccess={() => handleTabChange('pets')} />}
        {activeTab === 'pets' && userId ? (
          <PetList userId={userId} reloadPets={reloadPets} onShowPetCard={handleShowPetCard} />
        ) : activeTab === 'pets' && !userId && (
          <p>Você precisa estar logado para visualizar os pets.</p>
        )}
        {activeTab === 'teleconsulta' && <VetCall userId={userId} />}
        {activeTab === 'servicos' && <Cartalogo />}
        {activeTab === 'convenios' && conveniosVisible && <ConveniosList />}
        {activeTab === 'convenios' && !conveniosVisible && (
          <h3>Convênios temporariamente indisponíveis.</h3>
        )}
        {selectedPet && (
          <Carteirinha pet={selectedPet} onClose={handleClosePetCard} userId={userId} />
        )}
      </main>

      <UserChat userId={userId} />
    </div>
  );
};

export default Dashboard;
