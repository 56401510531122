import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import { auth } from "../../../../firebase"; // ajuste conforme sua estrutura
import PetForm from "../../../Dashboard/Components/PetForm";
import { getUserIdFromFirebaseId } from "../../../Login/userService";
import "./TagPage.css";
import TagDetails from "./TagDetails";
import petIcon from "../../../../imagens/MascoteLiliVidaPetTech.png";

const TagPage = () => {
  const { codigo } = useParams();
  const navigate = useNavigate();

  // Estado para o input do código, caso não venha pela URL
  const [inputCode, setInputCode] = useState("");

  // Estados para a resposta da API da tag (QR Code)
  const [apiResponse, setApiResponse] = useState(null);
  const [loadingQR, setLoadingQR] = useState(false);
  const [errorQR, setErrorQR] = useState(null);

  // Estado do usuário
  const [user, setUser] = useState(null);

  // Estados para a lista de pets do usuário
  const [pets, setPets] = useState([]);
  const [isPetsLoaded, setIsPetsLoaded] = useState(false);

  // Estado para exibir o modal de cadastro de novo pet
  const [showPetModal, setShowPetModal] = useState(false);

  // Estado para exibir a mensagem de login
  const [showLoginMessageModal, setShowLoginMessageModal] = useState(false);

  // Autenticação do usuário via Firebase
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      if (currentUser) {
        getUserIdFromFirebaseId(currentUser.uid)
          .then((fetchedUserId) => {
            setUser({ ...currentUser, uid: fetchedUserId });
          })
          .catch((err) => {
            console.error("Erro ao obter o userId:", err);
          });
      } else {
        setUser(null);
      }
    });
    return unsubscribe;
  }, []);

  // Consulta à API da tag quando há código na URL
  useEffect(() => {
    if (codigo) {
      setLoadingQR(true);
      const qrApiLink = "https://vidapet.tech/api/db/vidapettech/serv_Apis/tagqrcode/tqrcode.php";
      const requestBody = { acao: "acessar", codigo };
      fetch(qrApiLink, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      })
        .then((response) => response.json())
        .then((data) => {
          setApiResponse(data);
          setLoadingQR(false);
        })
        .catch((err) => {
          console.error("Erro ao carregar os dados da tag:", err);
          setErrorQR("Erro ao carregar as informações da tag.");
          setLoadingQR(false);
        });
    }
  }, [codigo]);

  // Função para carregar a lista de pets do usuário
  const loadPets = useCallback(async () => {
    if (!user) return;
    try {
      const response = await axios.get(`https://vidapet.tech/petList.php?userId=${user.uid}`);
      const data = response.data;
      setPets(data.pets && Array.isArray(data.pets) ? data.pets : []);
    } catch (error) {
      console.error("Erro ao carregar os pets:", error);
    } finally {
      setIsPetsLoaded(true);
    }
  }, [user]);

  // Carrega os pets se o status da tag for "info" e o usuário estiver autenticado
  useEffect(() => {
    if (codigo && user && apiResponse && apiResponse.status === "info") {
      loadPets();
    }
  }, [codigo, user, apiResponse, loadPets]);

  // Handler para associar a tag a um pet
  const handleAssociatePet = (petId) => {
    const qrApiLink = "https://vidapet.tech/api/db/vidapettech/serv_Apis/tagqrcode/tqrcode.php";
    const requestData = { acao: "associar", codigo, pet_id: petId, tutor_id: user.uid };
    axios
      .post(qrApiLink, requestData)
      .then((res) => {
        if (res.data.status === "success") {
          setApiResponse({
            status: "success",
            message: "Tag associada com sucesso.",
            pet: res.data.pet,
            tutor: res.data.tutor,
          });
          navigate("/dashboard", { state: { section: "pets" } });
        } else {
          alert("Falha ao associar a tag: " + res.data.message);
        }
      })
      .catch((err) => {
        console.error("Erro ao associar a tag:", err);
        alert("Erro ao associar a tag.");
      });
  };

  const handleOpenPetModal = () => setShowPetModal(true);
  const handleClosePetModal = () => {
    setShowPetModal(false);
    loadPets();
  };

  // Tela inicial quando não há código na URL
  if (!codigo) {
    return (
      <div className="tag-container">
        <div className="tag-card">
          <h1 className="tag-title">Bem-vindo à Página de Tags da VidaPet.Tech</h1>
          <p>
            Encontrou um pet utilizando nossa tag? Informe o código impresso na tag ou realize a leitura do QR Code.
          </p>
          <p>Deseja conhecer mais sobre nossos serviços?</p>
          <div className="tag-input-area">
            <input
              type="text"
              placeholder="Informe o código da tag"
              value={inputCode}
              onChange={(e) => setInputCode(e.target.value)}
            />
            <button
              onClick={() => {
                if (inputCode.trim() !== "") {
                  navigate(`/tagpage/${inputCode.trim()}`);
                }
              }}
            >
              Enviar Código
            </button>
          </div>
          <button className="tag-button" onClick={() => navigate("/servicos")}>
            Conheça Nossos Serviços
          </button>
        </div>
      </div>
    );
  }

  // Fluxo quando há um código na URL
  return (
    <div className="tag-container">
      <div className="tag-card">
        <h1 className="tag-title">Página de Tag</h1>
        {loadingQR && <p className="tag-loading">Carregando informações...</p>}
        {errorQR && <p className="tag-error">{errorQR}</p>}

        {apiResponse && apiResponse.status === "success" && apiResponse.pet && apiResponse.tutor && (
          <div className="tag-info">
            <TagDetails pet={apiResponse.pet} tutor={apiResponse.tutor} />
          </div>
        )}

        {apiResponse && apiResponse.status === "info" && (
          <div className="tag-info">
            <p className="tag-welcome">
              Bem-vindo à Página de Tag da VidaPet.Tech.
            </p>
            <p>
              Notamos que esta tag ainda não está vinculada à sua conta. Para garantir a segurança e o monitoramento do seu pet, associe-a à sua conta.
            </p>
            {user ? (
              <div>
                {isPetsLoaded ? (
                  pets.length > 0 ? (
                    <div>
                      <p>Selecione um pet para associar à tag:</p>
                      <ul className="pet-list">
                        {pets.map((petItem) => {
                          const petImageURL = petItem.pet_3x4
                            ? `https://vidapet.tech/vidapet/pets/${petItem.pet_3x4}?t=${new Date().getTime()}`
                            : petIcon;
                          return (
                            <li key={petItem.petId} className="pet-list-item">
                              <img
                                src={petImageURL}
                                alt={petItem.petName}
                                className="pet-list-thumbnail"
                              />
                              <span>{petItem.petName}</span>
                              <button onClick={() => handleAssociatePet(petItem.petId)}>
                                Associar
                              </button>
                            </li>
                          );
                        })}
                      </ul>
                      <p>Ou cadastre um novo pet:</p>
                      <button className="tag-button" onClick={handleOpenPetModal}>
                        Cadastrar Novo Pet
                      </button>
                    </div>
                  ) : (
                    <div>
                      <p>Você ainda não possui nenhum pet cadastrado.</p>
                      <button className="tag-button" onClick={handleOpenPetModal}>
                        Cadastrar Novo Pet
                      </button>
                    </div>
                  )
                ) : (
                  <p>Carregando sua lista de pets...</p>
                )}
              </div>
            ) : (
              <div>
                <p>
                  Você não está autenticado. Por favor, realize o login através do menu superior em{" "}
                  <strong>Cadastro / Login</strong> para prosseguir.
                </p>
                <button className="tag-button" onClick={() => setShowLoginMessageModal(true)}>
                  Fazer Login
                </button>
              </div>
            )}
          </div>
        )}
      </div>

      {showPetModal && (
        <ModalBackground>
          <ModalContent>
            <PetForm
              userId={user ? user.uid : null}
              updatePetList={loadPets}
              closeForm={handleClosePetModal}
            />
          </ModalContent>
        </ModalBackground>
      )}

      {showLoginMessageModal && (
        <ModalBackground>
          <ModalContent>
            <p>
              Por favor, realize o login através do menu superior em <strong>Cadastro / Login</strong> para prosseguir.
            </p>
            <button onClick={() => setShowLoginMessageModal(false)}>Fechar</button>
          </ModalContent>
        </ModalBackground>
      )}
    </div>
  );
};

export default TagPage;

// ------------------ Styled Components (Modal) ------------------

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: white;
  border-radius: 12px;
  padding: 20px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;
