import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FaWhatsapp, FaFacebook, FaHeart, FaCommentAlt } from 'react-icons/fa';
import AdoptionFeeds from './AdoptionFeeds';
import './AdoptionViewDetails.css';

const AdoptionViewDetails = () => {
  const { id } = useParams();
  const [adoption, setAdoption] = useState(null);
  const [followers, setFollowers] = useState([]);
  const [isFollowing, setIsFollowing] = useState(false);
  const [isCommentBoxOpen, setIsCommentBoxOpen] = useState(false);
  const [comment, setComment] = useState('');
  const [refreshFeeds, setRefreshFeeds] = useState(false);
  const [comments, setComments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Buscar todos os casos e filtrar pelo ID
    fetch('https://vidapet.tech/api/DB/vidapettech/adoption/getadoptioncases.php')
      .then((res) => res.json())
      .then((data) => {
        const found = data.find((item) => String(item.id) === String(id));
        if (found) {
          setAdoption(found);
          fetchFollowers(found.id);
          fetchComments(found.id); // Buscar comentários
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.error('Erro ao buscar dados:', err);
        setIsLoading(false);
      });
  }, [id]);

  // Buscar seguidores do caso
  const fetchFollowers = (adoptionId) => {
    fetch(`https://vidapet.tech/api/DB/vidapettech/adoption/adoption_follows.php?adoption_id=${adoptionId}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setFollowers(data.followers || []);
        }
      })
      .catch((error) => console.error('Erro ao buscar seguidores:', error));
  };

  // Buscar comentários do caso
  const fetchComments = (adoptionId) => {
    fetch(`https://vidapet.tech/api/DB/vidapettech/adoption/getComments.php?adocao_caso_id=${adoptionId}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setComments(data.comments || []);
        }
      })
      .catch((error) => console.error('Erro ao buscar comentários:', error));
  };

  // Verifica se o userId logado está seguindo
  useEffect(() => {
    const userId = localStorage.getItem('userId');
    if (userId && followers.includes(parseInt(userId))) {
      setIsFollowing(true);
    } else {
      setIsFollowing(false);
    }
  }, [followers]);

  // Ação de seguir ou parar de seguir
  const handleFollowAdoption = () => {
    const userId = localStorage.getItem('userId');
    if (!userId) {
      alert('É necessário estar logado para seguir este caso.');
      return;
    }
    const action = isFollowing ? 'unfollow' : 'follow';
    const payload = {
      adoption_id: id,
      user_id: userId,
      action,
    };

    fetch('https://vidapet.tech/api/DB/vidapettech/adoption/adoption_follows.php', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setIsFollowing(!isFollowing);
          fetchFollowers(id);
          setRefreshFeeds((prev) => !prev);
        } else {
          alert(data.message || 'Erro ao seguir/desseguir.');
        }
      })
      .catch((err) => {
        console.error(err);
        alert('Erro ao seguir/desseguir.');
      });
  };

  // Compartilhar no WhatsApp
  const shareOnWhatsApp = () => {
    const url = window.location.href;
    const text = `Confira este pet para adoção! ${url}`;
    window.open(
      `https://api.whatsapp.com/send?text=${encodeURIComponent(text)}`,
      '_blank'
    );
  };

  // Compartilhar no Facebook
  const shareOnFacebook = () => {
    const url = window.location.href;
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`,
      '_blank'
    );
  };

  // Enviar comentário
  const handleCommentSubmit = () => {
    const userId = localStorage.getItem('userId');
    if (!userId) {
      alert('É necessário estar logado para comentar.');
      return;
    }
    if (!comment.trim()) {
      alert('Digite um comentário válido.');
      return;
    }

    const payload = {
      adocao_caso_id: id,
      sender_id: userId,
      message: comment,
      action: 'comment',
      is_deleted: 'não',
    };

    fetch('https://vidapet.tech/api/DB/vidapettech/adoption/submitComment.php', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          alert('Comentário enviado com sucesso!');
          setComment('');
          setIsCommentBoxOpen(false);
          fetchComments(id); // Atualizar comentários após envio
          setRefreshFeeds((prev) => !prev);
        } else {
          alert(data.message || 'Erro ao enviar comentário.');
        }
      })
      .catch((err) => {
        console.error(err);
        alert('Erro ao enviar comentário.');
      });
  };

  if (isLoading) {
    return <div className="details-container">Carregando...</div>;
  }

  if (!adoption) {
    return (
      <div className="details-container">
        <h2>Pet não encontrado.</h2>
      </div>
    );
  }

  return (
    <div className="details-container">
      <div className="details-wrapper">
        <div className="details-image">
          <img
            src={adoption.pet_3x4 ? adoption.pet_3x4 : 'https://via.placeholder.com/400x300?text=Sem+Imagem'}
            alt={adoption.petName || 'Pet'}
          />
        </div>
        <div className="details-info">
          <h2>{adoption.petName || adoption.title || 'Adoção Pet'}</h2>
          <p>
            <strong>Motivo da adoção:</strong> {adoption.reason || 'Não informado'}
          </p>
          <p>
            <strong>Comentários:</strong> {adoption.comments || 'Nenhum comentário adicional.'}
          </p>
          <p>
            <strong>Local:</strong> {adoption.city}, {adoption.state}, {adoption.country}
          </p>

          <div className="action-buttons">
            <button onClick={handleFollowAdoption}>
              <FaHeart />
              {isFollowing ? ' Parar de Seguir' : ' Seguir'}
            </button>
            <button onClick={shareOnWhatsApp}>
              <FaWhatsapp /> WhatsApp
            </button>
            <button onClick={shareOnFacebook}>
              <FaFacebook /> Facebook
            </button>
          </div>
        </div>
      </div>

      {/* Lista de seguidores */}
      <div className="followers-section">
        <h3>Seguidores ({followers.length}):</h3>
        {followers.length > 0 ? (
          followers.map((uid) => <div key={uid}>Usuário ID: {uid}</div>)
        ) : (
          <p>Ninguém segue este pet ainda.</p>
        )}
      </div>

      {/* Seção de comentário */}
      <div className="comment-section">
        <button onClick={() => setIsCommentBoxOpen(!isCommentBoxOpen)}>
          <FaCommentAlt /> Comentar
        </button>
        {isCommentBoxOpen && (
          <div className="comment-box">
            <textarea
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder="Escreva seu comentário..."
            />
            <button onClick={handleCommentSubmit}>Enviar</button>
          </div>
        )}
      </div>

      {/* Feeds de comentários */}
      <AdoptionFeeds adoptionId={id} refreshFeeds={refreshFeeds} comments={comments} />
    </div>
  );
};

export default AdoptionViewDetails;
