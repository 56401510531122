import React, { useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';

const PetForm = ({ userId, updatePetList, closeForm }) => {
  const [petName, setPetName] = useState('');
  const [breed, setBreed] = useState('');
  const [age, setAge] = useState('');
  const [petType, setPetType] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      if (!petName || !breed || !age || !petType) {
        console.error('Erro: informações de pet incompletas');
        return;
      }

      const petData = {
        userId,
        petName,
        breed,
        age,
        type: petType,
      };

      console.log('Dados enviados para o cadastro:', petData);

      const response = await axios.post('https://vidapet.tech/CRUD-pets.php', petData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      console.log('Resposta do servidor ao cadastrar o pet:', response.data);

      if (response.data.includes('sucesso')) {
        setShowAlert(true);
        updatePetList();
        setTimeout(() => {
          closeForm();
        }, 2000);
      } else {
        console.error('Erro ao adicionar o pet:', response.data);
      }
    } catch (error) {
      console.error('Erro ao adicionar o pet:', error);
    }
  };

  return (
    <ModalBackground>
      <ModalContent>
        <CloseButton onClick={closeForm}>&times;</CloseButton>
        <Title>Adicionar Novo Pet</Title>
        {showAlert && <Alert>Pet Cadastrado com Sucesso!</Alert>}

        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Label>Nome do Pet:</Label>
            <Input
              type="text"
              value={petName}
              onChange={(e) => setPetName(e.target.value)}
              placeholder="Digite o nome do pet"
            />
          </FormGroup>
          <FormGroup>
            <Label>Raça:</Label>
            <Input
              type="text"
              value={breed}
              onChange={(e) => setBreed(e.target.value)}
              placeholder="Digite a raça do pet"
            />
          </FormGroup>
          <FormGroup>
            <Label>Idade:</Label>
            <Input
              type="number"
              value={age}
              onChange={(e) => setAge(e.target.value)}
              placeholder="Digite a idade do pet"
            />
          </FormGroup>
          <FormGroup>
            <Label>Tipo:</Label>
            <Select value={petType} onChange={(e) => setPetType(e.target.value)}>
              <option value="">Selecione o tipo</option>
              <option value="Cão">Cão</option>
              <option value="Gato">Gato</option>
              <option value="Ave">Ave</option>
              <option value="Reptil">Reptil</option>
              <option value="Peixe">Peixe</option>
              <option value="Outro">Outro</option>
            </Select>
          </FormGroup>
          <SubmitButton type="submit">Salvar</SubmitButton>
        </Form>
      </ModalContent>
    </ModalBackground>
  );
};

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: #fff;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 500px;
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #333;
  transition: color 0.3s;

  &:hover {
    color: #ff4500;
  }
`;

const Title = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
`;

const Alert = styled.div`
  background-color: #d4edda;
  color: #155724;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
  text-align: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const FormGroup = styled.div`
  margin-bottom: 15px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: bold;
  color: #555;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
`;

const Select = styled.select`
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
`;

const SubmitButton = styled.button`
  background-color: #ff7f50;
  color: white;
  padding: 12px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #ff4500;
  }
`;

export default PetForm;
