import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import HealthEventForm from '../Components/HealthEventForm';
import HealthEventsView from '../Components/HealthEventsView';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

const Container = styled.div`
  padding: 20px;
  max-width: 900px;
  margin: 0 auto;
`;

const Header = styled.div`
  display: flex; 
  justify-content: space-between; 
  align-items: center;
  margin-bottom: 20px;
`;

const Title = styled.h1`
  font-size: 24px; 
  color: #333;
`;

const UserInfo = styled.div`
  margin-bottom: 20px;
  padding: 15px; 
  background-color: #f5f7fa; 
  border-radius: 8px;
`;

const PetList = styled.ul`
  list-style: none; 
  padding: 0;
`;

const PetItem = styled.li`
  padding: 10px; 
  border-bottom: 1px solid #ddd;
`;

const Loading = styled.div`
  text-align: center;
  font-size: 18px;
  color: #666;
`;

const ErrorMessage = styled.p`
  color: red;
  text-align: center;
`;

const PartnerDashboard = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get('userId');
  const petId = queryParams.get('petId');

  const [user, setUser] = useState(null);
  const [pets, setPets] = useState([]);
  const [selectedPet, setSelectedPet] = useState(petId || '');
  const [healthEvents, setHealthEvents] = useState([]);
  const [error, setError] = useState('');
  const [loadingUser, setLoadingUser] = useState(false);
  const [loadingPets, setLoadingPets] = useState(false);
  const [loadingEvents, setLoadingEvents] = useState(false);
  const [addingEvent, setAddingEvent] = useState(false);

  // Função para buscar informações do usuário
  const fetchUserInfo = useCallback(async () => {
    setLoadingUser(true);
    try {
      const response = await axios.get(`https://vidapet.tech/api/user.php?userId=${userId}`);
      console.log('Resposta da API user.php:', response.data);
      if (response.data.status === 'success') {
        setUser(response.data.user);
      } else {
        setError('Usuário não encontrado.');
      }
    } catch (error) {
      console.error('Erro ao buscar informações do usuário:', error);
      setError('Erro ao buscar informações do usuário.');
    } finally {
      setLoadingUser(false);
    }
  }, [userId]);

  // Função para buscar os pets do usuário
  const fetchUserPets = useCallback(async () => {
    setLoadingPets(true);
    try {
      const response = await axios.get(`https://vidapet.tech/api/pets.php?userId=${userId}`);
      console.log('Resposta da API pets.php:', response.data);
      if (response.data.status === 'success') {
        setPets(response.data.pets);
        // Se o petId não está na lista de pets, redefinir
        if (!response.data.pets.some(pet => pet.petId === parseInt(petId))) {
          setSelectedPet('');
          console.warn('petId não encontrado na lista de pets. Resetando selectedPet.');
        }
      } else {
        setError('Nenhum pet encontrado para este usuário.');
      }
    } catch (error) {
      console.error('Erro ao buscar pets do usuário:', error);
      setError('Erro ao buscar pets do usuário.');
    } finally {
      setLoadingPets(false);
    }
  }, [userId, petId]);

  // Função para buscar eventos de saúde do pet selecionado
  const fetchHealthEvents = useCallback(async (petId) => {
    setLoadingEvents(true);
    try {
      const response = await axios.get(`https://vidapet.tech/api/health-events.php?petId=${petId}`);
      console.log('Resposta da API health-events.php:', response.data);
      if (response.data.status === 'success' && Array.isArray(response.data.events)) {
        setHealthEvents(response.data.events);
      } else {
        setHealthEvents([]);
      }
    } catch (error) {
      console.error('Erro ao buscar eventos de saúde:', error);
      setError('Erro ao buscar eventos de saúde.');
    } finally {
      setLoadingEvents(false);
    }
  }, []);

  // Função para adicionar um novo evento de saúde
  const handleAddHealthEvent = async (eventDescription) => {
    if (selectedPet) {
      setAddingEvent(true);
      try {
        const eventType = 'Atendimento';
        const userDate = new Date().toISOString().split('T')[0];

        const formData = new FormData();
        formData.append('eventType', eventType);
        formData.append('eventDescription', eventDescription);
        formData.append('petId', selectedPet);
        formData.append('userDate', userDate);

        const response = await axios.post('https://vidapet.tech/health-events.php', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        console.log('Resposta da API ao adicionar evento:', response.data);
        if (response.data.status === 'success') {
          alert('Evento de saúde adicionado com sucesso!');
          fetchHealthEvents(selectedPet);
        } else {
          alert('Falha ao adicionar evento de saúde.');
        }
      } catch (error) {
        console.error('Erro ao adicionar evento de saúde:', error);
        alert('Erro ao adicionar evento de saúde.');
      } finally {
        setAddingEvent(false);
      }
    }
  };

  useEffect(() => {
    if (userId) {
      fetchUserInfo();
      fetchUserPets();
    }
  }, [userId, fetchUserInfo, fetchUserPets]);

  useEffect(() => {
    if (selectedPet) {
      fetchHealthEvents(selectedPet);
    }
  }, [selectedPet, fetchHealthEvents]);

  if (!userId || !petId) {
    return (
      <Container>
        <ErrorMessage>Parâmetros inválidos. Por favor, verifique o link e tente novamente.</ErrorMessage>
      </Container>
    );
  }

  return (
    <Container>
      <Header>
        <Title>Painel de Veterinário - VidaPet.Tech</Title>
      </Header>

      {error && <ErrorMessage>{error}</ErrorMessage>}

      {/* Informações do Usuário */}
      {loadingUser ? (
        <Loading>Carregando informações do usuário...</Loading>
      ) : (
        user && (
          <UserInfo>
            <h2>Informações do Usuário</h2>
            <p><strong>Nome:</strong> {user.nome}</p>
            <p><strong>Email:</strong> {user.email}</p>
            {/* Adicione mais informações conforme necessário */}
          </UserInfo>
        )
      )}

      {/* Lista de Pets */}
      {loadingPets ? (
        <Loading>Carregando pets do usuário...</Loading>
      ) : (
        pets.length > 0 && (
          <div>
            <h2>Pets do Usuário</h2>
            <PetList>
              {pets.map((pet) => (
                <PetItem key={pet.petId}>
                  <strong>Nome:</strong> {pet.petName} <br />
                  <strong>Raça:</strong> {pet.breed} <br />
                  <strong>Idade:</strong> {pet.age} anos
                </PetItem>
              ))}
            </PetList>
          </div>
        )
      )}

      {/* Selecionar Pet e Eventos de Saúde */}
      {selectedPet ? (
        <div>
          <h2>Eventos de Saúde</h2>
          {loadingEvents ? (
            <Loading>Carregando eventos de saúde...</Loading>
          ) : (
            <>
              <HealthEventsView
                petId={selectedPet}
                healthEvents={healthEvents}
                reloadEvents={() => fetchHealthEvents(selectedPet)}
              />
              <HealthEventForm petId={selectedPet} onSubmit={handleAddHealthEvent} loading={addingEvent} />
            </>
          )}
        </div>
      ) : (
        <ErrorMessage>Selecione um pet para visualizar os eventos de saúde.</ErrorMessage>
      )}
    </Container>
  );
};

export default PartnerDashboard;
