import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // Importando useNavigate
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { FaWhatsapp, FaFacebook, FaDog, FaPaw, FaHeart, FaMapMarkerAlt, FaCommentAlt } from 'react-icons/fa';
import pinIcon from '../../../imagens/icon-Map.png';
import { Helmet } from 'react-helmet';
import { getUserIdFromFirebaseId } from '../../Login/userService';
import RescueFeeds from './RescueFeeds';
import './RescueDetails.css'; // Importação correta do CSS
import './RescueFeeds.css'; // Importação correta do CSS

// Definição do ícone do mapa
const icon = L.icon({
  iconUrl: pinIcon,
  iconSize: [38, 38],
  iconAnchor: [19, 38],
});

const RescueDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate(); // Inicializando useNavigate
  const [rescue, setRescue] = useState(null);
  const [userId, setUserId] = useState(null);
  const [followers, setFollowers] = useState([]);
  const [isFollowing, setIsFollowing] = useState(false); // Verificação de acompanhamento
  const [isCommentBoxOpen, setIsCommentBoxOpen] = useState(false);
  const [comment, setComment] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [refreshFeeds, setRefreshFeeds] = useState(false); // Definição do estado
  const [userPhotoURLs, setUserPhotoURLs] = useState({}); // Novo estado para armazenar photoURLs

  // Função para buscar os detalhes do resgate e seguidores
  const fetchRescueDetails = (rescueId) => {
    fetch(`https://vidapet.tech/api/resgate/rescues.php?id=${rescueId}`)
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setRescue(data.resgate); // Atualiza o estado com os detalhes do resgate
          fetchFollowers(data.resgate.id); // Usa 'id' como 'rescue_id'
          setIsLoading(false);
        } else {
          console.error(data.mensagem);
          setIsLoading(false);
        }
      })
      .catch(error => {
        console.error('Erro ao buscar os dados do resgate:', error);
        setIsLoading(false);
      });
  };

  // Busca os detalhes do resgate quando o componente é montado ou o ID muda
  useEffect(() => {
    fetchRescueDetails(id);
  }, [id]);

  // Função para buscar os seguidores do caso
  const fetchFollowers = (rescueId) => {
    fetch(`https://vidapet.tech/api/resgate/rescue_followers.php?rescue_id=${rescueId}`)
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          setFollowers(data.followers); // Atualiza a lista de seguidores
        }
      })
      .catch(error => console.error('Erro ao buscar os seguidores:', error));
  };

  // Lógica para verificar se o usuário está seguindo o caso
  useEffect(() => {
    const firebaseId = localStorage.getItem('firebaseId');
    if (firebaseId) {
      getUserIdFromFirebaseId(firebaseId)
        .then(fetchedUserId => {
          if (fetchedUserId) {
            setUserId(fetchedUserId);

            // Converte ambos para string para evitar problemas de comparação de tipos
            const userIdString = String(fetchedUserId);
            const followersAsString = followers.map(follower => String(follower));

            const isUserFollowing = followersAsString.includes(userIdString);
            setIsFollowing(isUserFollowing);
          }
        })
        .catch(error => console.error('Erro ao verificar a autenticação:', error));
    }
  }, [followers]);

  // Função para buscar e armazenar photoURLs dos seguidores
  useEffect(() => {
    // Extrair todos os userIds dos seguidores
    const allFollowerIds = followers;
    const uniqueFollowerIds = [...new Set(allFollowerIds)];

    console.log('User IDs únicos dos seguidores:', uniqueFollowerIds); // Log dos IDs únicos

    if (uniqueFollowerIds.length > 0) {
      // Chamar a API para obter as photoURLs
      fetch(`https://vidapet.tech/api/resgate/userid_to_photourl.php?userIds=${uniqueFollowerIds.join(',')}`)
        .then(response => response.json())
        .then(data => {
          console.log('Resposta da API photoURLs:', data); // Log da resposta da API
          setUserPhotoURLs(data);
          console.log('Estado userPhotoURLs atualizado:', data); // Log do estado atualizado
        })
        .catch(error => {
          console.error('Erro ao buscar photoURLs:', error);
        });
    }
  }, [followers]);

  // Função de confirmação
  const confirmAction = (message, action) => {
    const confirm = window.confirm(message);
    if (confirm) {
      action();
    }
  };

  // Função para as ações do usuário
  const handleAction = async (actionUrl, message, isSpecialAction = false, successCallback) => {
    if (!userId) {
      alert('Você precisa estar logado para realizar esta ação.');
      return;
    }
  
    if (!rescue?.id) { // Verifica se 'id' está definido
      console.error('Rescue ID não está definido.');
      alert('Detalhes do resgate ainda estão sendo carregados. Tente novamente mais tarde.');
      return;
    }
  
    confirmAction(message, async () => {
      try {
        const payload = { rescue_id: rescue.id, user_id: userId }; // Usa 'id' como 'rescue_id'
        console.log('Enviando para API:', actionUrl, payload);
  
        const response = await fetch(actionUrl, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(payload),
        });
  
        if (!response.ok) {
          const errorText = await response.text();
          console.error('Resposta de erro da API:', errorText);
          alert('Erro ao realizar a ação.');
          return;
        }
  
        const data = await response.json();
        console.log('Resposta da API:', data);
  
        if (data.success) {
          alert('Ação realizada com sucesso!');
          if (isSpecialAction) {
            alert('Obrigado por informar. Nossa equipe analisará a ação.');
          }
          if (successCallback) successCallback();
          fetchRescueDetails(id);
          setRefreshFeeds(!refreshFeeds); // Atualiza o feed
        } else {
          alert('Erro ao realizar a ação: ' + (data.message || ''));
        }
      } catch (error) {
        console.error('Erro ao realizar a ação:', error);
        alert('Erro ao realizar a ação.');
      }
    });
  };

  // Funções específicas para cada ação
  const handlePetFound = () => handleAction(
    'https://vidapet.tech/api/resgate/pet_found.php',
    'Quer Registrar que Achou esse Pet?',
    true // Indica que a ação é especial, para exibir a mensagem adicional
  );

  const handlePetRescued = () => handleAction(
    'https://vidapet.tech/api/resgate/pet_rescued.php',
    'Quer Registrar que Resgatou esse Pet?',
    true // Indica que a ação é especial, para exibir a mensagem adicional
  );

  const handleFollowCase = () => {
    const actionUrl = isFollowing
      ? 'https://vidapet.tech/api/resgate/unfollow_case.php'
      : 'https://vidapet.tech/api/resgate/follow_case.php';

    const message = isFollowing
      ? 'Quer Parar de Seguir este Caso?'
      : 'Quer Acompanhar este Caso de Pet?';

    handleAction(actionUrl, message, false); // Não exibe a mensagem adicional
  };

  const handleLocationAccess = () => {
    const actionUrl = 'https://vidapet.tech/api/resgate/rescue_location_access.php';

    handleAction(actionUrl, 'Quer abrir a geolocalização desse pet no Google Maps?', () => {
      const { latitude, longitude } = rescue;
      window.open(`https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`, '_blank');
    });
  };

  // Funções para compartilhar nas redes sociais
  const shareOnWhatsApp = () => {
    const url = window.location.href;
    const message = `Olá! Encontrei um pet perdido que precisa de ajuda.\n\nMais informações: ${url}`;
    window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`, '_blank');
  };

  const handleShareOnFacebook = () => {
    const url = window.location.href;
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`, '_blank');
  };

  // Funções para manipulação de comentários
  const toggleCommentBox = () => {
    setIsCommentBoxOpen(!isCommentBoxOpen);
  };

  const handleCommentSubmit = async () => {
    if (comment.trim() === '') {
      alert('Por favor, insira um comentário.');
      return;
    }

    const generateMsgId = () => {
      return Math.random().toString(36).substr(2, 9); // Gera uma string aleatória de 9 caracteres
    };

    const msgId = generateMsgId(); // Gera um novo msg_id

    const payload = {
      rescue_id: rescue.id, // Usa 'id' como 'rescue_id'
      user_id: userId,
      message: comment,
      msg_id: msgId // Enviar o msg_id gerado
    };

    console.log('Enviando comentário para API:', payload);

    try {
      const response = await fetch('https://vidapet.tech/api/resgate/rescue_msgs.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });

      const data = await response.json();
      console.log('Resposta da API:', data);

      if (data.success) {
        alert('Comentário enviado com sucesso!');
        setComment('');
        setIsCommentBoxOpen(false);
        setRefreshFeeds(!refreshFeeds); // Atualiza o feed após o comentário
        fetchRescueDetails(id); // Atualiza detalhes do resgate, se necessário
      } else {
        alert('Erro ao enviar o comentário: ' + (data.message || ''));
      }
    } catch (error) {
      console.error('Erro ao enviar o comentário:', error);
      alert('Erro ao enviar o comentário.');
    }
  };

  // Exibe enquanto dados não estão disponíveis
  if (isLoading) {
    return <div>Carregando...</div>;
  }

  return (
    <div className="container1">
      <Helmet>
        <title>Detalhes do Resgate</title>
        <meta property="og:title" content="Detalhes do Resgate" />
        <meta property="og:description" content="Encontrei um pet perdido que precisa de ajuda. Veja mais detalhes e ajude se puder!" />
        <meta property="og:image" content={`https://vidapet.tech/${rescue.image_path}`} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
      </Helmet>
      <div className="content-wrapper">
        {/* Seção da Imagem */}
        <div className="image-section">
          <div className="image-container">
            {/* Selos de "Encontrado" e "Resgatado" */}
            {rescue?.pet_found === 'sim' && (
              <div className="badge badge-found">Encontrado</div>
            )}

            {rescue?.pet_rescued === 'sim' && (
              <div className="badge badge-rescued">Resgatado</div>
            )}

            <img src={`https://vidapet.tech/${rescue.image_path}`} alt={`Resgate ${rescue.type}`} />
          </div>

          {/* Textos abaixo da imagem */}
          <div className="status-texts">
            {rescue?.pet_found === 'sim' && (
              <p className="texto-encontrado">Este pet foi marcado como Encontrado.</p>
            )}
            {rescue?.pet_rescued === 'sim' && (
              <p className="texto-resgatado">Este pet foi marcado como Resgatado!</p>
            )}
          </div>
        </div>

        {/* Seção de Informações */}
        <div className="info-section">
          {/* Mapa com Localização */}
          <div className="details-map">
            <MapContainer center={[rescue.latitude, rescue.longitude]} zoom={13} style={{ height: '100%', width: '100%' }}>
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution='&copy; OpenStreetMap contributors' />
              <Marker position={[rescue.latitude, rescue.longitude]} icon={icon}>
                <Popup>Localização do resgate</Popup>
              </Marker>
            </MapContainer>
          </div>

          {/* Detalhes Adicionais */}
          <div className="details">
            <p><strong>Localização:</strong> {rescue.latitude}, {rescue.longitude}</p>
            <p><strong>Data da Captura:</strong> {new Date(rescue.capture_datetime).toLocaleString()}</p>
            <p><strong>Comentários:</strong> {rescue.comments}</p>
          </div>

          {/* Exibição dos Seguidores */}
          <div className="followers-section">
            <h3>Usuários Acompanhando Este Caso:</h3>
            <div className="followers-list">
              {followers.map((followerId) => (
                <div key={followerId} className="follower-item">
                  <img
                    src={userPhotoURLs[String(followerId)] || 'https://via.placeholder.com/40'}
                    alt={`Usuário ${followerId}`}
                    className="follower-image"
                    onError={(e) => {
                      e.target.onerror = null; // Previne loop de erro
                      e.target.src = 'https://via.placeholder.com/40';
                    }}
                  />
                </div>
              ))}
            </div>
          </div>

          {/* Botões de Ação e Compartilhamento */}
          <div className="action-share-wrapper">
            <div className="action-buttons">
              <div className="tooltip">
                <button className="buttonLocation" onClick={handleLocationAccess}>
                  <FaMapMarkerAlt />
                </button>
                <span className="tooltiptext">Acessar a localização no Google Maps</span>
              </div>

              <div className="tooltip">
                <button className="buttonPetFound" onClick={handlePetFound} disabled={rescue?.pet_found === 'sim'}>
                  <FaDog />
                </button>
                <span className="tooltiptext">Aqui você pode registrar se encontrou seu pet</span>
              </div>

              <div className="tooltip">
                <button className="buttonPetRescued" onClick={handlePetRescued} disabled={rescue?.pet_rescued === 'sim'}>
                  <FaPaw />
                </button>
                <span className="tooltiptext">Aqui você pode registrar se resgatou esse pet</span>
              </div>

              <div className="tooltip">
                <button className="buttonFollow" onClick={handleFollowCase}>
                  <FaHeart />
                </button>
                <span className="tooltiptext">{isFollowing ? 'Parar de seguir este caso' : 'Acompanhar este caso'}</span>
              </div>
            </div>

            <div className="share-buttons-right">
              <div className="tooltip">
                <button className="buttonWha" onClick={shareOnWhatsApp}>
                  <FaWhatsapp />
                </button>
                <span className="tooltiptext">Compartilhar nos grupos de WhatsApp</span>
              </div>

              <div className="tooltip">
                <button className="buttonFace" onClick={handleShareOnFacebook}>
                  <FaFacebook />
                </button>
                <span className="tooltiptext">Compartilhar no Facebook</span>
              </div>
            </div>
          </div>

          {/* Seção de Comentários e Feeds */}
          <div className="comment-section">
            <button className="comment-button" onClick={toggleCommentBox}>
              <FaCommentAlt /> Comente nesse Caso de Resgate
            </button>

            {isCommentBoxOpen && (
              <div className="comment-box">
                <textarea
                  className="comment-textarea"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  placeholder="Escreva seu comentário..."
                />
                <button className="submit-comment" onClick={handleCommentSubmit}>
                  Enviar
                </button>
              </div>
            )}
          </div>

          <RescueFeeds rescueId={id} refreshFeeds={refreshFeeds} />
        </div>
      </div>
    </div>
  );
};

export default RescueDetails;
