import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './Home.css';
import UserChat from '../Dashboard/Components/Chat/UserChat';
import BlogList from '../Blog/BlogList';
import DonationCard from './DonationCard'; // Certifique-se de criar este componente conforme mostrado anteriormente

const Home = () => {
  const [isChatVisible, setIsChatVisible] = useState(false);
  const [showConsentModal, setShowConsentModal] = useState(true);
  const [isDonationExpanded, setIsDonationExpanded] = useState(false);
  const navigate = useNavigate();
  const videoRef = useRef(null);

  const features = {
    digitalCard: {
      title: "Carteirinha Digital",
      description: [
        "Documentação segura e acessível.",
        "Acesso rápido em emergências.",
        "Armazenamento digital seguro."
      ],
      link: "/dashboard",
    },
    petServices: {
      title: "Serviços Pet",
      description: [
        "Tudo para seu pet em um só lugar.",
        "Produtos essenciais sempre disponíveis.",
        "Comodidade e facilidade de compra."
      ],
      link: "/servico",
    },
    telemedicine: {
      title: "Teleconsulta Veterinária",
      description: [
        "Consultas veterinárias a qualquer momento.",
        "Orientações rápidas e eficazes.",
        "Cuidado imediato para seu pet."
      ],
      link: "/telemedicine",
    },
    rescue: {
      title: "Resgate Inteligente",
      description: [
        "Alertas rápidos à comunidade local.",
        "Aumenta as chances de encontrar pets perdidos.",
        "Suporte em situações de emergência."
      ],
      link: "/resgate",
    },
  };

  useEffect(() => {
    const chatTimeout = setTimeout(() => {
      setIsChatVisible(true);
    }, 12000);

    return () => {
      clearTimeout(chatTimeout);
    };
  }, []);

  const handleNavigate = (link) => {
    navigate(link);
  };

  const handleLoginRedirect = () => {
    navigate('/dashboard');
  };

  const handleConsent = () => {
    setShowConsentModal(false);
  };

  const toggleDonation = () => {
    setIsDonationExpanded(!isDonationExpanded);
  };

  return (
    <div className="home-new-container">
<section className="intro-section">
  {/* Vídeo de fundo */}
  <div className="background-video">
    <video ref={videoRef} autoPlay loop muted>
      <source src="/imagens/eleefon21.mp4" type="video/mp4" />
      Seu navegador não suporta vídeos.
    </video>
  </div>

  {/* Overlay que escurece o vídeo */}
  <div className="intro-overlay"></div>

  {/* Conteúdo acima do overlay */}
  <div className="intro-content">
    <div className="logo-container">
      <img
        src="/imagens/logoOficial2024.png"
        alt="Logo VidaPet.Tech"
        className="logo"
      />
    </div>
    <h1 className="intro-title">Cuidar do seu pet nunca foi tão fácil</h1>
    <p className="intro-description">
      Serviços, facilidades e segurança na palma da mão.
    </p>
    <button
      className="intro-button"
      onClick={() =>
        window.scrollTo({ top: window.innerHeight, behavior: 'smooth' })
      }
    >
      Saiba Mais
    </button>
  </div>
</section>


      <section className="features-section">
  <h2>Conheça Nossas Ferramentas</h2>
  {/* Texto em vermelho coladinho abaixo do título */}
  <p style={{ color: 'red', marginTop: '-10px', fontWeight: 'bold' }}>
    Cadastre-se Grátis
  </p>
  <div className="features-grid">
    {Object.keys(features).map((feature, index) => (
      <div className="feature-card" key={index}>
        <img
          src={`/imagens/Produto0${index + 1}.jpeg`}
          alt={features[feature].title}
          className="feature-img"
        />
        <h3>{features[feature].title}</h3>
        <ul className="feature-description">
          {features[feature].description.map((desc, idx) => (
            <li key={idx}>{desc}</li>
          ))}
        </ul>
        <button
          className="saiba-mais-btn"
          onClick={() => handleNavigate(features[feature].link)}
        >
          Saiba Mais
        </button>
      </div>
    ))}
    {/* Cartão de Doação */}
    <DonationCard onExpand={toggleDonation} />
  </div>
</section>


      {isDonationExpanded && (
        <section className="donation-expanded-section">
          {/* Aqui pode ser um modal ou uma expansão de conteúdo com mais informações sobre como doar */}
          <h2>Como Contribuir</h2>
          <p>Informações detalhadas sobre como realizar sua doação para o programa.</p>
          <button onClick={() => setIsDonationExpanded(false)}>Fechar</button>
        </section>
      )}

      <section className="blog-section">
        <BlogList />
      </section>

      <section className="cta-final-section">
        <button className="cta-button" onClick={handleLoginRedirect}>
          Cadastre-se Grátis e Comece Agora!
        </button>
      </section>

      <section className="testimonials-section">
        <h2>O Que Nossos Usuários Dizem</h2>
        <div className="testimonials-container">
          <div className="testimonial">
            <p>"Fui o primeiro usuário VidaPet.Tech e uso com minha YorkShire, Jade. Tudo está guardado lá desde o nascimento dela. Numa emergência, temos todos os dados de saúde dela na palma da mão."</p>
            <span>- Anderson Santos - Itaboraí - RJ</span>
          </div>
          <div className="testimonial">
            <p>"O resgate interativo salvou a vida do meu cachorro, um vizinho do bairro tirou a foto dele pelo VidaPet.Tech. Agradeço à equipe!"</p>
            <span>- Edson Soares - São Gonçalo - RJ</span>
          </div>
        </div>
      </section>

      {showConsentModal && (
        <div className="modal-overlay consent-modal">
          <div className="modal-content">
            <h2>Termos e Política de Privacidade</h2>
            <p>Ao utilizar o ecossistema VidaPet.Tech, você concorda com nossas políticas descritas em nossa página:</p>
            <a href="https://vidapet.tech/termos-e-privacidade" target="_blank" rel="noopener noreferrer" style={{ color: '#f9652b', textDecoration: 'underline', fontWeight: 'bold' }}>
              Termos e Privacidade
            </a>
            <button className="cta-button" onClick={handleConsent}>Aceitar</button>
          </div>
        </div>
      )}

      {isChatVisible && <UserChat />}
    </div>
  );
};

export default Home;
