import React, { useState, useEffect } from 'react';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { useInView } from 'react-intersection-observer';
import heroImage from '../../imagens/PlanEmpresasHero.webp';

ChartJS.register(ArcElement, Tooltip, Legend);

const PlanEmpresas = () => {
  /************************************************************
   * GRÁFICO ESTÁTICO - Distribuição do Fundo
   ************************************************************/
  const [chartData, setChartData] = useState([10, 20, 30, 40]); // valores iniciais para animação
  const [ref, inView] = useInView({ triggerOnce: true });

  /************************************************************
   * SIMULADOR DE DOAÇÃO (inputs) - cálculo da contribuição total
   ************************************************************/
  const [numFuncionarios, setNumFuncionarios] = useState(0);
  const [valorDoacao, setValorDoacao] = useState('');
  const [valorTotal, setValorTotal] = useState(0);
  const [beneficioAtivo, setBeneficioAtivo] = useState(false);

  const calcularDoacao = () => {
    let val = parseFloat(valorDoacao);
    if (isNaN(val) || val < 0) {
      val = 0;
    }
    const total = numFuncionarios * val;
    setValorTotal(total);
    setBeneficioAtivo(val >= 5);
  };

  const handleNumFuncionariosChange = (e) => {
    const val = parseInt(e.target.value, 10);
    setNumFuncionarios(isNaN(val) ? 0 : Math.max(0, val));
  };

  const handleValorDoacaoChange = (e) => {
    setValorDoacao(e.target.value);
  };

  /************************************************************
   * FAQ - Perguntas Frequentes
   ************************************************************/
  const [faqOpenIndex, setFaqOpenIndex] = useState(null);
  const faqItems = [
    {
      question: 'Como os funcionários garantem o VidaPet.Tech Confort?',
      answer:
        'Basta que o funcionário contribua com uma doação de R$5,00 ou mais durante o ano para ter acesso ao VidaPet.Tech Confort por um ano.',
    },
    {
      question: 'A empresa é obrigada a fazer doações?',
      answer:
        'Não, a contribuição é totalmente opcional. A empresa pode incentivar a participação, mas não há obrigação de doação.',
    },
    {
      question: 'Como funciona o Fundo Dinâmico de Resgate?',
      answer:
        'O Fundo Dinâmico de Resgate é formado a partir das doações voluntárias dos funcionários. Os recursos são aplicados em ações de resgate, cuidado e adoção de animais, sempre com total transparência.',
    },
    {
      question: 'Posso doar valores superiores a R$5,00?',
      answer:
        'Claro! Qualquer valor acima de R$5,00 garante o benefício para o funcionário e contribui para o Fundo Dinâmico de Resgate.',
    },
    {
      question: 'Como recebo os relatórios de impacto das doações?',
      answer:
        'Os relatórios são enviados semestralmente, detalhando as ações realizadas com os recursos do Fundo Dinâmico de Resgate, como resgates, atendimentos e adoções.',
    },
  ];

  const toggleFAQ = (index) => {
    setFaqOpenIndex(faqOpenIndex === index ? null : index);
  };

  /************************************************************
   * FORMULÁRIO DE CONTATO
   ************************************************************/
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [mensagem, setMensagem] = useState('');

  const handleContactSubmit = (e) => {
    e.preventDefault();
    const subject = encodeURIComponent('Contato - Plano Empresas VidaPet.Tech');
    const body = encodeURIComponent(
      `Nome: ${nome}\nE-mail: ${email}\nMensagem: ${mensagem}`
    );
    window.location.href = `mailto:contato@vidapet.tech?subject=${subject}&body=${body}`;
  };

  /************************************************************
   * SIMULAÇÃO AUTOMÁTICA DO USO DO FUNDO - 5 CATEGORIAS
   ************************************************************/
  const [remainingFunds, setRemainingFunds] = useState(20000);
  const [accUsage, setAccUsage] = useState([0, 0, 0, 0, 0]);
  const [eventList, setEventList] = useState([]);
  const [simulationRunning, setSimulationRunning] = useState(false);
  const [scenarioIndex, setScenarioIndex] = useState(0);
  const [stepIndex, setStepIndex] = useState(0);

  const scenarios = [
    {
      petName: 'Cachorrinho',
      userName: 'Mariza',
      steps: [
        { text: 'Mariza registra o avistamento de um cachorrinho perdido via VidaPet.Tech', cost: [0, 0, 0, 0, 0] },
        { text: 'Alerta é enviado às ONGs parceiras', cost: [0, 0, 0, 0, 0] },
        { text: 'ONG realiza o resgate (R$197)', cost: [0, 197, 0, 0, 0] },
        { text: 'Pet encaminhado para clínica para verificação (R$350)', cost: [0, 0, 350, 0, 0] },
        { text: 'Mariza recebe R$15 em créditos pelo registro', cost: [15, 0, 0, 0, 0] },
        { text: 'Hospedagem do pet por 6 dias (R$360)', cost: [0, 0, 0, 360, 0] },
        { text: 'Pet disponibilizado para adoção', cost: [0, 0, 0, 0, 0] },
        { text: 'Julia adota o cachorrinho e recebe R$197 de incentivo', cost: [0, 0, 0, 0, 197] },
        { text: 'Cachorrinho resgatado com sucesso!', cost: [0, 0, 0, 0, 0] },
      ],
    },
    {
      petName: 'Gatinho',
      userName: 'Paula',
      steps: [
        { text: 'Paula encontra um gatinho em situação de risco', cost: [0, 0, 0, 0, 0] },
        { text: 'Alerta é gerado na plataforma', cost: [0, 0, 0, 0, 0] },
        { text: 'ONG realiza o resgate (R$197)', cost: [0, 197, 0, 0, 0] },
        { text: 'Clínica realiza sanitização (R$350)', cost: [0, 0, 350, 0, 0] },
        { text: 'Paula recebe R$15 em créditos', cost: [15, 0, 0, 0, 0] },
        { text: 'Hospedagem do pet (R$360)', cost: [0, 0, 0, 360, 0] },
        { text: 'Pet encaminhado para adoção', cost: [0, 0, 0, 0, 0] },
        { text: 'Família de Ricardo adota e recebe R$197 de incentivo', cost: [0, 0, 0, 0, 197] },
        { text: 'Gatinho resgatado com sucesso!', cost: [0, 0, 0, 0, 0] },
      ],
    },
    {
      petName: 'Cachorrinho',
      userName: 'Carlos',
      steps: [
        { text: 'Carlos avista um cachorro em situação de abandono', cost: [0, 0, 0, 0, 0] },
        { text: 'Alerta enviado às ONGs', cost: [0, 0, 0, 0, 0] },
        { text: 'ONG realiza o resgate e recebe R$197', cost: [0, 197, 0, 0, 0] },
        { text: 'Clínica realiza verificação e vacinação (R$350)', cost: [0, 0, 350, 0, 0] },
        { text: 'Carlos recebe R$15 de crédito', cost: [15, 0, 0, 0, 0] },
        { text: 'Hospedagem do pet por 6 dias (R$360)', cost: [0, 0, 0, 360, 0] },
        { text: 'Pet encaminhado para adoção', cost: [0, 0, 0, 0, 0] },
        { text: 'Matheus adota e recebe R$197 de incentivo', cost: [0, 0, 0, 0, 197] },
        { text: 'Cachorrinho resgatado com sucesso!', cost: [0, 0, 0, 0, 0] },
      ],
    },
    {
      petName: 'Gatinha',
      userName: 'Alice',
      steps: [
        { text: 'Alice encontra uma gatinha em situação delicada', cost: [0, 0, 0, 0, 0] },
        { text: 'Alerta gerado na plataforma', cost: [0, 0, 0, 0, 0] },
        { text: 'ONG assume o chamado (R$197)', cost: [0, 197, 0, 0, 0] },
        { text: 'Clínica confere situação de saúde (R$350)', cost: [0, 0, 350, 0, 0] },
        { text: 'Alice recebe R$15 em créditos', cost: [15, 0, 0, 0, 0] },
        { text: 'Hospedagem da gatinha (R$360)', cost: [0, 0, 0, 360, 0] },
        { text: 'Pet encaminhado para adoção', cost: [0, 0, 0, 0, 0] },
        { text: 'Suzana adota e recebe R$197 de incentivo', cost: [0, 0, 0, 0, 197] },
        { text: 'Gatinha resgatada com sucesso!', cost: [0, 0, 0, 0, 0] },
      ],
    },
    {
      petName: 'Cachorrinho',
      userName: 'Thiago',
      steps: [
        { text: 'Thiago registra o avistamento de um cachorro de grande porte', cost: [0, 0, 0, 0, 0] },
        { text: 'Alerta gerado na plataforma', cost: [0, 0, 0, 0, 0] },
        { text: 'ONG realiza o resgate (R$197)', cost: [0, 197, 0, 0, 0] },
        { text: 'Clínica realiza exames (R$350)', cost: [0, 0, 350, 0, 0] },
        { text: 'Thiago recebe R$15 de crédito', cost: [15, 0, 0, 0, 0] },
        { text: 'Hospedagem do pet por 6 dias (R$360)', cost: [0, 0, 0, 360, 0] },
        { text: 'Pet encaminhado para adoção', cost: [0, 0, 0, 0, 0] },
        { text: 'Família Silva adota e recebe R$197 de incentivo', cost: [0, 0, 0, 0, 197] },
        { text: 'Cachorrinho resgatado com sucesso!', cost: [0, 0, 0, 0, 0] },
      ],
    },
    {
      petName: 'Gatinho',
      userName: 'Bia',
      steps: [
        { text: 'Bia avista um gatinho em situação de risco na calçada', cost: [0, 0, 0, 0, 0] },
        { text: 'Foto enviada para a plataforma VidaPet.Tech', cost: [0, 0, 0, 0, 0] },
        { text: 'ONG realiza o resgate (R$197)', cost: [0, 197, 0, 0, 0] },
        { text: 'Clínica realiza atendimento (R$350)', cost: [0, 0, 350, 0, 0] },
        { text: 'Bia recebe R$15 em créditos', cost: [15, 0, 0, 0, 0] },
        { text: 'Hospedagem do pet (R$360)', cost: [0, 0, 0, 360, 0] },
        { text: 'Pet encaminhado para adoção', cost: [0, 0, 0, 0, 0] },
        { text: 'Carla adota e recebe R$197 de incentivo', cost: [0, 0, 0, 0, 197] },
        { text: 'Gatinho resgatado com sucesso!', cost: [0, 0, 0, 0, 0] },
      ],
    },
  ];

  useEffect(() => {
    let intervalId = null;
    if (simulationRunning) {
      intervalId = setInterval(() => {
        setStepIndex((oldStep) => {
          const subSteps = scenarios[scenarioIndex].steps;
          if (oldStep < subSteps.length) {
            const currentSubStep = subSteps[oldStep];
            const costArr = currentSubStep.cost;
            const totalCost = costArr.reduce((a, b) => a + b, 0);

            if (remainingFunds < totalCost) {
              setEventList((evt) => [
                ...evt,
                `Fundo insuficiente para continuar. Simulação interrompida.`,
              ]);
              setSimulationRunning(false);
              return oldStep;
            }

            setRemainingFunds((prev) => prev - totalCost);
            setAccUsage((prevUsage) => {
              const newUsage = [...prevUsage];
              for (let i = 0; i < newUsage.length; i++) {
                newUsage[i] += costArr[i];
              }
              return newUsage;
            });

            setEventList((evt) => [...evt, currentSubStep.text]);

            const nextStep = oldStep + 1;
            if (nextStep >= subSteps.length) {
              const nextScenario = scenarioIndex + 1;
              if (nextScenario >= scenarios.length) {
                setEventList((evt) => [
                  ...evt,
                  `Simulação concluída! Todos os 6 pets foram resgatados.`,
                ]);
                setSimulationRunning(false);
              } else {
                setScenarioIndex(nextScenario);
                return 0;
              }
            }
            return nextStep;
          }
          return oldStep;
        });
      }, 3000);
    }
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [simulationRunning, scenarioIndex, scenarios, remainingFunds]);

  const startSimulation = () => {
    setRemainingFunds(20000);
    setAccUsage([0, 0, 0, 0, 0]);
    setEventList([]);
    setScenarioIndex(0);
    setStepIndex(0);
    setSimulationRunning(true);
  };

  /************************************************************
   * Efeito de Animação do Gráfico Estático
   ************************************************************/
  useEffect(() => {
    if (inView) {
      setTimeout(() => {
        setChartData([25, 30, 25, 20]);
      }, 500);
    }
  }, [inView]);

  /************************************************************
   * Dados do Gráfico Estático (Distribuição do Fundo)
   ************************************************************/
  const dataFundo = {
    labels: ['ONGs Resgatadoras', 'Clínicas Veterinárias', 'Adoções', 'Administração'],
    datasets: [
      {
        label: 'Distribuição do Fundo Dinâmico',
        data: chartData,
        backgroundColor: ['#ff6600', '#ffcc00', '#33cc33', '#6666ff'],
        borderWidth: 1,
      },
    ],
  };

  /************************************************************
   * Dados do Gráfico Dinâmico (Simulação - 5 fatias)
   ************************************************************/
  const dataSimulacao = {
    labels: [
      'Usuário/Denunciante',
      'ONG Resgatadora',
      'Clínica',
      'Hospedagem',
      'Família Adotante',
    ],
    datasets: [
      {
        label: 'Uso do Fundo na Simulação',
        data: accUsage,
        backgroundColor: ['#0088cc', '#ff6600', '#ffcc00', '#cc66ff', '#33cc33'],
        borderWidth: 1,
      },
    ],
  };

  /************************************************************
   * Funções de Input e Contato
   ************************************************************/
  const handleWhatsAppClick = () => {
    const message = encodeURIComponent(
      `Gostaria de saber mais sobre o Plano Empresas VidaPet.Tech:\n` +
      `- Nome da Empresa (opcional):\n` +
      `- Quantidade de Funcionários:\n` +
      `- Cidade/Estado:\n` +
      `- Contato WhatsApp:`
    );
    window.open(`https://wa.me/5521982992635?text=${message}`, '_blank');
  };

  /************************************************************
   * RENDER
   ************************************************************/
  return (
    <div
      style={{
        fontFamily: 'Arial, sans-serif',
        color: '#333',
        lineHeight: '1.6',
        margin: '0',
        padding: '0',
        backgroundColor: '#f9f9f9',
      }}
    >
      {/* =========================
          HERO SECTION
      ========================= */}
      <section
        style={{
          maxWidth: '1200px',
          margin: '0 auto',
          textAlign: 'center',
          padding: '50px 20px',
          backgroundColor: '#f0f8ff',
          borderBottom: '2px solid #ccc',
        }}
      >
        <h1 style={{ color: '#ff6600', fontSize: '2.5em' }}>
          Plano Empresas VidaPet.Tech
        </h1>
        <p style={{ fontSize: '1.2em', margin: '20px 0' }}>
          Agora, sua empresa não precisa realizar doações obrigatórias. A contribuição é totalmente opcional e, se os funcionários doarem R$5,00 ou mais ao longo do ano, garantirão o acesso ao VidaPet.Tech Confort por 1 ano!
        </p>
        <img
          src={heroImage}
          alt="Plano Empresas VidaPet.Tech"
          style={{
            maxWidth: '100%',
            height: 'auto',
            borderRadius: '10px',
            marginBottom: '20px',
          }}
        />
        <button
          onClick={handleWhatsAppClick}
          style={{
            backgroundColor: '#ff6600',
            color: '#fff',
            padding: '10px 20px',
            fontSize: '1em',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
          }}
        >
          Entre em Contato via WhatsApp
        </button>
      </section>

      {/** -- 1. DESCRIÇÃO GERAL DO PRODUTO -- */}
      <section
        style={{
          padding: '40px 20px',
          backgroundColor: '#fff',
          borderBottom: '2px solid #ccc',
        }}
      >
        <h2 style={{ color: '#333', textAlign: 'center' }}>
          1. Descrição Geral do Produto
        </h2>
        <p style={{ maxWidth: '800px', margin: '20px auto' }}>
          O <strong>Plano Empresas VidaPet.Tech</strong> é uma solução inovadora que conecta empresas, funcionários e o ecossistema animal. Agora, a contribuição é opcional – o incentivo é para que cada colaborador, ao doar a partir de R$5,00, tenha acesso ao exclusivo VidaPet.Tech Confort por um ano, ajudando a formar o Fundo Dinâmico de Resgate e, ao mesmo tempo, garantindo benefícios diretos.
        </p>
        <ul style={{ maxWidth: '800px', margin: '0 auto', listStyle: 'disc inside' }}>
          <li>Oferece o <strong>VidaPet.Tech Confort</strong> aos funcionários que contribuem.</li>
          <li>Financia o <strong>Fundo Dinâmico de Resgate</strong> com doações voluntárias.</li>
          <li>Promove responsabilidade social e benefícios diretos para os colaboradores.</li>
        </ul>
      </section>

      {/** -- 2. BENEFÍCIOS PARA A EMPRESA -- */}
      <section
        style={{
          padding: '40px 20px',
          backgroundColor: '#f9f9f9',
          borderBottom: '2px solid #ccc',
        }}
      >
        <h2 style={{ color: '#333', textAlign: 'center' }}>
          2. Benefícios para a Empresa
        </h2>
        <div style={{ maxWidth: '800px', margin: '20px auto' }}>
          <ul style={{ listStyle: 'disc inside' }}>
            <li>
              <strong>Certificação "Selo Amigo VidaPet.Tech":</strong> Reconhecimento como empresa socialmente responsável, sem a obrigação de doações.
            </li>
            <li>
              <strong>Relatórios Semestrais Detalhados:</strong> Acompanhamento transparente do impacto das doações voluntárias.
            </li>
            <li>
              <strong>Valorização da Imagem Corporativa:</strong> Demonstre o compromisso com causas sociais e o bem-estar animal.
            </li>
            <li>
              <strong>Engajamento Interno:</strong> Incentive a participação dos funcionários com benefícios exclusivos.
            </li>
          </ul>
        </div>
      </section>

      {/** -- 3. BENEFÍCIOS PARA OS FUNCIONÁRIOS -- */}
      <section
        style={{
          padding: '40px 20px',
          backgroundColor: '#fff',
          borderBottom: '2px solid #ccc',
        }}
      >
        <h2 style={{ color: '#333', textAlign: 'center' }}>
          3. Benefícios para os Funcionários
        </h2>
        <div style={{ maxWidth: '800px', margin: '20px auto' }}>
          <p>
            Ao participar com uma contribuição a partir de R$5,00, o funcionário garante o acesso ao <strong>VidaPet.Tech Confort</strong> por 1 ano, que inclui:
          </p>
          <ul style={{ listStyle: 'disc inside' }}>
            <li>
              <strong>Carteirinha Digital de Saúde Pet:</strong> Registro unificado de vacinas, exames e histórico médico do pet.
            </li>
            <li>
              <strong>Tag QR Code de Identificação para seu Pet:</strong> Se alguém ler o QR Code, ele terá acesso ao perfil do pet e informações de contato do tutor.
            </li>
            <li>
              <strong>Serviços de Telemedicina Veterinária:</strong> Consultas rápidas com veterinários credenciados.
            </li>
            <li>
              <strong>Descontos Exclusivos:</strong> Em clínicas, lojas e serviços parceiros do ecossistema pet.
            </li>
            <li>
              <strong>Gestão Integrada da Saúde do Pet:</strong> Acesso facilitado a informações e agendamento de serviços.
            </li>
          </ul>
        </div>
      </section>

      {/** -- 4. CONDIÇÕES DE ADESÃO -- */}
      <section
        style={{
          padding: '40px 20px',
          backgroundColor: '#f9f9f9',
          borderBottom: '2px solid #ccc',
        }}
      >
        <h2 style={{ color: '#333', textAlign: 'center' }}>
          4. Condições de Adesão
        </h2>
        <div style={{ maxWidth: '800px', margin: '20px auto' }}>
          <ul style={{ listStyle: 'disc inside' }}>
            <li>
              <strong>Contribuição Opcional:</strong> A empresa não precisa doar; a participação se dá por meio da contribuição individual dos funcionários.
            </li>
            <li>
              <strong>Valor Mínimo para Benefício:</strong> Funcionários que contribuírem com <strong>R$5,00 ou mais</strong> ao longo do ano terão acesso ao VidaPet.Tech Confort por 1 ano.
            </li>
            <li>
              <strong>Fundo Dinâmico de Resgate:</strong> As doações voluntárias compõem o fundo que financia ações de resgate, cuidado e adoção de animais.
            </li>
          </ul>
        </div>
      </section>

      {/** -- SIMULADOR DE DOAÇÃO -- */}
      <section
        style={{
          padding: '40px 20px',
          backgroundColor: '#fff',
          borderBottom: '2px solid #ccc',
        }}
      >
        <h2 style={{ color: '#333', textAlign: 'center' }}>Simule sua Contribuição</h2>
        <div
          style={{
            maxWidth: '500px',
            margin: '20px auto',
            border: '1px solid #ccc',
            borderRadius: '8px',
            padding: '20px',
            backgroundColor: '#f9f9f9',
          }}
        >
          <div style={{ marginBottom: '20px' }}>
            <label htmlFor="numFuncionarios" style={{ display: 'block', marginBottom: '5px' }}>
              Número de Funcionários:
            </label>
            <input
              id="numFuncionarios"
              type="number"
              min="0"
              value={numFuncionarios}
              onChange={handleNumFuncionariosChange}
              style={{
                width: '100%',
                padding: '8px',
                borderRadius: '4px',
                border: '1px solid #ccc',
              }}
              placeholder="Digite o número de funcionários"
            />
          </div>

          <div style={{ marginBottom: '20px' }}>
            <label htmlFor="valorDoacao" style={{ display: 'block', marginBottom: '5px' }}>
              Valor da Doação (por funcionário):
            </label>
            <input
              id="valorDoacao"
              type="number"
              value={valorDoacao}
              onChange={handleValorDoacaoChange}
              style={{
                width: '100%',
                padding: '8px',
                borderRadius: '4px',
                border: '1px solid #ccc',
              }}
              placeholder="Digite o valor que cada funcionário contribuirá"
            />
          </div>

          <button
            onClick={calcularDoacao}
            style={{
              backgroundColor: '#ff6600',
              color: '#fff',
              padding: '10px 20px',
              fontSize: '1em',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
              marginBottom: '20px',
            }}
          >
            Calcular Contribuição Anual
          </button>

          <div
            style={{
              backgroundColor: '#fff',
              padding: '10px',
              borderRadius: '4px',
              border: '1px solid #ccc',
              textAlign: 'center',
            }}
          >
            <strong>Valor Total das Contribuições:</strong>
            <p style={{ fontSize: '1.2em', margin: '10px 0' }}>
              R${' '}
              {valorTotal.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
              })}
            </p>
            {valorDoacao !== '' && parseFloat(valorDoacao) < 5 && (
              <p style={{ color: 'red' }}>
                Atenção: O valor mínimo por funcionário para garantir o benefício é de R$5,00.
              </p>
            )}
            {beneficioAtivo && (
              <p style={{ color: 'green' }}>
                Cada funcionário que contribuir garantirá o VidaPet.Tech Confort por 1 ano!
              </p>
            )}
          </div>
        </div>
      </section>

      {/** -- 5. CICLOS DE RESGATE -- */}
      <section
        style={{
          padding: '40px 20px',
          backgroundColor: '#f9f9f9',
          borderBottom: '2px solid #ccc',
        }}
      >
        <h2 style={{ color: '#333', textAlign: 'center' }}>
          5. Ciclos de Resgate - Como Funciona
        </h2>
        <div style={{ maxWidth: '800px', margin: '20px auto' }}>
          <p>
            As ações de resgate acontecem em etapas bem definidas, utilizando os recursos do Fundo Dinâmico de Resgate formado pelas contribuições voluntárias:
          </p>
          <ol style={{ listStyle: 'decimal inside' }}>
            <li>
              <strong>Identificação do Animal:</strong> Um colaborador registra o pet perdido via VidaPet.Tech.
            </li>
            <li>
              <strong>Resgate:</strong> Uma ONG parceira recebe o alerta e realiza o resgate.
            </li>
            <li>
              <strong>Verificação e Cuidados:</strong> O pet passa por cuidados em clínica (vacinação, exames, etc.).
            </li>
            <li>
              <strong>Adoção:</strong> O pet é encaminhado para adoção via plataforma.
            </li>
            <li>
              <strong>Aplicação dos Recursos:</strong> Os valores são distribuídos entre ONGs, clínicas, hospedagem e incentivo à família adotante.
            </li>
          </ol>
        </div>
      </section>

      {/** -- 6. FUNDO DINÂMICO DE RESGATE -- */}
      <section
        style={{
          padding: '40px 20px',
          backgroundColor: '#fff',
          borderBottom: '2px solid #ccc',
        }}
      >
        <h2 style={{ color: '#333', textAlign: 'center' }}>
          6. Fundo Dinâmico de Resgate
        </h2>
        <div style={{ maxWidth: '800px', margin: '20px auto' }}>
          <p>
            O Fundo Dinâmico de Resgate é formado pelas doações voluntárias dos funcionários e é utilizado para financiar ações de resgate, cuidado e adoção de animais. Confira o gráfico de distribuição do fundo e a simulação de aplicação dos recursos:
          </p>
          <ul>
            <li>Usuário/Denunciante</li>
            <li>ONG Resgatadora</li>
            <li>Clínica</li>
            <li>Hospedagem</li>
            <li>Família Adotante</li>
          </ul>
          <p>
            Iniciamos com <strong>R$ 20.000,00</strong> para demonstrar como os recursos podem ser aplicados.
          </p>
        </div>

        {/* Botão para iniciar simulação */}
        <div style={{ textAlign: 'center', marginBottom: '20px' }}>
          {!simulationRunning && (
            <button
              onClick={startSimulation}
              style={{
                backgroundColor: '#ff6600',
                color: '#fff',
                padding: '10px 20px',
                fontSize: '1em',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
            >
              Iniciar Simulação
            </button>
          )}
        </div>

        {/* Container flex para DOIS GRÁFICOS + Lista de Eventos */}
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '30px',
            justifyContent: 'center',
            marginTop: '20px',
          }}
        >
          {/* Gráfico 1: Distribuição Estática */}
          <div
            ref={ref}
            style={{
              flex: '1 1 300px',
              minWidth: '300px',
              maxWidth: '400px',
              textAlign: 'center',
            }}
          >
            <h3>Distribuição do Fundo</h3>
            <Pie
              data={dataFundo}
              options={{
                plugins: {
                  legend: { position: 'bottom' },
                },
                responsive: true,
              }}
            />
          </div>

          {/* Gráfico 2: Simulação */}
          <div
            style={{
              flex: '1 1 300px',
              minWidth: '300px',
              maxWidth: '400px',
              textAlign: 'center',
            }}
          >
            <h3>Simulação (Automática)</h3>
            <Pie
              data={dataSimulacao}
              options={{
                plugins: {
                  legend: { position: 'bottom' },
                },
                responsive: true,
              }}
            />

            {/* Info de fundo e status */}
            <div style={{ marginTop: '20px', textAlign: 'left' }}>
              <p>
                <strong>Fundo Restante:</strong> R${' '}
                {remainingFunds.toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                })}
              </p>
              <p>
                <strong>Cenário Atual:</strong> {scenarioIndex + 1} / 6
              </p>
            </div>
          </div>

          {/* Lista de Eventos */}
          <div
            style={{
              flex: '1 1 300px',
              minWidth: '300px',
              maxWidth: '400px',
              backgroundColor: '#fff',
              border: '1px solid #ccc',
              borderRadius: '5px',
              padding: '15px',
              marginTop: '20px',
            }}
          >
            <h3 style={{ marginTop: '0' }}>Eventos</h3>
            <div
              style={{
                maxHeight: '250px',
                overflowY: 'auto',
                paddingRight: '10px',
              }}
            >
              {eventList.map((evt, idx) => (
                <p key={idx} style={{ marginBottom: '5px' }}>
                  {evt}
                </p>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/** -- 7. RELATÓRIOS DETALHADOS POR CICLO -- */}
      <section
        style={{
          padding: '40px 20px',
          backgroundColor: '#f9f9f9',
          borderBottom: '2px solid #ccc',
        }}
      >
        <h2 style={{ color: '#333', textAlign: 'center' }}>
          7. Relatórios Detalhados por Ciclo
        </h2>
        <div style={{ maxWidth: '800px', margin: '20px auto' }}>
          <p>
            A VidaPet.Tech disponibiliza relatórios semestrais com informações detalhadas de cada ciclo de resgate, incluindo:
          </p>
          <ul style={{ listStyle: 'disc inside' }}>
            <li><strong>ID do Pet:</strong> Identificação única no sistema.</li>
            <li><strong>Foto do Pet:</strong> Imagens do antes e depois do resgate.</li>
            <li><strong>Responsável pelo Resgate:</strong> ONG ou agente resgatador.</li>
            <li><strong>Clínica Envolvida:</strong> Nome e localização da clínica responsável.</li>
            <li><strong>Custo do Ciclo:</strong> Valores alocados para cada etapa (resgate, cuidados, adoção).</li>
            <li><strong>Resultado Final:</strong> Status do pet (adotado, encaminhado para abrigo, etc.).</li>
            <li><strong>Impacto Financeiro:</strong> Percentual dos recursos aplicados em cada ciclo.</li>
          </ul>
          <p>
            Além disso, um <strong>Painel de Impacto Dinâmico</strong> permite visualizar, em tempo real, os resultados das ações.
          </p>
        </div>
      </section>

      {/** -- 8. RECOMENDAÇÕES -- */}
      <section
        style={{
          padding: '40px 20px',
          backgroundColor: '#fff',
          borderBottom: '2px solid #ccc',
        }}
      >
        <h2 style={{ color: '#333', textAlign: 'center' }}>8. Recomendações</h2>
        <div style={{ maxWidth: '800px', margin: '20px auto' }}>
          <ul style={{ listStyle: 'disc inside' }}>
            <li>
              <strong>Comunicação Interna:</strong> Incentive os funcionários a participar e informe-os sobre os benefícios do VidaPet.Tech Confort.
            </li>
            <li>
              <strong>Acompanhe os Relatórios:</strong> Utilize os relatórios semestrais para validar o impacto social e a aplicação dos recursos.
            </li>
          </ul>
        </div>
      </section>

      {/** -- 9. CASOS DE SUCESSO -- */}
      <section
        style={{
          padding: '40px 20px',
          backgroundColor: '#f9f9f9',
          borderBottom: '2px solid #ccc',
        }}
      >
        <h2 style={{ color: '#333', textAlign: 'center' }}>9. Casos de Sucesso</h2>
        <div style={{ maxWidth: '800px', margin: '20px auto' }}>
          <p>
            Conheça histórias que demonstram como as contribuições voluntárias podem transformar a vida de animais em situação de risco:
          </p>
          <ul style={{ listStyle: 'disc inside' }}>
            <li>
              <strong>Mel:</strong> Uma cadelinha que, graças à rápida ação de uma ONG, recebeu os cuidados necessários e foi adotada em poucos meses.
            </li>
            <li>
              <strong>Bob:</strong> Um cachorro idoso que, após ser resgatado e receber atendimento adequado, encontrou um lar repleto de amor.
            </li>
          </ul>
          <p>
            Cada contribuição faz a diferença para salvar vidas!
          </p>
        </div>
      </section>

      {/** -- 10. PERGUNTAS FREQUENTES (FAQ) -- */}
      <section
        style={{
          padding: '40px 20px',
          backgroundColor: '#fff',
          borderBottom: '2px solid #ccc',
        }}
      >
        <h2 style={{ color: '#333', textAlign: 'center' }}>
          10. Perguntas Frequentes (FAQ)
        </h2>
        <div style={{ maxWidth: '800px', margin: '20px auto' }}>
          {faqItems.map((item, index) => (
            <div key={index} style={{ marginBottom: '10px' }}>
              <h3
                onClick={() => toggleFAQ(index)}
                style={{
                  cursor: 'pointer',
                  color: '#ff6600',
                  marginBottom: '5px',
                }}
              >
                {item.question}
              </h3>
              {faqOpenIndex === index && (
                <p style={{ marginLeft: '20px' }}>{item.answer}</p>
              )}
            </div>
          ))}
        </div>
      </section>

      {/** -- 11. FALE CONOSCO -- */}
      <section
        style={{
          padding: '40px 20px',
          backgroundColor: '#f9f9f9',
          borderBottom: '2px solid #ccc',
        }}
      >
        <h2 style={{ color: '#333', textAlign: 'center' }}>
          11. Fale Conosco
        </h2>
        <div style={{ maxWidth: '600px', margin: '20px auto' }}>
          <p>
            Tem dúvidas ou quer saber mais? Envie sua mensagem!
          </p>
          <form onSubmit={handleContactSubmit} style={{ marginTop: '20px' }}>
            <div style={{ marginBottom: '15px' }}>
              <label htmlFor="nome" style={{ display: 'block', marginBottom: '5px' }}>
                Nome:
              </label>
              <input
                type="text"
                id="nome"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
                required
                style={{
                  width: '100%',
                  padding: '8px',
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                }}
                placeholder="Seu nome"
              />
            </div>
            <div style={{ marginBottom: '15px' }}>
              <label htmlFor="email" style={{ display: 'block', marginBottom: '5px' }}>
                E-mail:
              </label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                style={{
                  width: '100%',
                  padding: '8px',
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                }}
                placeholder="seuemail@empresa.com"
              />
            </div>
            <div style={{ marginBottom: '15px' }}>
              <label htmlFor="mensagem" style={{ display: 'block', marginBottom: '5px' }}>
                Mensagem:
              </label>
              <textarea
                id="mensagem"
                value={mensagem}
                onChange={(e) => setMensagem(e.target.value)}
                required
                style={{
                  width: '100%',
                  height: '100px',
                  padding: '8px',
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  resize: 'vertical',
                }}
                placeholder="Escreva sua mensagem..."
              />
            </div>
            <button
              type="submit"
              style={{
                backgroundColor: '#ff6600',
                color: '#fff',
                padding: '10px 20px',
                fontSize: '1em',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
            >
              Enviar Mensagem
            </button>
          </form>
        </div>
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <p>Ou, se preferir:</p>
          <button
            onClick={handleWhatsAppClick}
            style={{
              backgroundColor: '#25d366',
              color: '#fff',
              padding: '10px 20px',
              fontSize: '1em',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
            }}
          >
            Fale agora via WhatsApp
          </button>
        </div>
      </section>

      {/** -- RESUMO FINAL -- */}
      <section
        style={{
          padding: '40px 20px',
          backgroundColor: '#fff',
        }}
      >
        <h2 style={{ color: '#333', textAlign: 'center' }}>Resumo Final</h2>
        <div style={{ maxWidth: '800px', margin: '20px auto' }}>
          <p>
            O <strong>Plano Empresas VidaPet.Tech</strong> incentiva a contribuição voluntária dos funcionários, transformando pequenas doações em grandes ações de resgate e cuidado animal. Com o VidaPet.Tech Confort, cada colaborador que doar R$5,00 ou mais garante benefícios exclusivos por um ano, enquanto ajuda a construir um futuro melhor para os pets.
          </p>
        </div>
      </section>

      {/* FOOTER */}
      <footer
        style={{
          textAlign: 'center',
          padding: '20px',
          backgroundColor: '#333',
          color: '#fff',
          fontSize: '0.9em',
        }}
      >
        <p>VidaPet.Tech - Todos os direitos reservados.</p>
      </footer>
    </div>
  );
};

export default PlanEmpresas;
