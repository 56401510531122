import React from 'react';
import styled from 'styled-components';
import OcaLogo from '../../../imagens/OCA.png';
import CodemarLogo from '../../../imagens/codemar.png';
import PrefeituraLogo from '../../../imagens/Prefeitura.png';
// Importe a imagem codificada em base64
import IbmecLogo from '../../../imagens/IbmecLogo.png'; // Assuma que o caminho está correto

const Parceiros = () => {
  return (
    <Container>
      <h1>Nossos Parceiros</h1>
      <Cards>
        <Card>
          <img src={OcaLogo} alt="OCA - Oficina de Criação de Aprendizagem" />
          <h2>OCA - Oficina de Criação de Aprendizagem</h2>
          <p>Mentoria e Aceleração de Startups</p>
          <a href="https://www.oca.org.br" target="_blank" rel="noopener noreferrer">Visite o Site</a>
        </Card>
        <Card>
          <img src={CodemarLogo} alt="Codemar" />
          <h2>Codemar</h2>
          <p>Desenvolvimento Tecnológico em Maricá</p>
          <a href="https://codemar-sa.com.br/edital-parque-tecnologico/" target="_blank" rel="noopener noreferrer">Visite o Site</a>
        </Card>
        <Card>
          <img src={PrefeituraLogo} alt="Prefeitura de Maricá" />
          <h2>Prefeitura de Maricá</h2>
          <p>Apoio e Fomento à Inovação</p>
          <a href="https://www.marica.rj.gov.br" target="_blank" rel="noopener noreferrer">Visite o Site</a>
        </Card>
        <Card>
          <img src={IbmecLogo} alt="IBMEC Hubs RJ" />
          <h2>IBMEC Hubs RJ</h2>
          <p>Incubadora e Aceleradora de Empresas</p>
          <a href="https://www.ibmec.br" target="_blank" rel="noopener noreferrer">Visite o Site</a>
        </Card>
      </Cards>
    </Container>
  );
};

export default Parceiros;

const Container = styled.div`
  padding: 20px;
  text-align: center;
`;

const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
`;

const Card = styled.div`
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  width: 250px;

  img {
    width: 100px;
    height: auto;
    margin-bottom: 15px;
  }

  h2 {
    font-size: 18px;
    margin-bottom: 10px;
  }

  p {
    font-size: 14px;
    color: #666;
  }

  a {
    color: #00a4e3;
    text-decoration: none;
    font-weight: bold;

    &:hover {
      text-decoration: underline;
    }
  }
`;
